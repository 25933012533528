import { React, Component } from "react";
import "../App.css";
import "../index.css";

class Darktheme extends Component {
  componentDidMount() {
    const presentTheme = localStorage.getItem("theme");
    if (presentTheme) {
      document.documentElement.setAttribute("data-theme", presentTheme);
      if (presentTheme === "dark") {
        document.querySelector("input[type=checkbox]").checked = true;
      }
    }
  }

  switchTheme(e) {
    if (e.target.checked) {
      document.documentElement.setAttribute("data-theme", "dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.setAttribute("data-theme", "light");
      localStorage.setItem("theme", "light");
    }
  }

  onChange(e) {
    this.switchTheme(e);
  }

  render() {
    return (
      <div>
        <label>
          <span className="span">
            <input type="checkbox" onChange={(e) => this.onChange(e)} />
          </span>
          <svg
            className="sun"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 12 12"
          >
            <style type="text/css"></style>
            <g>
              <path
                className="st7"
                d="M6,2.3C4,2.3,2.3,4,2.3,6S4,9.7,6,9.7C8.1,9.7,9.7,8,9.7,6S8.1,2.3,6,2.3z M6,9.2C4.2,9.2,2.8,7.8,2.8,6
		S4.2,2.8,6,2.8S9.2,4.2,9.2,6S7.8,9.2,6,9.2z"
              />
              <rect
                x="9.4"
                y="1.9"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 1.2713 7.556)"
                className="st7"
                width="0.8"
                height="0.6"
              />
              <rect x="10.9" y="5.7" className="st7" width="0.8" height="0.6" />
              <rect
                x="9.5"
                y="9.4"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.0413 9.7566)"
                className="st7"
                width="0.6"
                height="0.8"
              />
              <rect x="5.7" y="10.9" className="st7" width="0.6" height="0.8" />
              <rect
                x="1.9"
                y="9.5"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.2419 4.444)"
                className="st7"
                width="0.8"
                height="0.6"
              />
              <rect x="0.3" y="5.7" className="st7" width="0.8" height="0.6" />
              <rect
                x="1.9"
                y="1.9"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -0.9293 2.2434)"
                className="st7"
                width="0.6"
                height="0.8"
              />
              <rect x="5.7" y="0.3" className="st7" width="0.6" height="0.8" />
            </g>
            <path
              className="st9"
              d="M6,1.7C3.6,1.7,1.7,3.6,1.7,6s1.9,4.3,4.3,4.3c0,0-2-1-2-4.3S6,1.7,6,1.7z"
            />
          </svg>
        </label>
      </div>
    );
  }
}
export default Darktheme;
