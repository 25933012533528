import { React, useState } from 'react'
import { motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { send } from '@emailjs/browser'
// import "../App.css";
import Slogan from './Slogan'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

const xAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.1 },
  }),
}

const yAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.1 },
  }),
}

const Contacts = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const [disabled, setDisabled] = useState(false)

  // const sendSuccess = () => alert(`Відправлено!`);

  const sendSuccess = () =>
    toast.success('Відправлено!', {
      position: 'top-center',
      autoClose: 2100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      style: {
        backgroundColor: 'grey',
        color: 'white',
      },
      // transition: Bounce,
    })

  const onSubmit = async (data) => {
    const { name, email, mob, message } = data
    try {
      setDisabled(true)

      const templatePrams = {
        name,
        email,
        mob,
        message,
      }

      await send(
        'Gmail',
        'template_58pk314',
        templatePrams,
        '8zJr6jv1JjrV8d9dl'
      )

      reset()
      sendSuccess()
      setDisabled(false)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <section>
      <section>
        <ToastContainer
          position="top-center"
          autoClose={2100}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          closeButton={false}
          // transition="Bounce"
        />
        <motion.section className="sectionContact" id="#contact">
          <div className="formInputContainer">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.2, once: true }}
              variants={yAnimation}
              custom={1}
              className="formText scaleChange"
            >
              <h1 style={{ color: 'var(--color-red)' }}>Напишіть нам</h1>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.2, once: true }}
              variants={yAnimation}
              custom={3}
              className="scaleChange2"
            >
              <h4
                style={{ marginTop: '-4%', marginBottom: '3%' }}
              >{`... ми завжди читаємо ;)`}</h4>
            </motion.div>
            <form
              className="form"
              id="contact-form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.2, once: true }}
                variants={xAnimation}
                custom={1}
              >
                <input
                  autoFocus="1"
                  maxLength="34"
                  type="text"
                  name="name"
                  className="formInput"
                  placeholder="Ім'я"
                  {...register('name', {
                    required: {
                      value: true,
                      message: `Введіть ім'я`,
                    },
                    maxLength: {
                      value: 30,
                      message: 'Використовуйте до 30 символів',
                    },
                  })}
                ></input>
              </motion.div>
              <div className="errorMessage">
                {errors.name && <span>{errors.name.message}</span>}
              </div>

              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.2, once: true }}
                variants={xAnimation}
                custom={2}
              >
                <input
                  maxLength="52"
                  type="email"
                  name="email"
                  className="formInput"
                  placeholder="Email"
                  {...register('email', {
                    required: true,
                    pattern:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                ></input>
              </motion.div>
              <div className="errorMessage">
                {errors.email && <span>Введіть email</span>}
              </div>

              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.2, once: true }}
                variants={xAnimation}
                custom={3}
              >
                <input
                  maxLength="21"
                  type="tel"
                  name="mob"
                  className="formInput"
                  placeholder="Моб."
                  {...register('mob', {
                    required: {
                      value: true,
                      message: 'Введіть номер мобільного',
                    },
                    maxLength: {
                      value: 18,
                      message: 'Забагато символів',
                    },
                  })}
                ></input>
              </motion.div>
              <div className="errorMessage">
                {errors.mob && <span>{errors.mob.message}</span>}
              </div>

              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.2, once: true }}
                variants={xAnimation}
                custom={4}
              >
                <textarea
                  maxLength="300"
                  rows={3}
                  name="message"
                  className="formInput"
                  placeholder="Повідомлення"
                  {...register('message', {
                    required: true,
                  })}
                ></textarea>
              </motion.div>
              <div className="errorMessage">
                {errors.message && <span>Введіть повідомлення</span>}
              </div>

              <div>
                <button disabled={disabled} type="submit">
                  Відправити
                </button>
              </div>
            </form>
          </div>
        </motion.section>
      </section>
      <footer>
        <Slogan />
      </footer>
    </section>
  )
}

export default Contacts
