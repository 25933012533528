import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link, useParams } from 'react-router-dom'
import 'react-lazy-load-image-component/src/effects/blur.css'
// import "../App.css";
import foods from '../data/foods'
import Slogan from './Slogan'

const SingleFood = () => {
  const paramsSingle = useParams()

  foods.find((foodSingle) => foodSingle.slug === paramsSingle.foodSingleSlug)
  const slugNew = paramsSingle.foodSingleSlug

  return (
    <section>
      <section className="sectionFoods">
        {foods.map(
          (foodSingle) =>
            foodSingle.slug === slugNew &&
            !!foodSingle.prod &&
            foodSingle.prod.map(
              (prod, index) =>
                !!prod.i && (
                  <div key={prod.n} className="containerFood">
                    <div className="scaleChange">
                      <Link to={`../${prod.s}`}>
                        <LazyLoadImage
                          className="shadowImg scale"
                          placeholderSrc={prod.i2}
                          loading="lazy"
                          effect="blur opacity"
                          src={prod.i}
                          alt={prod.tit}
                          draggable="false"
                        />
                      </Link>
                    </div>

                    <div className="marginMinusProd scaleChange2">
                      <Link
                        to={`../${prod.s}`}
                        className="marginMinusProdCol scale"
                      >
                        {prod.tit}
                      </Link>
                    </div>
                  </div>
                )
            )
        )}
      </section>
      <footer>
        <Slogan />
      </footer>
    </section>
  )
}

export default SingleFood
