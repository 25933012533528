import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import foods from '../data/foods'
import Slogan from './Slogan'

const Foods = () => {
  const imgAnimation = {
    hidden: {
      x: -50,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.03 },
    }),
  }
  return (
    <section>
      <section>
        <motion.section
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.1, once: true }}
          className="sectionFoods"
        >
          {foods.map((food, index) => (
            <motion.div
              custom={index + 1}
              variants={imgAnimation}
              key={food.id}
              className="containerFood"
            >
              <div className="animationRotate scaleChange">
                <Link to={food.slug}>
                  <LazyLoadImage
                    className="shadowImgFoods"
                    effect="opacity"
                    placeholderSrc={food.img2}
                    src={food.img}
                    alt={food.title}
                    draggable="false"
                  />
                </Link>
              </div>
              <div className="marginMinus scale">
                <Link to={food.slug} className="marginMinusCol scaleChange2">
                  {food.title}
                </Link>
              </div>
            </motion.div>
          ))}
        </motion.section>
      </section>
      <footer>
        <Slogan />
      </footer>
    </section>
  )
}
export default Foods
