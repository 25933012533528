// import React, { useState, useEffect } from "react";
import React from 'react'
import { NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import pelmeni from '../data/img/about/Pelmeni.jpg'
import factory from '../data/img/about/Factory.jpg'
import kotlety from '../data/img/about/Kotlety.jpg'
import pyrizhok from '../data/img/about/Pyrizhok.jpg'
import mlyntsi from '../data/img/about/Mlyntsi.jpg'
import pelmeni2 from '../data/img/about-2/Pelmeni2.jpg'
import factory2 from '../data/img/about-2/Factory2.jpg'
import kotlety2 from '../data/img/about-2/Kotlety2.jpg'
import pyrizhok2 from '../data/img/about-2/Pyrizhok2.jpg'
import mlyntsi2 from '../data/img/about-2/Mlyntsi2.jpg'
import Slogan from './Slogan'
import logo from '../data/logo'

const Abouts = () => {
  const imgAnimation = {
    hidden: {
      x: -50,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
    }),
  }

  const textAnimation = {
    hidden: {
      y: 50,
      opacity: 0,
    },
    visible: (custom) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
    }),
  }

  return (
    <section>
      <section>
        <motion.section>
          <div className="sectionProd">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.2, once: true }}
              variants={imgAnimation}
              custom={1}
              className="logo scaleChange2"
              draggable="false"
            >
              {logo}
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.2, once: true }}
              variants={textAnimation}
              custom={1}
              className="containerProdText"
            >
              <div className="paddingText1 scaleChange2" id="#drygalo">
                <h1 style={{ color: 'var(--color-red)' }}>ДРИГАЛО</h1>
              </div>
              <div className="paddingText2">
                <h3 style={{ color: 'var(--color-red)' }}>
                  – це прізвище родини,
                  <br />
                  яка створила смачний бренд "ДРИГАЛО"
                </h3>
              </div>
              <div className="paddingText2">
                <h4>
                  Сьогодні "ДРИГАЛО" – загальновизнаний лідер національного
                  ринку заморожених напівфабрикатів і продуктів готових до
                  вживання:
                  <NavLink to="./foods/var"> Вареники</NavLink>,
                  <NavLink to="./foods/pel"> Пельмені</NavLink>,
                  <NavLink to="./foods/kot"> Котлети</NavLink>,
                  <NavLink to="./foods/mly"> Млинці</NavLink>,
                  <NavLink to="./foods/zra"> Зрази</NavLink>,
                  <NavLink to="./foods/vyp"> Пиріжки</NavLink>,
                  <NavLink to="./foods/sma"> Вироби з Тіста</NavLink> тощо.
                  <br />
                  <br />
                  Безліч нагород – підтвердження високої якості та успіху з 1995
                  року. <br />
                  <br />
                  Понад<NavLink to="./search">
                    {' '}
                    100 видів продукції
                  </NavLink>{' '}
                  успішно продаються в Україні, а також експортуються до різних
                  країн Cвіту.
                </h4>
              </div>
            </motion.div>
          </div>

          <div className="sectionProd2">
            <motion.div
              className="containerProdText"
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.2, once: true }}
              variants={imgAnimation}
              custom={1.2}
              alt="Історія Дригало"
            >
              <div className="paddingText2 scaleChange2" id="#history">
                <h1 style={{ color: 'var(--color-red)' }}>ІСТОРІЯ "ДРИГАЛО"</h1>
              </div>
              <div className="paddingText2">
                <h4>
                  У 1995 році приватний підприємець Дригало Людмила Борисівна зі
                  своєю сім'єю засновує невеличке виробництво заморожених
                  напівфабрикатів.
                  <br />
                  <br />
                  Роздумів над назвою торгової марки не виникає: сімейний бізнес
                  – сімейний бренд "ДРИГАЛО".
                  <br />
                  <br />
                  2000 – рік швидкого зросту. Виробнича площа збільшується до
                  1000 м², автоматизуються виробничі процеси, кількість
                  працюючих досягає 100 чоловік.
                  <br />
                </h4>
              </div>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.2, once: true }}
              variants={textAnimation}
              custom={1.2}
              className="imgBox scaleChange"
            >
              <LazyLoadImage
                className="imgBlock"
                placeholderSrc={factory2}
                // loading="lazy"
                effect="blur opacity"
                src={factory}
                alt="Фабрика Дригало"
                draggable="false"
              />
            </motion.div>
          </div>

          <div className="sectionProd">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.2, once: true }}
              variants={imgAnimation}
              className="imgBox scaleChange2"
            >
              <LazyLoadImage
                placeholderSrc={pelmeni2}
                // loading="lazy"
                effect="blur opacity"
                className="imgBlock"
                src={pelmeni}
                alt="Пельмені Дригало"
                draggable="false"
              />
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.2, once: true }}
              variants={textAnimation}
              // custom={1}
              className="containerProdText"
              alt="2007 Дригало"
            >
              <div className="paddingText2">
                <h4>
                  У 2007 році підприємство найперше серед українських виробників
                  напівфабрикатів заморожених впроваджує систему управління
                  якістю відповідно до міжнародного стандарту ISO 9001:2000 та
                  систему управління безпекою продуктів харчування відповідно до
                  міжнародного стандарту ISO 22000:2005 (HACCP).
                </h4>
              </div>
            </motion.div>
          </div>
          <div className="sectionProd2">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.2, once: true }}
              variants={imgAnimation}
              className="containerProdText"
              alt="2021 Дригало"
            >
              <div className="paddingText2">
                <h4>
                  Згодом площа фабрики збільшується до 4600 м², кількість
                  працівників – 650 чол., потужність – 6000 кг продукції на
                  годину.
                  <br />
                  <br />
                  2012 – пожежа... Довгий час компанія знаходиться у складному
                  становищі.
                  <br />
                  <br />
                  2021 – нарешті "ДРИГАЛО" знову розквітає!
                </h4>
              </div>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.2, once: true }}
              variants={textAnimation}
              className="imgBox scaleChange"
            >
              <LazyLoadImage
                className="imgBlock"
                placeholderSrc={kotlety2}
                // loading="lazy"
                effect="blur opacity"
                src={kotlety}
                alt="Котлети Дригало"
                draggable="false"
              />
            </motion.div>
          </div>
          <div className="sectionProd">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.2, once: true }}
              variants={imgAnimation}
              className="imgBox scaleChange2"
            >
              <LazyLoadImage
                className="imgBlock"
                placeholderSrc={pyrizhok2}
                // loading="lazy"
                effect="blur opacity"
                src={pyrizhok}
                alt="Пиріжки Дригало"
                draggable="false"
              />
            </motion.div>

            <div className="containerProdText">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.2, once: true }}
                variants={textAnimation}
                className="paddingText2"
                alt="Дригало сьогодні"
              >
                <h4>
                  2022 рік – рашисти... війна... Важко, страшно, але ми не
                  зупиняємося. Завдяки відважним людям нашої команди працюємо на
                  благо Рідної України!
                  <br />
                  <br />
                  2023-2024 – Українські Захисники дякують "ДРИГАЛО" за постійну
                  підтримку та надану продукцію, а "ДРИГАЛО" дякує їм за Захист
                  і Героїзм!
                  <br />
                  <br />
                  СЛАВА УКРАЇНІ 💙 <br />
                  ГЕРОЯМ СЛАВА 💛
                </h4>
              </motion.div>
            </div>
          </div>

          <div className="sectionProd2">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.2, once: true }}
              variants={imgAnimation}
              className="containerProdText"
            >
              <div className="paddingText2 scaleChange2" id="#history">
                <h1 style={{ color: 'var(--color-red)' }}>СПІВПРАЦЯ</h1>
              </div>
              <div className="paddingText2">
                <h3>
                  З питань співпраці, а саме Дистриб'юція, Франшиза, Приват
                  Лейбл і т.д., звертайтесь до нас через форму зворотнього
                  зв'язку!
                </h3>

                <div>
                  <NavLink to="../contacts">
                    <button>КОНТАКТ</button>
                    {/* <div className="blockMenuEnd">КОНТАКТ</div> */}
                  </NavLink>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.2, once: true }}
              variants={textAnimation}
              className="imgBox scaleChange"
            >
              <LazyLoadImage
                className="imgBlock"
                placeholderSrc={mlyntsi2}
                // loading="lazy"
                effect="blur opacity"
                src={mlyntsi}
                alt="Млинці Дригало"
                draggable="false"
              />
            </motion.div>
          </div>
        </motion.section>
      </section>
      <footer>
        <Slogan />
      </footer>
    </section>
  )
}

export default Abouts
