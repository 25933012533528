import React from 'react'
import { Link } from 'react-scroll'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'
import logo from '../data/logo'
import slogan from '../data/slogan'

const Slogan = () => {
  const imgAnimation = {
    hidden: {
      y: 30,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.5,
      },
    },
  }

  return (
    <motion.section className="sloganBox">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.2 }}
        variants={imgAnimation}
        className="sloganBox2"
      >
        <NavLink
          to=".."
          end
          alt="Лого Дригало"
          className="sloganLogo left-part scaleChange noActive"
        >
          {logo}
        </NavLink>

        <Link
          alt="Відпочиньте в добрий час, ми подбаємо про вас!"
          // activeclassName="active"
          to="#top"
          spy={true}
          smooth={true}
          offset={-50}
          duration={700}
          className="slogan scaleChange2 right-part noActive"
        >
          {slogan}
          {/* <h2 className="scaleChange2">
            Відпочиньте в добрий час, <br /> ми подбаємо про вас!
          </h2> */}
        </Link>
      </motion.div>
    </motion.section>
  )
}

export default Slogan
