import { React, useState, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
// import "../App.css";
import foods from '../data/foods'
// import slogan from "../data/slogan";
import Slogan from './Slogan'

const SearchProd = () => {
  const paramsS = useParams()

  foods.find((searchPr) => searchPr.prod.s === paramsS.searchProdS)
  const searchNew = paramsS.searchProdS

  const arr = []
  arr.push(foods.map((data) => data.prod.map((prod) => prod.s)))

  let a = 0,
    b = 0,
    c = 0

  const arr2 = []
  for (c = 0; c < arr.length; c++) {
    for (b = 0; b < arr[c].length; b++) {
      for (a = 0; a < arr[c][b].length; a++) {
        arr2.push(arr[c][b][a])
      }
    }
  }

  const indProd = arr2.findIndex((newArr) => newArr === searchNew)
  const len = arr2.length - 1
  const nextProd = indProd < len ? arr2[indProd + 1] : arr2[0]
  const preProd = indProd > 0 ? arr2[indProd - 1] : arr2[len]

  const imgAnimation = {
    hidden: {
      x: -50,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
    }),
  }

  const textAnimation = {
    hidden: {
      y: 50,
      opacity: 0,
    },
    visible: (custom) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
    }),
  }

  const [isClicked, setIsClicked] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        document.querySelector('.clickableR').click()
      }
      if (event.key === 'ArrowLeft') {
        document.querySelector('.clickableL').click()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [isClicked])

  return (
    <section>
      {foods.map((searchPr) =>
        searchPr.prod.map(
          (prod) =>
            prod.s === searchNew &&
            !!prod.sklad && (
              <section>
                <section key={prod.n} className="sectionProd">
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.2, once: true }}
                    variants={imgAnimation}
                    custom={1}
                    className="containerProd"
                    draggable="false"
                  >
                    <div>
                      <div className="containerProd2">
                        <h1 className="containerProd90 scaleChange2">
                          {prod.tit}
                        </h1>
                      </div>
                      <div>
                        <h3
                          className="lineUp"
                          style={{
                            color: 'var(--color-light)',
                          }}
                        >
                          {prod.ng}
                        </h3>
                      </div>
                      <LazyLoadImage
                        placeholderSrc={prod.i2}
                        className="scaleChange"
                        effect="blur opacity"
                        src={prod.i}
                        width="300px"
                        height="200px"
                        alt={prod.tit}
                        draggable="false"
                      />
                    </div>
                  </motion.div>

                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.2, once: true }}
                    variants={textAnimation}
                    custom={2}
                    className="containerProdText"
                  >
                    <div className="paddingText1">
                      <h3 className="colorChange">СКЛАД</h3>
                    </div>
                    <div className="paddingText2">
                      <h4>{prod.sklad}</h4>
                    </div>
                    <div className="paddingText1">
                      <h3 className="colorChange">ЯК ГОТУВАТИ</h3>
                    </div>
                    <div className="paddingText2">
                      <h4>{prod.sp}</h4>
                    </div>
                    <div className="paddingText1">
                      <h3 className="colorChange">ЯК ЗБЕРІГАТИ</h3>
                    </div>
                    <div className="paddingText2">
                      <h4>{prod.uz}</h4>
                    </div>
                    <div className="strilkaBtnR"></div>
                  </motion.div>

                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.2, once: true }}
                    variants={textAnimation}
                    custom={3}
                    className="sloganBox"
                  >
                    {/* <Slogan /> */}
                    {/* {slogan} */}
                  </motion.div>

                  <NavLink to={`../${nextProd}`}>
                    <div
                      className="clickableR"
                      onClick={() => setIsClicked(true)}
                    >
                      <div className="nextBtn">❯</div>
                    </div>
                  </NavLink>

                  <NavLink to={`../${preProd}`}>
                    <div
                      className="clickableL"
                      onClick={() => setIsClicked(true)}
                    >
                      <div className="preBtn">❮</div>
                    </div>
                  </NavLink>
                </section>
                <footer>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.2, once: true }}
                    variants={textAnimation}
                    custom={3}
                    className="sloganBox"
                  >
                    <Slogan />
                    {/* {slogan} */}
                  </motion.div>
                </footer>
              </section>
            )
        )
      )}
    </section>
  )
}

export default SearchProd
