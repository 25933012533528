import va from "./img/tar/Тарілка-з-варениками-300x300.png";
import pel from "./img/tar/Тарілка-з-пельменями-300x300.png";
import kot from "./img/tar/Тарілка-з-котлетами-300x300.png";
import mly from "./img/tar/Тарілка-з-млинцями-300x300.png";
import zra from "./img/tar/Тарілка-з-зразами-300x300.png";
import syr from "./img/tar/Тарілка-з-сирниками-300x300.png";
import vyp from "./img/tar/Тарілка-з-пиріжками-300x300.png";
import sma from "./img/tar/Тарілка-з-біляшем-300x300.png";
import riz from "./img/tar/Тарілка-з-тістечками-300x300.png";

import va2 from "./img/tar-2/Тарілка-з-варениками-300x300-2.png";
import pel2 from "./img/tar-2/Тарілка-з-пельменями-300x300-2.png";
import kot2 from "./img/tar-2/Тарілка-з-котлетами-300x300-2.png";
import mly2 from "./img/tar-2/Тарілка-з-млинцями-300x300-2.png";
import zra2 from "./img/tar-2/Тарілка-з-зразами-300x300-2.png";
import syr2 from "./img/tar-2/Тарілка-з-сирниками-300x300-2.png";
import vyp2 from "./img/tar-2/Тарілка-з-пиріжками-300x300-2.png";
import sma2 from "./img/tar-2/Тарілка-з-біляшем-300x300-2.png";
import riz2 from "./img/tar-2/Тарілка-з-тістечками-300x300-2.png";

import varApa from "./img/prod/Var-apa.png";
import varKos from "./img/prod/Var-kos.png";
import varRuc from "./img/prod/Var-ruc.png";
import pelApa from "./img/prod/Pel-apa.png";
import pelBil from "./img/prod/Pel-ruc.png";
import pelBul from "./img/prod/Pel-bul.png";
import pelGno from "./img/prod/Pel-gno.png";
import kotZvy from "./img/prod/Kot.png";
import kotKyi from "./img/prod/Kot-Kyi.png";
import kotBil from "./img/prod/Kot-Bil.png";
import mlyVes from "./img/prod/Ves.png";
import mlyVys from "./img/prod/Vys.png";
import mlyZvy from "./img/prod/Mly.png";
import rolSyr from "./img/prod/Rol-syr.png";
import rolVys from "./img/prod/Rol-vys.png";
import rolDom from "./img/prod/Rol-dom.png";
import rolMia from "./img/prod/Rol-mia.png";
import zraKar from "./img/prod/Zra-kar.png";
import pyrPec from "./img/prod/Pyr-pec.png";
import pyrPecKos from "./img/prod/Pyr-pec-kos.png";
import pyrPecMal from "./img/prod/Pyr-pec-mal.png";
import sosTisPec from "./img/prod/Sos-tis-pec.png";
import sosTisKro from "./img/prod/Sos-tis-Kro.png";
import pyrSma from "./img/prod/Pyr-sma.png";
import pyrSmaApa from "./img/prod/Pyr-sma-apa.png";
import pyrSmaPov from "./img/prod/Pyr-pov-sma.png";
import bilSma from "./img/prod/Bil-sma.png";
import syrNF from "./img/prod/Syr-n.png";
import syrRodz from "./img/prod/Syr-n-rodz.png";
import cheKru from "./img/prod/Che-kru.png";
import fry from "./img/prod/Fry.png";
import hin from "./img/prod/Hin.png";
import osnPit from "./img/prod/Osn-pit.png";
import zagPit from "./img/prod/Zag-pit.png";
import kotTis from "./img/prod/Kot-tis.png";
import gol from "./img/prod/Gol.png";
import golOvo from "./img/prod/Gol-ovo.png";
import vatPec from "./img/prod/Vat-pec.png";
import bulPec from "./img/prod/Bul-pec.png";
import benVag from "./img/prod/Ben-vag.png";
import kex from "./img/prod/Kex.png";
import zap from "./img/prod/Zap.png";
import tis from "./img/prod/Tis.png";
import cheKruNF from "./img/prod/Che-kru-n.png";
import per from "./img/prod/Per.png";
import perBil from "./img/prod/Per-bil.png";
import bur from "./img/prod/Bur.png";
import soch from "./img/prod/Soch.png";
import pitKur from "./img/prod/Pit-kur.png";
import kotKru from "./img/prod/Kot-kru.png";
import kotRyb from "./img/prod/Kot-ryb.png";
import kotKor from "./img/prod/Kot-kor.png";
import kotNiz from "./img/prod/Kot-niz.png";

import varApa2 from "./img/prod-2/Var-apa-2.png";
import varKos2 from "./img/prod-2/Var-kos-2.png";
import varRuc2 from "./img/prod-2/Var-ruc-2.png";
import pelApa2 from "./img/prod-2/Pel-apa-2.png";
import pelBil2 from "./img/prod-2/Pel-ruc-2.png";
import pelBul2 from "./img/prod-2/Pel-bul-2.png";
import pelGno2 from "./img/prod-2/Pel-gno-2.png";
import kotZvy2 from "./img/prod-2/Kot-2.png";
import kotKyi2 from "./img/prod-2/Kot-Kyi-2.png";
import kotBil2 from "./img/prod-2/Kot-Bil-2.png";
import mlyVes2 from "./img/prod-2/Ves-2.png";
import mlyVys2 from "./img/prod-2/Vys-2.png";
import mlyZvy2 from "./img/prod-2/Mly-2.png";
import rolSyr2 from "./img/prod-2/Rol-syr-2.png";
import rolVys2 from "./img/prod-2/Rol-vys-2.png";
import rolDom2 from "./img/prod-2/Rol-dom-2.png";
import rolMia2 from "./img/prod-2/Rol-mia-2.png";
import zraKar2 from "./img/prod-2/Zra-kar-2.png";
import pyrPec2 from "./img/prod-2/Pyr-pec-2.png";
import pyrPecKos2 from "./img/prod-2/Pyr-pec-kos-2.png";
import pyrPecMal2 from "./img/prod-2/Pyr-pec-mal-2.png";
import sosTisPec2 from "./img/prod-2/Sos-tis-pec-2.png";
import sosTisKro2 from "./img/prod-2/Sos-tis-Kro-2.png";
import pyrSma2 from "./img/prod-2/Pyr-sma-2.png";
import pyrSmaApa2 from "./img/prod-2/Pyr-sma-apa-2.png";
import pyrSmaPov2 from "./img/prod-2/Pyr-pov-sma-2.png";
import bilSma2 from "./img/prod-2/Bil-sma-2.png";
import syrNF2 from "./img/prod-2/Syr-n-2.png";
import syrRodz2 from "./img/prod-2/Syr-n-rodz-2.png";
import cheKru2 from "./img/prod-2/Che-kru-2.png";
import fry2 from "./img/prod-2/Fry-2.png";
import hin2 from "./img/prod-2/Hin-2.png";
import osnPit2 from "./img/prod-2/Osn-pit-2.png";
import zagPit2 from "./img/prod-2/Zag-pit-2.png";
import kotTis2 from "./img/prod-2/Kot-tis-2.png";
import gol2 from "./img/prod-2/Gol-2.png";
import golOvo2 from "./img/prod-2/Gol-ovo-2.png";
import vatPec2 from "./img/prod-2/Vat-pec-2.png";
import bulPec2 from "./img/prod-2/Bul-pec-2.png";
import benVag2 from "./img/prod-2/Ben-vag-2.png";
import kex2 from "./img/prod-2/Kex-2.png";
import zap2 from "./img/prod-2/Zap-2.png";
import tis2 from "./img/prod-2/Tis-2.png";
import cheKruNF2 from "./img/prod-2/Che-kru-n-2.png";
import per2 from "./img/prod-2/Per-2.png";
import perBil2 from "./img/prod-2/Per-bil-2.png";
import bur2 from "./img/prod-2/Bur-2.png";
import soch2 from "./img/prod-2/Soch-2.png";
import pitKur2 from "./img/prod-2/Pit-kur-2.png";
import kotKru2 from "./img/prod-2/Kot-kru-2.png";
import kotRyb2 from "./img/prod-2/Kot-ryb-2.png";
import kotKor2 from "./img/prod-2/Kot-kor-2.png";
import kotNiz2 from "./img/prod-2/Kot-niz-2.png";

const foods = [
  {
    img: va,
    img2: va2,
    title: "Вареники",
    slug: "var",
    id: 1,
    prod: [
      {
        n: 151,
        i: varKos,
        i2: varKos2,

        s: `Varzvyshn`,
        tit: `Вареники з вишнями`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, вишня, вода питна, яйця курячі.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 161,
        i: varApa,
        i2: varApa2,

        s: `Varzkapu`,
        tit: `Вареники з капустою`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, капуста свіжа (43%), вода питна, цибуля ріпчаста свіжа, морква свіжа, олія соняшникова, яйця курячі, сіль кухонна, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 171,
        i: varApa,
        i2: varApa2,

        s: `Varzkart`,
        tit: `Вареники з картоплею`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, картопля свіжа, вода питна, цибуля ріпчаста свіжа, олія соняшникова, яйця курячі, сіль кухонна, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 181,
        i: varApa,
        i2: varApa2,

        s: `Varzkarttagryb`,
        tit: `Вареники з картоплею та грибами`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, картопля свіжа, вода питна, цибуля ріпчаста свіжа, гриби, олія соняшникова, яйця курячі, сіль кухонна, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 191,
        i: varApa,
        i2: varApa2,

        s: `Varzkarttapechi`,
        tit: `Вареники з картоплею та печінкою`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, картопля свіжа, печінка ялова, сало, вода питна, цибуля ріпчаста свіжа, олія соняшникова, яйця курячі, сіль кухонна, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 201,
        i: varRuc,
        i2: varRuc2,

        s: `Varzkarttashkva`,
        tit: `Вареники з картоплею та шкварками`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, картопля свіжа, шкварки (обсмажені шматочки сала), вода питна, цибуля ріпчаста свіжа, олія соняшникова, яйця курячі, сіль кухонна, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 211,
        i: varRuc,
        i2: varRuc2,

        s: `Varzkarttsybutaolije`,
        tit: `Вареники з картоплею, цибулею та олією`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, картопля свіжа (43%), вода питна, цибуля ріпчаста свіжа, олія соняшникова, яйця курячі, сіль кухонна, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 221,
        i: varApa,
        i2: varApa2,

        s: `Varzmjyas`,
        tit: `Вареники з м'ясом`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, яловичина, свинина, вода питна, цибуля ріпчаста свіжа, сало, яйця курячі, сіль кухонна, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 231,
        i: varRuc,
        i2: varRuc2,

        s: `Varzmjyasichasn`,
        tit: `Вареники з м'ясом і часником`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, яловичина, свинина, часник, вода питна, цибуля ріпчаста свіжа, сало, яйця курячі, сіль кухонна, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 241,
        i: varApa,
        i2: varApa2,

        s: `Varzmjyasindy`,
        tit: `Вареники з м'ясом індички`,
        ng: `Напівфабрикат`,
        sklad: `тісто (55%) - борошно пшеничне в/г, вода питна, олія соняшникова; 
        начинка (45%) - м'ясо індички (37,5%), цибуля ріпчаста свіжа, олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 251,
        i: varApa,
        i2: varApa2,

        s: `Varzsertstapechi`,
        tit: `Вареники з серцем та печінкою`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, ялові серце та печінка, вода питна, цибуля ріпчаста свіжа, сало, яйця курячі, олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 261,
        i: varApa,
        i2: varApa2,

        s: `Varzsyrosol`,
        tit: `Вареники з сиром солодким`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, сир домашній, вода питна, цукор, яйця курячі, сіль кухонна, ароматизатор ванілін.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 271,
        i: varApa,
        i2: varApa2,

        s: `Varzsyrosolo`,
        tit: `Вареники з сиром солоним`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, сир, вода питна, яйця курячі, сіль кухонна, цукор, кріп сушений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 281,
        i: varRuc,
        i2: varRuc2,

        s: `Varzyablu`,
        tit: `Вареники з яблуками`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, яблука, вода питна, яйця курячі.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (3-5 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
    ],
  },
  {
    img: pel,
    img2: pel2,
    title: "Пельмені",
    slug: "pel",
    id: 2,
    prod: [
      {
        n: 1051,
        i: pelBil,
        i2: pelBil2,

        s: `PelBil`,
        tit: `Пельмені "Білоцерківські"`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, вода питна, свинина, філе куряче, яловичина, цибуля ріпчаста свіжа, сало, яйця курячі, сіль кухонна, олія соняшникова, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (5-7 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 1071,
        i: pelBil,
        i2: pelBil2,

        s: `PelDom`,
        tit: `Пельмені "Домашні"`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, вода питна, свинина, філе куряче, яловичина, цибуля ріпчаста свіжа, сало, яйця курячі, сіль кухонна, олія соняшникова, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (5-7 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 1081,
        i: pelApa,
        i2: pelApa2,

        s: `PelKoz`,
        tit: `Пельмені "Козацькі"`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, яловичина, свинина, вода питна, цибуля ріпчаста свіжа, сіль кухонна, цукор, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (5-7 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 1101,
        i: pelGno,
        i2: pelGno2,

        s: `PelMalindy`,
        tit: `Пельмені "Малюк" індичі`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, вода питна, м'ясо індиче, цибуля ріпчаста свіжа, сало, яйця курячі, сіль кухонна, олія соняшникова, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (5-7 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 1131,
        i: pelApa,
        i2: pelApa2,

        s: `PelSyt`,
        tit: `Пельмені "Ситні"`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, яловичина, свинина, вода питна, цибуля ріпчаста свіжа, сіль кухонна, цукор, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (5-7 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 1171,
        i: pelBil,
        i2: pelBil2,

        s: `Pelzmjyasindy`,
        tit: `Пельмені з м'ясом індички`,
        ng: `Напівфабрикат`,
        sklad: `тісто (50%) – борошно пшеничне в/г, вода питна, олія соняшникова; 
        фарш (50%) – м'ясо індички (41,7%), цибуля ріпчаста свіжа, сіль кухонна, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (5-7 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 1181,
        i: pelBil,
        i2: pelBil2,

        s: `Pelzyalov`,
        tit: `Пельмені з яловичиною`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, вода питна, яловичина, цибуля ріпчаста свіжа, яйця курячі, сіль кухонна, олія соняшникова, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (5-7 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 1201,
        i: pelGno,
        i2: pelGno2,

        s: `PelMiniDel`,
        tit: `Пельмені МІНІ "Делікатесні"`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, м'ясо курки, сало, вода питна, цибуля ріпчаста свіжа, сіль кухонна, олія соняшникова рафінована, яйця курячі, перець чорний мелений.`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (5-7 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 1202,
        i: pelBul,
        i2: pelBul2,

        s: `PelBul`,
        tit: `Пельмені "Бульмені"`,
        ng: `Напівфабрикат`,
        sklad: `тісто (55%) – борошно пшеничне в/г, вода питна, олія соняшникова; фарш (45%) – м'ясо птиці, сало свинне, цибуля ріпчаста свіжа, сіль кухонна, спеції для напівфабрикатів (сіль кухонна, коріандр мелений, цукор, перець чорний мелений, імбир мелений, мускатний горіх, перець духмяний, натуральний екстракт перцю).`,
        sp: `заморожені вироби занурити в підсолений окріп у широкій кастрюлі (на 1 частину продукту 4 частини води, сіль за смаком), помішуючи довести до кипіння та варити до готовності (5-7 хв. кип'ятіння після спливання), потім вироби вийняти шумівкою та подати з маслом або сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
    ],
  },
  {
    img: kot,
    img2: kot2,
    title: "Котлети",
    slug: "kot",
    id: 3,
    prod: [
      {
        n: 551,
        i: kotBil,
        i2: kotBil2,

        s: `KotBil`,
        tit: `Котлети "Білоцерківські"`,
        ng: `Напівфабрикат`,
        sklad: `фарш курячий, сухарі панірувальні, вода питна, сало, хліб пшеничний, масло вершкове, клітковина пшенична, цибуля ріпчаста свіжа, яйця курячі, сіль кухонна, зелень, перець чорний мелений.`,
        sp: `обсмажити у фритюрі 1-2 хв. до золотистої скоринки, а потім довести до готовності в духовій шафі при температурі 140-150°С.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 561,
        i: kotZvy,
        i2: kotZvy2,

        s: `KotDom`,
        tit: `Котлети "Домашні"`,
        ng: `Напівфабрикат`,
        sklad: `яловичина, свинина, вода питна, хліб пшеничний, сухарі панірувальні, цибуля ріпчаста свіжа, сіль кухонна, яйця курячі, перець чорний мелений, часник сушений.`,
        sp: `смажити звичайним способом з двох сторін у невеликій кількості жиру.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 581,
        i: kotKyi,
        i2: kotKyi2,

        s: `KotKyji`,
        tit: `Котлети "Київські"`,
        ng: `Напівфабрикат`,
        sklad: `свинина, м'ясо куряче, цибуля свіжа ріпчаста, яйця курячі, масло вершкове, сухарі панірувальні, сіль кухонна, перець чорний мелений.`,
        sp: `смажити звичайним способом з двох сторін у невеликій кількості жиру.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 591,
        i: kotKyi,
        i2: kotKyi2,

        s: `KotKyjizindy`,
        tit: `Котлети "Київські" з індички`,
        ng: `Напівфабрикат`,
        sklad: `м'ясо індички (75%), цибуля ріпчаста, масло вершкове (10%), сухарі панірувальні, яйця курячі, олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `смажити у фритюрі, довести до готовності в духовій шафі.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 601,
        i: kotKor,
        i2: kotKor2,

        s: `KotKorBlyu`,
        tit: `Котлети "Кордон Блю"`,
        ng: `Напівфабрикат`,
        sklad: `куряче філе, вітчина, сир твердий, борошно пшеничне в/г, сухарі панірувальні, цибуля ріпчаста свіжа, яйця курячі, сіль кухонна, перець чорний мелений.`,
        sp: `смажити у великій кількості жиру (фритюрі) на середньому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 661,
        i: kotZvy,
        i2: kotZvy2,

        s: `KotSto`,
        tit: `Котлети "Столові"`,
        ng: `Напівфабрикат`,
        sklad: `фарш курячий, сало, вода питна, хліб пшеничний, сухарі панірувальні, пшенична клітковина, цибуля ріпчаста свіжа, сіль кухонна, часник сушений, перець чорний мелений.`,
        sp: `смажити звичайним способом з двох сторін у невеликій кількості жиру.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 668,
        i: kotNiz,
        i2: kotNiz2,

        s: `KotNiz`,
        tit: `Котлети "Ніжні"`,
        ng: `Напівфабрикат`,
        sklad: `куряче філе, цибуля ріпчаста свіжа, сухарі панірувальні (борошно пшеничне в/г, дріжджі пресовані, сіль кухонна, барвник натуральний – екстракт паприки), сіль кухонна, яйця курячі, перець чорний мелений.`,
        sp: `смажити до готовності на середньому вогні.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 672,
        i: kotKru,
        i2: kotKru2,

        s: `KotGre`,
        tit: `Котлети "Гречневики"`,
        ng: `Напівфабрикат`,
        sklad: `фарш курячий, гречка відварна, олія соняшникова, сало свинини, цибуля ріпчаста свіжа, борошно пшеничне в/г, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 3-5 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 711,
        i: kotRyb,
        i2: kotRyb2,

        s: `KotrybnzfileSuda`,
        tit: `Котлети рибні з філе Судака`,
        ng: `Напівфабрикат`,
        sklad: `філе риби мороженої, цибуля ріпчаста свіжа, хліб білий, сухарі панірувальні, сіль кухонна, перець чорний мелений.`,
        sp: `смажити звичайним способом з двох сторін у невеликій кількості жиру.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 131,
        i: bur,
        i2: bur2,

        s: `Burzkurya`,
        tit: `Бургери з курятини`,
        ng: `Готово до вживання`,
        sklad: `м'ясо куряче, цибуля ріпчаста свіжа, хліб пшеничний білий, вода питна, олія соняшникова, сіль кухонна, борошно пшеничне в/г, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв., або смажити на сковороді з двох сторін до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 141,
        i: bur,
        i2: bur2,

        s: `Burzyalov`,
        tit: `Бургери з яловичини`,
        ng: `Готово до вживання`,
        sklad: `яловичина, цибуля ріпчаста свіжа, хліб пшеничний білий, вода питна, олія соняшникова, сіль кухонна, борошно пшеничне в/г, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв., або смажити на сковороді з двох сторін до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
    ],
  },
  {
    img: mly,
    img2: mly2,
    title: "Млинці",
    slug: "mly",
    id: 4,
    prod: [
      {
        n: 761,
        i: mlyVes,
        i2: mlyVes2,

        s: `MlyVes`,
        tit: `Млинці "Веснянка"`,
        ng: `Напівфабрикат`,
        sklad: `вода питна, борошно пшеничне в/г, сир кисломолочний, джем фруктовий, макова начинка (мак харчовий, цукор білий кристалічний, патока крохмальна, вода питна, крохмаль кукурудзяний, консервант - сорбінова кислота Е200), цукор, яйця курячі, родзинки, олія соняшникова, крупа манна, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 771,
        i: mlyVys,
        i2: mlyVys2,

        s: `MlyVysh`,
        tit: `Млинці "Вишиванка"`,
        ng: `Напівфабрикат`,
        sklad: `вода питна, борошно пшеничне в/г, сир кисломолочний, вишня заморожена, макова начинка (мак харчовий, цукор білий кристалічний, патока крохмальна, вода питна, крохмаль кукурудзяний, консервант - сорбінова кислота Е200), цукор, яйця курячі, олія соняшникова, родзинки, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 791,
        i: mlyZvy,
        i2: mlyZvy2,

        s: `Mlyzvyshn`,
        tit: `Млинці з вишнями`,
        ng: `Напівфабрикат`,
        sklad: `вишня заморожена, вода питна, борошно пшеничне в/г, цукор, яйця курячі, олія соняшникова, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 811,
        i: mlyZvy,
        i2: mlyZvy2,

        s: `Mlyzmako`,
        tit: `Млинці з маком`,
        ng: `Напівфабрикат`,
        sklad: `макова начинка (мак харчовий, цукор білий кристалічний, патока крохмальна, вода питна, крохмаль кукурудзяний, консервант - сорбінова кислота Е200), вода питна, борошно пшеничне в/г, цукор, яйця курячі, олія соняшникова, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 871,
        i: mlyZvy,
        i2: mlyZvy2,

        s: `Mlyzsyro`,
        tit: `Млинці з сиром`,
        ng: `Напівфабрикат`,
        sklad: `сир кисломолочний, вода питна, борошно пшеничне в/г, цукор, яйця курячі, олія соняшникова, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 881,
        i: mlyZvy,
        i2: mlyZvy2,

        s: `Mlyzyablu`,
        tit: `Млинці з яблуками`,
        ng: `Напівфабрикат`,
        sklad: `яблука, вода питна, борошно пшеничне в/г, цукор, яйця курячі, олія соняшникова, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 801,
        i: mlyZvy,
        i2: mlyZvy2,

        s: `mlyzgry`,
        tit: `Млинці з грибами`,
        ng: `Напівфабрикат`,
        sklad: `молоко пастеризоване, гриби глива, борошно пшеничне в/г, цибуля ріпчаста свіжа, яйця курячі, олія соняшникова, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 821,
        i: mlyZvy,
        i2: mlyZvy2,

        s: `Mlyzmia`,
        tit: `Млинці з м'ясом`,
        ng: `Напівфабрикат`,
        sklad: `вода питна, яловичина, свинина, борошно пшеничне в/г, цибуля ріпчаста свіжа, яйця курячі, цукор, сіль кухонна, олія соняшникова, перець чорний мелений.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 831,
        i: mlyZvy,
        i2: mlyZvy2,

        s: `Mlyzmiaind`,
        tit: `Млинці з м'ясом індички`,
        ng: `Напівфабрикат`,
        sklad: `тісто (55%) - вода питна, борошно пшеничне в/г, яйця курячі, олія соняшникова, сіль кухонна, цукор; начинка (45%) - м'ясо індички (37,5%), цибуля ріпчаста свіжа, олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 941,
        i: rolMia,
        i2: rolMia2,

        s: `MlyRolizmia`,
        tit: `Млинці РОЛИ з м'ясом`,
        ng: `Напівфабрикат`,
        sklad: `вода питна, яловичина, свинина, борошно пшеничне в/г, цибуля ріпчаста свіжа, яйця курячі, цукор, сіль кухонна, олія соняшникова, перець чорний мелений.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 961,
        i: rolSyr,
        i2: rolSyr2,

        s: `MlyRolizsyr`,
        tit: `Млинці РОЛИ з сиром`,
        ng: `Напівфабрикат`,
        sklad: `сир кисломолочний, вода питна, борошно пшеничне в/г, цукор, яйця курячі, олія соняшникова, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 931,
        i: rolVys,
        i2: rolVys2,

        s: `MlyRolizvys`,
        tit: `Млинці РОЛИ з вишнею`,
        ng: `Напівфабрикат`,
        sklad: `вишня заморожена, вода питна, борошно пшеничне в/г, цукор, яйця курячі, олія соняшникова, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 951,
        i: rolDom,
        i2: rolDom2,

        s: `RoliDomzmia`,
        tit: `РОЛИ "Домашні" з м'ясом`,
        ng: `Напівфабрикат`,
        sklad: `вода питна, борошно пшеничне в/г, фарш курячий, цибуля ріпчаста свіжа, яйця курячі, цукор, сіль кухонна, олія соняшникова, перець чорний мелений.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 971,
        i: rolDom,
        i2: rolDom2,

        s: `RoliDomzsyr`,
        tit: `РОЛИ "Домашні" з сиром`,
        ng: `Напівфабрикат`,
        sklad: `сир кисломолочний, вода питна, борошно пшеничне в/г, цукор, яйця курячі, олія соняшникова, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 972,
        i: rolDom,
        i2: rolDom2,

        s: `RoliDomzabr`,
        tit: `РОЛИ "Домашні" з абрикосовим джемом`,
        ng: `Напівфабрикат`,
        sklad: `абрикосовий джем, вода питна, борошно пшеничне в/г, цукор, яйця курячі, олія соняшникова, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 973,
        i: rolDom,
        i2: rolDom2,

        s: `RoliDomzpol`,
        tit: `РОЛИ "Домашні" з полуничним джемом`,
        ng: `Напівфабрикат`,
        sklad: `полуничний джем, вода питна, борошно пшеничне в/г, цукор, яйця курячі, олія соняшникова, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 974,
        i: rolDom,
        i2: rolDom2,

        s: `RoliDomzjab`,
        tit: `РОЛИ "Домашні" з яблуками`,
        ng: `Напівфабрикат`,
        sklad: `яблука, вода питна, борошно пшеничне в/г, цукор, яйця курячі, олія соняшникова, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 975,
        i: rolDom,
        i2: rolDom2,

        s: `RoliDomzizgu`,
        tit: `РОЛИ "Домашні" зі згущеним молоком`,
        ng: `Напівфабрикат`,
        sklad: `згущене молоко, вода питна, борошно пшеничне в/г, цукор, яйця курячі, олія соняшникова, сіль кухонна.`,
        sp: `обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
    ],
  },
  {
    img: zra,
    img2: zra2,
    title: "Зрази",
    slug: "zra",
    id: 5,
    prod: [
      {
        n: 431,
        i: zraKar,
        i2: zraKar2,

        s: `Zrakartzgryb`,
        tit: `Зрази картопляні з грибами`,
        ng: `Напівфабрикат`,
        sklad: `картопля свіжа, цибуля ріпчаста свіжа, гриби, сухарі панірувальні, олія соняшникова, яйця курячі, борошно пшеничне в/г, сіль кухонна, перець чорний мелений.`,
        sp: `покласти зрази у невелику кількість розігрітого жиру і смажити до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 441,
        i: zraKar,
        i2: zraKar2,

        s: `Zrakartzkapu`,
        tit: `Зрази картопляні з капустою`,
        ng: `Напівфабрикат`,
        sklad: `картопля свіжа, капуста свіжа, сухарі панірувальні, цибуля ріпчаста свіжа, олія соняшникова, яйця курячі, борошно пшеничне в/г, сіль кухонна, перець чорний мелений.`,
        sp: `покласти зрази у невелику кількість розігрітого жиру і смажити до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 451,
        i: zraKar,
        i2: zraKar2,

        s: `Zrakartzmjyas`,
        tit: `Зрази картопляні з м'ясом`,
        ng: `Напівфабрикат`,
        sklad: `картопля свіжа, яловичина, свинина, сухарі панірувальні, цибуля ріпчаста свіжа, олія соняшникова, яйця курячі, борошно пшеничне в/г, сіль кухонна, перець чорний мелений.`,
        sp: `покласти зрази у невелику кількість розігрітого жиру і смажити до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 481,
        i: zraKar,
        i2: zraKar2,

        s: `Zramjyaszgryb`,
        tit: `Зрази м'ясні з грибами`,
        ng: `Напівфабрикат`,
        sklad: `свинина, яловичина, гриби, вода питна, сухарі панірувальні, цибуля ріпчаста свіжа, хліб пшеничний, олія соняшникова, сіль кухонна, яйця курячі, перець чорний мелений.`,
        sp: `покласти зрази у невелику кількість розігрітого жиру і смажити до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
    ],
  },
  {
    img: syr,
    img2: syr2,
    title: "Сирники",
    slug: "syr",
    id: 6,
    prod: [
      {
        n: 1731,
        i: syrNF,
        i2: syrNF2,

        s: `Syr`,
        tit: `Сирники`,
        ng: `Напівфабрикат`,
        sklad: `сир кисломолочний, борошно пшеничне в/г, олія, цукор, яйця курячі, сіль кухонна, ванільний цукор.`,
        sp: `заморожені вироби відправити на добре розігріту сковороду з жиром, на невеликому вогні обсмажити їх 10-15 хв. з обох боків до готовності. Подавати зі сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1741,
        i: syrNF,
        i2: syrNF2,

        s: `Syrzabrydzhem`,
        tit: `Сирники з абрикосовим джемом`,
        ng: `Напівфабрикат`,
        sklad: `сир кисломолочний, крупа манна, олія, джем абрикосовий, цукор, яйця курячі, сіль кухонна.`,
        sp: `заморожені вироби відправити на добре розігріту сковороду з жиром, на невеликому вогні обсмажити їх 10-15 хв. з обох боків до готовності. Подавати зі сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1751,
        i: syrNF,
        i2: syrNF2,

        s: `Syrzvyshndzhem`,
        tit: `Сирники з вишневим джемом`,
        ng: `Напівфабрикат`,
        sklad: `сир кисломолочний, крупа манна, олія, джем вишневий, цукор, яйця курячі, сіль кухонна.`,
        sp: `заморожені вироби відправити на добре розігріту сковороду з жиром, на невеликому вогні обсмажити їх 10-15 хв. з обох боків до готовності. Подавати зі сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1761,
        i: syrNF,
        i2: syrNF2,

        s: `Syrzmalydzhem`,
        tit: `Сирники з малиновим джемом`,
        ng: `Напівфабрикат`,
        sklad: `сир кисломолочний, крупа манна, олія, джем малиновий, цукор, яйця курячі, сіль кухонна.`,
        sp: `заморожені вироби відправити на добре розігріту сковороду з жиром, на невеликому вогні обсмажити їх 10-15 хв. з обох боків до готовності. Подавати зі сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1771,
        i: syrNF,
        i2: syrNF2,

        s: `Syrzpersdzhem`,
        tit: `Сирники з персиковим джемом`,
        ng: `Напівфабрикат`,
        sklad: `сир кисломолочний, крупа манна, олія, джем персиковий, цукор, яйця курячі, сіль кухонна.`,
        sp: `заморожені вироби відправити на добре розігріту сковороду з жиром, на невеликому вогні обсмажити їх 10-15 хв. з обох боків до готовності. Подавати зі сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1781,
        i: syrNF,
        i2: syrNF2,

        s: `Syrzpoludzhem`,
        tit: `Сирники з полуничним джемом`,
        ng: `Напівфабрикат`,
        sklad: `сир кисломолочний, крупа манна, олія, джем полуничний, цукор, яйця курячі, сіль кухонна.`,
        sp: `заморожені вироби відправити на добре розігріту сковороду з жиром, на невеликому вогні обсмажити їх 10-15 хв. з обох боків до готовності. Подавати зі сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 1801,
        i: syrNF,
        i2: syrNF2,

        s: `Syrzshokodzhem`,
        tit: `Сирники з шоколадним джемом`,
        ng: `Напівфабрикат`,
        sklad: `сир кисломолочний, крупа манна, олія, джем шоколадний, цукор, яйця курячі, сіль кухонна.`,
        sp: `заморожені вироби відправити на добре розігріту сковороду з жиром, на невеликому вогні обсмажити їх 10-15 хв. з обох боків до готовності. Подавати зі сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1791,
        i: syrRodz,
        i2: syrRodz2,

        s: `Syrzrodz`,
        tit: `Сирники з родзинками`,
        ng: `Напівфабрикат`,
        sklad: `сир кисломолочний, борошно пшеничне в/г, олія, родзинки, цукор, яйця курячі, сіль кухонна, ванільний цукор.`,
        sp: `заморожені вироби відправити на добре розігріту сковороду з жиром, на невеликому вогні обсмажити їх 10-15 хв. з обох боків до готовності. Подавати зі сметаною.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
    ],
  },
  {
    img: vyp,
    img2: vyp2,
    title: "Випічка",
    slug: "vyp",
    id: 7,
    prod: [
      {
        n: 1291,
        i: pyrPecKos,

        i2: pyrPecKos2,

        s: `Pyrzvyshn`,
        tit: `Пиріжки з вишнею`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, вишня заморожена, дріжджі пресовані, цукор, олія соняшникова, сіль кухонна, яйця курячі.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1301,
        i: pyrPec,

        i2: pyrPec2,

        s: `Pyrzgoro`,
        tit: `Пиріжки з горохом`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, дріжджі пресовані, цукор, горох відварений, цибуля ріпчаста свіжа, олія соняшникова, яйця курячі, сіль кухонна, перець чорний мелений.  `,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1311,
        i: pyrPec,

        i2: pyrPec2,

        s: `Pyrzkapu`,
        tit: `Пиріжки з капустою`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, капуста, олія соняшникова, цибуля ріпчаста свіжа, дріжджі пресовані, цукор, морква, сіль кухонна, яйця курячі, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 1331,
        i: pyrPec,

        i2: pyrPec2,

        s: `Pyrzkart`,
        tit: `Пиріжки з картоплею`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, картопля свіжа, цибуля ріпчаста свіжа, сало, дріжджі пресовані, цукор, олія соняшникова, сіль кухонна, яйця курячі, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1341,
        i: pyrPec,

        i2: pyrPec2,

        s: `Pyrzlive`,
        tit: `Пиріжки з лівером`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, ялові легені, серце та печінка, дріжджі пресовані, сало, вода питна, цибуля ріпчаста свіжа, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1351,
        i: pyrPecKos,

        i2: pyrPecKos2,

        s: `Pyrzmako`,
        tit: `Пиріжки з маком`,
        ng: `Готово до вживання`,
        sklad: `тісто (борошно пшеничне в/г, вода питна, олія соняшникова рафінована, дріжджі пресовані, цукор, сіль кухонна); начинка макова (мак харчовий 30%, цукор, патока крохмальна, вода питна, крохмаль кукурудзяний, консервант – сорбінова кислота Е200); яйця курячі для змащування пиріжків.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 1391,
        i: pyrPec,

        i2: pyrPec2,

        s: `Pyrzpovy`,
        tit: `Пиріжки з повидлом`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, дріжджі пресовані, олія соняшникова, цукор, повидло, сіль кухонна, яйця курячі.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 1411,
        i: pyrPecKos,

        i2: pyrPecKos2,

        s: `Pyrzyablu`,
        tit: `Пиріжки з яблуками`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, яблука, дріжджі пресовані, цукор, олія соняшникова, сіль кухонна, яйця курячі.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 1431,
        i: pyrPec,

        i2: pyrPec2,

        s: `Pyrzyajtserysoitsybu`,
        tit: `Пиріжки з яйцем, рисом і цибулею`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, рис відварений, дріжджі пресовані, цукор, яйця курячі, цибуля ріпчаста свіжа, олія соняшникова, сіль кухонна, кріп сушений, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 1451,
        i: pyrPecKos,

        i2: pyrPecKos2,

        s: `Pyrzislyv`,
        tit: `Пиріжки зі сливою`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, слива заморожена, дріжджі пресовані, цукор, олія соняшникова, сіль кухонна, яйця курячі.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1461,
        i: pyrPecMal,

        i2: pyrPecMal2,

        s: `PyrMINIzvyshn`,
        tit: `Пиріжки МІНІ з вишнею`,
        ng: `Готово до вживання`,
        sklad: `тісто: листково-дріжджове (борошно пшеничне в/г, маргарин (жири рослинні та олії в натуральному й ствердлому вигляді рафіновані дезодоровані) вода питна, цукор, дріжджі пресовані, яйця курячі, сіль кухонна);
        начинка: (вишня заморожена, цукор).`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1471,
        i: pyrPecMal,

        i2: pyrPecMal2,

        s: `PyrMINIzkapu`,
        tit: `Пиріжки МІНІ з капустою`,
        ng: `Готово до вживання`,
        sklad: `тісто: листково-дріжджове (борошно пшеничне в/г, маргарин (жири рослинні та олії в натуральному й ствердлому вигляді рафіновані дезодоровані) вода питна, цукор, дріжджі пресовані, яйця курячі, сіль кухонна);  начинка: (капуста свіжа, морква свіжа, олія соняшникова рафінована, сіль кухонна, перець чорний мелений).`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1481,
        i: pyrPecMal,

        i2: pyrPecMal2,

        s: `PyrMINIzkarttasmazhtsybu`,
        tit: `Пиріжки МІНІ з картоплею та смаж. цибулею`,
        ng: `Готово до вживання`,
        sklad: `тісто: листково-дріжджове (борошно пшеничне в/г, маргарин (жири рослинні та олії в натуральному й ствердлому вигляді рафіновані дезодоровані) вода питна, цукор, дріжджі пресовані, яйця курячі, сіль кухонна);  начинка: (картопля свіжа, цибуля ріпчаста свіжа, олія соняшникова рафінована, сіль кухонна, перець чорний мелений).`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1491,
        i: pyrPecMal,

        i2: pyrPecMal2,

        s: `PyrMINIzmjyaskurk`,
        tit: `Пиріжки МІНІ з м'ясом курки`,
        ng: `Готово до вживання`,
        sklad: `тісто: листково-дріжджове (борошно пшеничне в/г, маргарин (жири рослинні та олії в натуральному й ствердлому вигляді рафіновані дезодоровані) вода питна, цукор, дріжджі пресовані, яйця курячі, сіль кухонна);  начинка: (м'ясо куряче, олія соняшникова рафінована, сіль кухонна, перець чорний мелений).`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1501,
        i: pyrPecMal,

        i2: pyrPecMal2,

        s: `PyrMINIzpovy`,
        tit: `Пиріжки МІНІ з повидлом`,
        ng: `Готово до вживання`,
        sklad: `тісто: листково-дріжджове (борошно пшеничне в/г, маргарин (жири рослинні та олії в натуральному й ствердлому вигляді рафіновані дезодоровані) вода питна, цукор, дріжджі пресовані, яйця курячі, сіль кухонна);  начинка: (повидло яблучне).`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1511,
        i: pyrPecMal,

        i2: pyrPecMal2,

        s: `PyrMINIzsyrosol`,
        tit: `Пиріжки МІНІ з сиром солодким`,
        ng: `Готово до вживання`,
        sklad: `тісто: листково-дріжджове (борошно пшеничне в/г, маргарин (жири рослинні та олії в натуральному й ствердлому вигляді рафіновані дезодоровані) вода питна, цукор, дріжджі пресовані, яйця курячі, сіль кухонна);  начинка: (сир кисломолочний, цукор, ароматизатор - ванілін).`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1521,
        i: pyrPecMal,

        i2: pyrPecMal2,

        s: `PyrMINIzsyrosolo`,
        tit: `Пиріжки МІНІ з сиром солоним`,
        ng: `Готово до вживання`,
        sklad: `тісто: листково-дріжджове (борошно пшеничне в/г, маргарин (жири рослинні та олії в натуральному й ствердлому вигляді рафіновані дезодоровані) вода питна, цукор, дріжджі пресовані, яйця курячі, сіль кухонна);  начинка: (сир кисломолочний, сіль кухонна).`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1531,
        i: pyrPecMal,

        i2: pyrPecMal2,

        s: `PyrMINIzsyrotakrop`,
        tit: `Пиріжки МІНІ з сиром та кропом`,
        ng: `Готово до вживання`,
        sklad: `тісто: листково-дріжджове (борошно пшеничне в/г, маргарин (жири рослинні та олії в натуральному й ствердлому вигляді рафіновані дезодоровані) вода питна, цукор, дріжджі пресовані, яйця курячі, сіль кухонна);  начинка: (сир кисломолочний, кріп сушений, сіль кухонна, перець черний мелений).`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1541,
        i: pyrPecMal,

        i2: pyrPecMal2,

        s: `PyrMINIzyalov`,
        tit: `Пиріжки МІНІ з яловичиною`,
        ng: `Готово до вживання`,
        sklad: `тісто: листково-дріжджове (борошно пшеничне в/г, маргарин (жири рослинні та олії в натуральному й ствердлому вигляді рафіновані дезодоровані) вода питна, цукор, дріжджі пресовані, яйця курячі, сіль кухонна);  начинка: (яловичина, олія соняшникова рафінована, сіль кухонна, перець чорний мелений).`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1551,
        i: pyrPecMal,

        i2: pyrPecMal2,

        s: `PyrMINIzismor`,
        tit: `Пиріжки МІНІ зі смородиною`,
        ng: `Готово до вживання`,
        sklad: `тісто: листково-дріжджове (борошно пшеничне в/г, маргарин (жири рослинні та олії в натуральному й ствердлому вигляді рафіновані дезодоровані) вода питна, цукор, дріжджі пресовані, яйця курячі, сіль кухонна);  начинка: (смородина заморожена, цукор білий кристалічний).`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1821,
        i: sosTisKro,

        i2: sosTisKro2,

        s: `SosBavavtistKRO`,
        tit: `Сосиски Баварські в тісті "КРОХА"`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, олія соняшникова, дріжджі пресовані, сіль кухонна, яйця курячі; сосиска: сировина м'ясна 100%.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1831,
        i: sosTisPec,

        i2: sosTisPec2,

        s: `Sosvtist`,
        tit: `Сосиски в тісті `,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, сосиска (м'ясо птиці, свинина, крупа манна, крохмал, білок рослинного походження, сіль кухонна, стабілізатори Е451, Е450, підсилювач смаку Е621, антиоксиданти Е301, Е575, Е316, Е330, мальтодекстрим, екстракт паприки, екстракт червогого вина, нітрит натрію), олія соняшникова, дріжджі пресовані, сіль кухонна, яйця курячі.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1841,
        i: sosTisPec,

        i2: sosTisPec2,

        s: `Sosvtistkopch`,
        tit: `Сосиски в тісті копчені`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, сосиска копчена, олія соняшникова, дріжджі пресовані, сіль кухонна, яйця курячі.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 681,
        i: kotTis,

        i2: kotTis2,

        s: `Kotvtist`,
        tit: `Котлети в тісті `,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, свинина, яловичина, дріжджі пресовані, цукор, цибуля ріпчаста свіжа, олія соняшникова, сало, хліб пшеничний, сухарі панірувальні, сіль кухонна, яйця курячі, часник сушений, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 3-5 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 691,
        i: kotTis,

        i2: kotTis2,

        s: `KotvtistSto`,
        tit: `Котлети в тісті "Столові"`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, м'ясо птиці механічної обвалки, сало шпик, вода питна, хліб пшеничний, сухарі панірувальні, пшенична клітковина, цибуля ріпчаста свіжа, сіль кухонна, часник сушений, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 3-5 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1591,
        i: pitKur,

        i2: pitKur2,

        s: `Pitszkurya`,
        tit: `Піца з курятиною`,
        ng: `Готово до вживання`,
        sklad: `м'ясо курки, борошно пшеничне в/г, гриби, сир твердий, соус томатний, майнез, морква свіжа, цибуля ріпчаста свіжа, спеції, сіль кухонна, дріжджі пресовані, вода питна.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1601,
        i: pitKur,

        i2: pitKur2,

        s: `Pitszisvyn`,
        tit: `Піца зі свининою`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, м'ясо свинини, гриби (глива), цибуля ріпчаста свіжа, майонез, сир твердий, морква свіжа, дріжджі пресовані, олія соняшникова, соус томатний, цукор, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1001,
        i: osnPit,

        i2: osnPit2,

        s: `Osndlyapitsy`,
        tit: `Основа для піци `,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, маргарин (жири рослинні та олії в натуральному та сведлому вигляді, рафіновані, дезодоровані), вода питна, яйця курячі, дріжджі пресовані, регулятор кислотності - оцет, сіль кухонна, цукор.`,
        sp: `коржі для піци розморожувати протягом 5-10 хвилин. На поверхню рівномірно розкласти начинку, посолити за смаком і випікати в духовці при температурі 200-220°С  протягом 7-10 хвилин, або приготувати в мікрохвильовій печі на максимальній потужності від 3 до 5 хвилин.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 391,
        i: zagPit,

        i2: zagPit2,

        s: `ZagdlyaPitsi`,
        tit: `Заготовка для піци`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, олія соняшникова, дріжджі пресовані, цукор, сіль кухонна, яйця курячі.`,
        sp: `розморозити 5-10 хвилин. На поверхню рівномірно розкласти начинку і випікати в духовій шафі при t 200 - 220°С  7-10 хв., або готувати в мікрохвильовій печі 3-5 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 401,
        i: zap,

        i2: zap2,

        s: `Zapsyrnzrodz`,
        tit: `Запіканка сирна з родзинками`,
        ng: `Готово до вживання`,
        sklad: `сир кисломолочний, сметана, цукор, крупа манна, яйця курячі, родзинки, сіль кухонна, ванільний цукор.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 1-2 хв. Подавати зі сметаною або варенням.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 1851,
        i: soch,

        i2: soch2,

        s: `Sochzsyro`,
        tit: `Сочник з сиром`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, сир кисломолочний, цукор, сметана, маргарин (жири рослинні і олії в натуральному і ствердлому вигляді рафіновані, дезодоровані), яйця курячі, розрихлювач, сода харчова, сіль кухонна.`,
        sp: `розморозити при кімнатній температурі. (Строк придатності після дефростації при температурі повітря (6±2)°С і відносній вологості повітря не вище 75% – 8 діб)`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 521,
        i: kex,

        i2: kex2,

        s: `Kek`,
        tit: `Кекс`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, цукор, кефір, олія соняшникова, сіль кухонна, яйця курячі, розрихлювач - сода харчова.`,
        sp: `розморозити при кімнатній температурі. (Строк придатності після дефростації при температурі повітря (6±2)°С і відносній вологості повітря не вище 75% – 8 діб).`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 541,
        i: kex,

        i2: kex2,

        s: `Keksyrn`,
        tit: `Кекс сирний`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, сир кисломолочний, цукор, яйця курячі, маргарин (жири рослинні і олії в натуральному і ствердлому вигляді рафіновані, дезодоровані), родзинки, сіль кухонна, розрихлювач - сода харчова, ароматизатор - ванільний.`,
        sp: `розморозити при кімнатній температурі. (Строк придатності після дефростації при температурі повітря (6±2)°С і відносній вологості повітря не вище 75% – 8 діб).`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 301,
        i: vatPec,

        i2: vatPec2,

        s: `Vatzsyrom`,
        tit: `Ватрушки з сиром`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, сир кисломолочний, дріжджі пресовані, цукор, сіль кухонна, яйця курячі, олія соняшникова.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 91,
        i: bulPec,

        i2: bulPec2,

        s: `Buldlyaburg`,
        tit: `Булочка для бургерів`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, олія соняшникова, дріжджі пресовані, цукор, сіль кухонна, яйця курячі.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 101,
        i: pyrPec,

        i2: pyrPec2,

        s: `Buldlyakhot`,
        tit: `Булочка для хот-догів`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, олія соняшникова, дріжджі пресовані, цукор, сіль кухонна, яйця курячі.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., використовувати для виготовлення хот-догів.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
    ],
  },
  {
    img: sma,
    img2: sma2,
    title: "Смажене",
    slug: "sma",
    id: 8,
    prod: [
      {
        n: 71,
        i: bilSma,
        i2: bilSma2,

        s: `Bil`,
        tit: `Біляші`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, свинина, яловичина, вода питна, цибуля ріпчаста свіжа, дріжджі пресовані, олія соняшникова, цукор білий кристалічний, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1170,
        i: pyrSma,
        i2: pyrSma2,

        s: `Perepichka`,
        tit: `Перепічка (сосиска в тісті)`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, сосиска, вода питна, олія соняшникова, дріжджі пресовані, сіль кухонна, цукор.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1171,
        i: pyrSma,
        i2: pyrSma2,

        s: `Pyrzgorosmazh`,
        tit: `Пиріжки з горохом`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, дріжджі пресовані, цукор, горох, цибуля ріпчаста свіжа, олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1221,
        i: pyrSma,
        i2: pyrSma2,

        s: `Pyrzkapusmazh`,
        tit: `Пиріжки з капустою`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, капуста свіжа, олія соняшникова, цибуля ріпчаста свіжа, дріжджі пресовані, цукор, морква свіжа, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 2581,
        i: pyrSma,
        i2: pyrSma2,

        s: `Pyrzkaputagryb`,
        tit: `Пиріжки з капустою та грибами`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, капуста, гриби, олія соняшникова, цибуля ріпчаста свіжа, дріжджі пресовані, цукор, морква, сіль кухонна, яйця курячі, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1271,
        i: pyrSma,
        i2: pyrSma2,

        s: `Pyrzkartsmazh`,
        tit: `Пиріжки з картоплею`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, картопля свіжа, цибуля ріпчаста свіжа, сало, дріжджі пресовані, цукор, олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1361,
        i: pyrSma,
        i2: pyrSma2,

        s: `Pyrzmjyas`,
        tit: `Пиріжки з м'ясом`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, м'ясо куряче, цибуля ріпчаста свіжа, дріжджі пресовані, олія соняшникова, цукор, сіль кухонна, яйця курячі.`,
        sp: `Розморозити продукт при температурі 22 ⁰С протягом 90 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 12 місяців від дати виготовлення.`,
      },
      {
        n: 1421,
        i: pyrSma,
        i2: pyrSma2,

        s: `Pyrzyajtseizeletsybu`,
        tit: `Пиріжки з яйцем і зеленою цибулею`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, дріжджі пресовані, яйця курячі, цибуля зелена, олія соняшникова, сіль кухонна, цукор, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 2941,
        i: pyrSma,
        i2: pyrSma2,

        s: `Pyrzsertstapechismazh`,
        tit: `Пиріжки з серцем та печінкою`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, ялові серце та печінка, дріжджі пресовані, сало, вода питна, цибуля ріпчаста свіжа, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 1401,
        i: pyrSmaApa,
        i2: pyrSmaApa2,

        s: `Pyrzsertstapechi`,
        tit: `Пиріжки з серцем та печінкою (апаратні)`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, вода питна, ялові серце та печінка, дріжджі пресовані, сало, вода питна, цибуля ріпчаста свіжа, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 2861,
        i: pyrSmaPov,
        i2: pyrSmaPov2,

        s: `Pyrzpovysmazh`,
        tit: `Пиріжки з повидлом`,
        ng: `Готово до вживання`,
        sklad: `тісто (борошно пшеничне в/г, вода питна, олія соняшникова рафінована, дріжджі пресовані, цукор, сіль кухонна); начинка (не менше 20%) – повидло яблучне (пюре яблучне, цукор).`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 1441,
        i: pyrSmaPov,
        i2: pyrSmaPov2,

        s: `Pyrzizguschmolo`,
        tit: `Пиріжки зі згущеним молоком`,
        ng: `Готово до вживання`,
        sklad: `тісто (борошно пшеничне в/г, вода питна, олія соняшникова рафінована, дріжджі пресовані, цукор, сіль кухонна); начинка (не менше 20%) – згущене молоко.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 2031,
        i: cheKru,
        i2: cheKru2,

        s: `ChebSlozmjyas`,
        tit: `Чебуреки "Слов'янські" з м'ясом`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, яловичина, свинина, вода питна, цибуля ріпчаста свіжа, олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 2071,
        i: cheKru,
        i2: cheKru2,

        s: `Cheb150g`,
        tit: `Чебуреки`,
        ng: `Готово до вживання`,
        sklad: `борошно пшеничне в/г, яловичина, свинина, вода питна, цибуля ріпчаста свіжа, олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 2091,
        i: cheKru,
        i2: cheKru2,

        s: `Chebzkapu`,
        tit: `Чебуреки з капустою`,
        ng: `Готово до вживання`,
        sklad: `тісто: борошно пшеничне в/г, вода питна, маргарин (жири рослинні і олії в натуральному і ствердлому вигляді рафіновані, дезодоровані), олія соняшникова, сіль кухонна;  начинка: капуста білокачанна свіжа, морква свіжа, цибуля ріпчаста свіжа, олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 2101,
        i: cheKru,
        i2: cheKru2,

        s: `Chebzkarttagryb`,
        tit: `Чебуреки з картоплею та грибами`,
        ng: `Готово до вживання`,
        sklad: `тісто: борошно пшеничне в/г, вода питна, маргарин (жири рослинні і олії в натуральному і ствердлому вигляді рафіновані, дезодоровані), олія соняшникова, сіль кухонна;  начинка: картопля свіжа, цибуля ріпчаста свіжа, гриби (глива), олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 2111,
        i: cheKru,
        i2: cheKru2,

        s: `Chebzkarttakrop`,
        tit: `Чебуреки з картоплею та кропом`,
        ng: `Готово до вживання`,
        sklad: `тісто: борошно пшеничне в/г, вода питна, маргарин (жири рослинні і олії в натуральному і ствердлому вигляді рафіновані, дезодоровані), олія соняшникова, сіль кухонна;  начинка: картопля свіжа, цибуля ріпчаста свіжа, кріп, олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `розморозити в мікрохвильовій печі протягом 2-3 хв., довести до готовності в середньому режимі 2-3 хв.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 2121,
        i: cheKru,
        i2: cheKru2,

        s: `Chebzsyrokm`,
        tit: `Чебуреки з сиром`,
        ng: `Готово до вживання`,
        sklad: `тісто: борошно пшеничне в/г, вода питна, маргарин (жири рослинні і олії в натуральному і ствердлому вигляді рафіновані, дезодоровані), олія соняшникова, сіль кухонна;  начинка: сир кисломолочний, сіль кухонна, перець чорний мелений.`,
        sp: `у невелику кількість розігрітого жиру (фритюр) покласти чебуреки і смажити до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
    ],
  },
  {
    img: riz,
    img2: riz2,
    title: "Різне",
    slug: "riz",
    id: 9,
    prod: [
      {
        n: 1,
        i: benVag,
        i2: benVag2,

        s: `Ben`,
        tit: `Бендерики `,
        ng: `Напівфабрикат`,
        sklad: `вода питна, борошно пшеничне в/г, свинина, яловичина, цибуля ріпчаста свіжа, яйця курячі, олія соняшникова, сіль кухонна, цукор, перець чорний мелений.`,
        sp: `Вироби змочити в яйці та обсмажити з обох сторін на малому вогні до готовності. Увага! М'ясний фарш сирий!`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 11,
        i: benVag,
        i2: benVag2,

        s: `BenVol`,
        tit: `Бендерики "Волинські"`,
        ng: `Напівфабрикат`,
        sklad: `вода питна, борошно пшеничне в/г, м'ясо птиці, цибуля ріпчаста свіжа, яйця курячі, олія соняшникова, сіль кухонна, цукор, перець чорний мелений.`,
        sp: `Вироби змочити в яйці та обсмажити з обох сторін на малому вогні до готовності. Увага! М'ясний фарш сирий!`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 21,
        i: benVag,
        i2: benVag2,

        s: `Benzkapu`,
        tit: `Бендерики з капустою`,
        ng: `Напівфабрикат`,
        sklad: `вода питна, капуста, борошно пшеничне в/г, цибуля ріпчаста свіжа, морква свіжа, яйця курячі, олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `Вироби змочити в яйці та обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 31,
        i: benVag,
        i2: benVag2,

        s: `Benzkart`,
        tit: `Бендерики з картоплею`,
        ng: `Напівфабрикат`,
        sklad: `вода питна, картопля свіжа, борошно пшеничне в/г, цибуля ріпчаста свіжа, яйця курячі, олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `Вироби змочити в яйці та обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 41,
        i: benVag,
        i2: benVag2,

        s: `Benzmjyas`,
        tit: `Бендерики з м'ясом`,
        ng: `Напівфабрикат`,
        sklad: `вода питна, борошно пшеничне в/г, свинина, яловичина, цибуля ріпчаста свіжа, яйця курячі, олія соняшникова, сіль кухонна, цукор, перець чорний мелений.`,
        sp: `Вироби змочити в яйці та обсмажити з обох сторін на малому вогні до готовності. Увага! М'ясний фарш сирий!`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 51,
        i: benVag,
        i2: benVag2,

        s: `Benzsyro`,
        tit: `Бендерики з сиром`,
        ng: `Напівфабрикат`,
        sklad: `вода питна, сир кисломолочний, борошно пшеничне в/г, яйця курячі, олія соняшникова, сіль кухонна.`,
        sp: `Вироби змочити в яйці та обсмажити з обох сторін на малому вогні до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 321,
        i: gol,
        i2: gol2,

        s: `GolDom`,
        tit: `Голубці "Домашні"`,
        ng: `Напівфабрикат`,
        sklad: `капуста свіжа, свинина, яловичина, рис відварний, цибуля свіжа ріпчаста, олія соняшникова, морква свіжа, сіль кухонна, перець чорний мелений.`,
        sp: `голубці обсмажити з обох сторін, скласти в посудину, залити томатним соусом і тушкувати до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 331,
        i: gol,
        i2: gol2,

        s: `GolSlo`,
        tit: `Голубці "Слов'янські"`,
        ng: `Напівфабрикат`,
        sklad: `капуста свіжа, м'ясо птиці, цибуля ріпчаста свіжа, свинина, рис відварний, морква свіжа, вода питна, сіль кухонна, суміш прянощів.`,
        sp: `голубці обсмажити з обох сторін, скласти в посудину, залити томатним соусом і тушкувати до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 341,
        i: gol,
        i2: gol2,

        s: `Golzmjyasindy`,
        tit: `Голубці з м'ясом індички`,
        ng: `Напівфабрикат`,
        sklad: `капуста свіжа, м'ясо індички, цибуля ріпчаста свіжа, свинина, рис відварний, морква свіжа, вода питна, сіль кухонна, суміш прянощів.`,
        sp: `голубці обсмажити з обох сторін, скласти в посудину, залити томатним соусом і тушкувати до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 351,
        i: golOvo,
        i2: golOvo2,

        s: `Golovoch`,
        tit: `Голубці овочеві`,
        ng: `Напівфабрикат`,
        sklad: `капуста свіжа, рис відварний, морква свіжа, цибуля ріпчаста свіжа, олія соняшникова, сіль кухонна, перець чорний мелений.`,
        sp: `голубці обсмажити з обох сторін, скласти в посудину, залити томатним соусом і тушкувати до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 1221,
        i: per,
        i2: per2,

        s: `PerSlo`,
        tit: `Перець "Слов'янський"`,
        ng: `Напівфабрикат`,
        sklad: `крупа рисова відварена, м'ясо птиці, перець болгарський, цибуля смажена, морква смажена, сіль кухонна, перець чорний мелений`,
        sp: `розморозити при кімнатній температурі, скласти у сотейник, додати підсолену воду, довести до кіпіння і тушкувати 25-30 хв. на невеликому вогні.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1231,
        i: perBil,
        i2: perBil2,

        s: `Perbilofarsh`,
        tit: `Перець білозірський фарширований`,
        ng: `Напівфабрикат`,
        sklad: `крупа рисова відварена, м'ясо птиці, перець білозірський, цибуля смажена, морква смажена, сіль кухонна, перець чорний мелений`,
        sp: `розморозити при кімнатній температурі, скласти у сотейник, додати підсолену воду, довести до кіпіння і тушкувати 25-30 хв. на невеликому вогні.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1241,
        i: per,
        i2: per2,

        s: `Perovoch`,
        tit: `Перець овочевий`,
        ng: `Напівфабрикат`,
        sklad: `крупа рисова відварена, перець болгарський, цибуля смажена, морква смажена, сіль кухонна, перець чорний мелений`,
        sp: `розморозити при кімнатній температурі, скласти у сотейник, додати підсолену воду, довести до кіпіння і тушкувати 25-30 хв. на невеликому вогні.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1251,
        i: per,
        i2: per2,

        s: `Perfarshzmjyas`,
        tit: `Перець фарширований з м'ясом`,
        ng: `Напівфабрикат`,
        sklad: `крупа рисова відварена, свинина, сало свинне, перець болгарський, цибуля смажена, морква смажена, сіль кухонна, перець чорний мелений`,
        sp: `розморозити при кімнатній температурі, скласти у сотейник, додати підсолену воду, довести до кіпіння і тушкувати 25-30 хв. на невеликому вогні.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 1861,
        i: fry,
        i2: fry2,

        s: `Tefmjyas`,
        tit: `Тефтелі м'ясні`,
        ng: `Напівфабрикат`,
        sklad: `свинина, рис відварений, цибуля ріпчаста свіжа, яйця курячі, борошно пшеничне в/г, сіль кухонна, перець чорний мелений.`,
        sp: `смажити тефтелі у невеликій кількості розігрітого жиру, потім покласти їх у каструлю, залити сметанним соусом і тушкувати до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1881,
        i: tis,
        i2: tis2,

        s: `Tislyst`,
        tit: `Тісто листкове`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, маргарин (жири рослинні та олії в натуральному та ствердлому вигляді рафіновані дезодоровані), вода питна, яйця курячі, оцет, сіль кухонна, цукор.`,
        sp: `розморозити при кімнатній температурі 30-40 хв. та розгорнути. Сформувати вироби (додаючи начинки), проколоти, зволожити та випікати при t 230°С 3-5 хв, а потім при t 180°С до набуття золотистого кольору.`,
        uz: `при температурі не вище мінус 18°С не більше 9 місяців від дати виготовлення.`,
      },
      {
        n: 1891,
        i: tis,
        i2: tis2,

        s: `Tislystpris`,
        tit: `Тісто листкове прісне`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, вода питна, маргарин для листкового тіста (рослинні жири та олії в натуральному та ствердлому стані, вода питна, емульгатори Е322, Е471, Е475, сіль кухонна, лимонна кислота, консервант Е202, ароматизатор «Масляний» барвник натуральний (бета-каротин), цукор, сіль кухонна, регулятор кислотності – лимонна кислота).`,
        sp: `розморозити при кімнатній температурі 30-40 хв. та розгорнути. Сформувати вироби (додаючи начинки), проколоти, зволожити та випікати при t 230°С 3-5 хв, а потім при t 180°С до набуття золотистого кольору.`,
        uz: `при температурі не вище мінус 18°С не більше 9 місяців від дати виготовлення.`,
      },
      {
        n: 1901,
        i: tis,
        i2: tis2,

        s: `Tislystd`,
        tit: `Тісто листково-дріжджове`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, маргарин (жири рослинні та олії в натуральному та ствердлому вигляді рафіновані дезодоровані), вода питна, яйця курячі, дріжджі пресовані, оцет, сіль кухонна, цукор.`,
        sp: `розморозити при кімнатній температурі 30-40 хв. та розгорнути. Сформувати вироби (додаючи начинки), проколоти, зволожити та випікати при t 230°С 3-5 хв, а потім при t 180°С до набуття золотистого кольору.`,
        uz: `при температурі не вище мінус 18°С не більше 9 місяців від дати виготовлення.`,
      },

      {
        n: 1921,
        i: fry,
        i2: fry2,

        s: `Fry`,
        tit: `Фрикадельки `,
        ng: `Напівфабрикат`,
        sklad: `яловичина, м'ясо птиці, цибуля ріпчаста свіжа, сало, сіль кухонна, перець чорний мелений.`,
        sp: `в суп з напівготовою картоплею кладуть фрикадельки і варять 10 хвилин до готовності. Зелень, приправи - за смаком.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 1931,
        i: fry,
        i2: fry2,

        s: `FryOso`,
        tit: `Фрикадельки "Особливі"`,
        ng: `Напівфабрикат`,
        sklad: `яловичина, м'ясо птиці, цибуля ріпчаста свіжа, сало, сіль кухонна, перець чорний мелений.`,
        sp: `в суп з напівготовою картоплею кладуть фрикадельки і варять 10 хвилин до готовності. Зелень, приправи - за смаком.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },

      {
        n: 1991,
        i: hin,
        i2: hin2,

        s: `KhinDlyaVsikh`,
        tit: `Хінкалі "Для Всіх"`,
        ng: `Напівфабрикат`,
        sklad: `борошно пшеничне в/г, фарш курячий, цибуля ріпчаста свіжа, сіль кухонна, олія соняшникова, яйця курячі, cуміш смакова "Хінкалі".`,
        sp: `варити у підсоленій воді протягом 6-7 хвилин.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },
      {
        n: 2001,
        i: hin,
        i2: hin2,

        s: `KhinTbi`,
        tit: `Хінкалі "Тбіліські"`,
        ng: `Напівфабрикат`,
        sklad: `тісто 60%: борошно пшеничне в/г, вода питна, олія соняшникова, сіль кухонна; фарш 40%: яловичина, цибуля ріпчаста свіжа, сало, сіль кухонна, суміш смакова.`,
        sp: `варити у підсоленій воді протягом 6-7 хвилин.`,
        uz: `при температурі не вище мінус 18°С не більше 6 місяців від дати виготовлення.`,
      },

      {
        n: 2131,
        i: cheKruNF,
        i2: cheKruNF2,

        s: `Chebzsyrokmtakrop`,
        tit: `Чебуреки з сиром та кропом`,
        ng: `Напівфабрикат`,
        sklad: `тісто: борошно пшеничне в/г, вода питна, маргарин (жири рослинні і олії в натуральному і ствердлому вигляді рафіновані, дезодоровані), олія соняшникова, сіль кухонна;  начинка: сир кисломолочний, кріп, сіль кухонна, перець чорний мелений.`,
        sp: `у невелику кількість розігрітого жиру (фритюр) покласти чебуреки і смажити до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 2141,
        i: cheKruNF,
        i2: cheKruNF2,

        s: `Chebzsyrotver`,
        tit: `Чебуреки з сиром твердим`,
        ng: `Напівфабрикат`,
        sklad: `тісто 60% - борошно пшеничне в/г, вода питна, олія соняшникова рафінована, сіль кухонна; начинка 40% - сир твердий 39,8%, кріп сушений, перець чорний мелений.`,
        sp: `у невелику кількість розігрітого жиру (фритюр) покласти чебуреки і смажити до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 2151,
        i: cheKruNF,
        i2: cheKruNF2,

        s: `Chebzyalov`,
        tit: `Чебуреки з яловичиною`,
        ng: `Напівфабрикат`,
        sklad: `тісто 60% - борошно пшеничне в/г, вода питна, олія соняшникова рафінована, сіль кухонна; начинка 40% - м'ясо (яловичина) (35%), цибуля ріпчаста свіжа, сіль кухонна, перець чорний мелений.`,
        sp: `у невелику кількість розігрітого жиру (фритюр) покласти чебуреки і смажити до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 2161,
        i: cheKruNF,
        i2: cheKruNF2,

        s: `Chebzisvyn`,
        tit: `Чебуреки зі свининою`,
        ng: `Напівфабрикат`,
        sklad: `тісто 60% - борошно пшеничне в/г, вода питна, олія соняшникова рафінована, сіль кухонна; начинка 40% - м'ясо свинини (35%), цибуля ріпчаста свіжа, сіль кухонна, перець чорний мелений.`,
        sp: `у невелику кількість розігрітого жиру (фритюр) покласти чебуреки і смажити до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
      {
        n: 2171,
        i: cheKruNF,
        i2: cheKruNF2,

        s: `Chebzisvyntasyrotver`,
        tit: `Чебуреки зі свининою та сиром твердим`,
        ng: `Напівфабрикат`,
        sklad: `тісто 60% - борошно пшеничне в/г, вода питна, олія соняшникова рафінована, сіль кухонна; начинка 40% - м'ясо свинини (20%), сир твердий (18%), цибуля ріпчаста свіжа, сіль кухонна, перець чорний мелений.`,
        sp: `у невелику кількість розігрітого жиру (фритюр) покласти чебуреки і смажити до готовності.`,
        uz: `при температурі не вище мінус 18°С не більше 180 діб від дати виготовлення.`,
      },
    ],
  },
];
export default foods;
