import { React, useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
// import "../App.css";
import foods from '../data/foods'
import Slogan from './Slogan'
// import ScrollToTop from './ScrollToTop'

const botAnimation = {
  hidden: {
    y: -50,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.02 },
  }),
}

// const Search = () => {
//   const [search, setSearch] = useState("");

//   const [inputReadOnly, setInputReadOnly] = useState(false);

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       setInputReadOnly(true);
//     }
//   };

//   const handleInputClick = () => {
//     setInputReadOnly(false);
//   };

//   return (
//     <section>
//       <section className="containerMenuEnd">
//         <motion.div
//           className="searchBox"
//           initial="hidden"
//           whileInView="visible"
//           viewport={{ amount: 0.1, once: true }}
//           variants={botAnimation}
//           custom={3}
//         >
//           <input
//             className="inputBox"
//             autoFocus={inputReadOnly ? undefined : "1"}
//             spellCheck="false"
//             maxLength="30"
//             placeholder="Пошук..."
//             type="text"
//             name="search"
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//             onKeyPress={handleKeyPress}
//             readOnly={inputReadOnly}
//             onClick={handleInputClick}
//           />
//         </motion.div>

//         {foods.map((data) =>
//           data.prod
//             .filter((prod) =>
//               prod.tit.toLowerCase().includes(search.toLowerCase())
//             )
//             .map((prod, index) => (
//               <Link key={prod.n} to={`../${prod.s}`}>
//                 <motion.div
//                   className="blockSearchMenu"
//                   initial="hidden"
//                   whileInView="visible"
//                   viewport={{ amount: 0.2, once: true }}
//                   variants={botAnimation}
//                   custom={index + 1}
//                 >
//                   <img className="imgSearchFood" src={prod.i2} alt={prod.tit} />
//                   <div className="rowMenuFood">{prod.tit}</div>
//                 </motion.div>
//               </Link>
//             ))
//         )}
//       </section>
//       <Slogan />
//     </section>
//   );
// };

const Search = () => {
  const [search, setSearch] = useState('')

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }) // Smooth scroll to top
  }

  // Add event listener on component mount and cleanup on unmount
  useEffect(() => {
    const handleInputChange = () => handleScrollToTop()
    window.addEventListener('input', handleInputChange)

    return () => window.removeEventListener('input', handleInputChange) // Cleanup
  }, [])

  return (
    <section>
      <input
        className="inputBox"
        // autoFocus={inputReadOnly ? undefined : "1"}
        autoFocus="1"
        spellCheck="false"
        maxLength="30"
        placeholder="Пошук..."
        type="text"
        name="search"
        value={search}
        // onChange={(e) => setSearch(e.target.value)}
        onChange={(e) => {
          setSearch(e.target.value)
          handleScrollToTop() // Call ScrollToTop on change
        }}
      />

      <section className="containerMenuEnd">
        {foods.map((data) =>
          data.prod
            .filter((prod) =>
              prod.tit.toLowerCase().includes(search.toLowerCase())
            )
            .map((prod, index) => (
              <Link key={prod.n} to={`../${prod.s}`}>
                <motion.div
                  className="blockSearchMenu lineDown"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ amount: 0.2, once: true }}
                  variants={botAnimation}
                  custom={index + 1}
                  draggable="false"
                >
                  <img className="imgSearchFood" src={prod.i2} alt={prod.tit} />
                  <div className="rowMenuFood">{prod.tit}</div>
                </motion.div>
              </Link>
            ))
        )}
      </section>
      <footer>
        <Slogan />
      </footer>
    </section>
  )
}

export default Search
