import React from "react";
import { motion } from "framer-motion";
import { Outlet } from "react-router-dom";
import Menu from "../components/Menu";
import ScrollToTop from "../components/ScrollToTop";

const MainLayout = () => {
  return (
    <motion.section>
      <Menu />
      <div id="#top" className="top" />
      <Outlet />
      <ScrollToTop />
    </motion.section>
  );
};

export default MainLayout;
