const slogan = (
  <svg
    alt="Слоган Дригало"
    width="100%"
    className="animationRotate"
    x="0px"
    y="0px"
    viewBox="0 0 239 54.4"
  >
    <style type="text/css"></style>

    <path
      className="st1"
      d="M31.4,10.2c-2.1,0-4,0.6-5.2,1.7c-1.2,1.2-1.5,3.2,0.1,4.3c0.5,0.2,1.2-0.3,1.2-0.8c-1.2-2.2,1.1-4,3.8-4
		c1.9,0,2.9,0.9,2.6,2.2s-1.7,1.9-3.3,1.9c0.3-1,0.6-2.3,1.3-2.7c0.1-0.3-0.6-0.7-1.1-0.5c-1.8,0.7-2.1,4.3-3,6.9
		c-1.8-1.1-4.2-0.7-4.3,1.3c-0.1,1.3,1.2,1.9,2.6,1.8c1.1,0,1.9-0.4,2.5-1.2c1,0.7,2,1.2,3.3,1.2c1.9,0,3.4-1,3.7-2.9
		c0.3-1.6-0.8-3.2-2.4-3.4c1.2-0.1,2.5-0.9,2.8-2.3C36.2,11.7,34.1,10.2,31.4,10.2z M26,21.1c-1.5,0.2-1.3-1.6,0.1-1.4
		c0.4,0.1,0.8,0.3,1.1,0.5C26.9,20.8,26.4,21.1,26,21.1z M33.6,19.1c-0.4,2.5-2.9,2.5-4.6,1.1c0.5-1.1,0.8-2.5,1.2-3.6
		c0.7-0.1,1.2,0,1.7,0.1C33,16.9,33.8,17.7,33.6,19.1z"
    />
    <path
      className="st1"
      d="M39.1,13.9c0.7,0,1.2-0.4,1.2-1.1c0.1-0.6-0.3-1-0.9-1c-0.6,0-1.1,0.4-1.2,1C38.2,13.4,38.6,13.9,39.1,13.9z"
    />
    <path
      className="st1"
      d="M66.7,19.4C66.8,19.4,66.8,19.3,66.7,19.4c0.8-1.5,1.3-3.3,1.4-4.3c0.3,0.4,0.9,0.8,1.4,1
		c-1,1.4-1.8,2.9-1.8,4.2c-0.1,1.2,0.8,2,2.1,2c1.2,0,2.3-0.6,3.2-1.8c-0.1,1,0.4,1.8,1.5,1.8c1.2,0,2.2-0.5,3.2-2.2
		c-0.3,1,0.1,2.1,1.1,2.1s1.8-0.7,2.4-1.5c-0.1,0.4-0.2,0.7-0.3,1.1c-0.2,0.6,1.8,0.6,1.9,0.2c0.2-0.9,0.4-1.7,0.7-2.8H86
		c-0.4,1.9-0.5,3.2,1.2,3.1c1.2,0,2.1-1.1,2.8-2.2c0,0.1,0,0.1,0,0.2c0.1,1.2,0.7,2,2.2,2c1.2,0,2.1-0.4,2.8-1.1
		c0.7,0.1,1.5-0.1,2.2-0.7c-0.1,0.4-0.2,0.9-0.4,1.3c-0.1,0.6,1.8,0.6,1.9,0.2c0.3-1.3,0.6-2.5,1-3.8c0.6-1,1.4-1.9,2-1.9
		c0.5,0,0.5,0.6,0.3,1c-0.4,1.8-0.8,3.2-1.1,4.7c0.1,0.5,1.7,0.4,1.9,0.1c0.3-1,0.6-2.7,0.9-3.8c0.4-0.9,1.4-2,2-2s0.4,0.6,0.3,1.1
		c-0.2,0.9-0.6,2.4-0.8,3.1c-0.2,1.2,0.2,2,1.5,1.9c1.2,0,2.2-1.2,3-2.4c0.2,1.3,1.2,2.3,3.2,2.4c1.6,0.1,3.7-0.5,5.1-3.6
		c0.1-0.2-0.5-0.6-0.6-0.3c-1,2.3-2.8,2.9-4,2.8s-1.8-0.8-1.7-1.9c2,0.5,3.6-0.1,4.3-1.4c0.7-1.3,0.1-2.8-1.8-2.9
		c-2-0.1-3.8,1.3-4.3,3.5c-0.6,1.4-1.7,2.7-2.3,2.7c-0.9,0,0.3-2.8,0.6-4c0.3-1.4,0-2.3-1.3-2.3c-1.1,0-2.1,0.9-2.7,1.7
		c0.2-1-0.3-1.7-1.2-1.7c-1,0-2.1,1-2.7,1.8l0.4-1.4c-0.1-0.5-1.5-0.6-1.8-0.3c-0.3,1.2-0.6,2.3-0.9,3.3l0,0
		c-0.5,1.2-1.3,2.1-2.2,2.1c0.7-1.6,0.3-3.5-1.2-3.6c-0.9-0.1-1.8,0.5-2.2,1.1c0.2-0.7,0.4-1.6,0.8-2.7c0.1-0.4-1.6-0.6-1.8-0.1
		c-0.4,1.2-0.7,2.3-0.9,3.3c-0.6,1.5-1.7,2.7-2.3,2.7c-0.4,0-0.3-0.8-0.2-1.4c0.3-1.3,0.7-2.5,1.2-4.4c0.1-0.5-1.8-0.6-1.9-0.1
		c-0.2,0.7-0.4,1.8-0.7,2.8h-2.4c0.2-0.8,0.4-1.7,0.7-2.7c0.1-0.5-1.8-0.6-1.9-0.1c-0.3,1.2-0.6,2.2-0.8,3.2
		c-0.6,1.4-1.6,2.7-2.3,2.7c-0.9,0,0.5-4.1,0.9-5.7c-0.1-0.5-1.6-0.6-1.8-0.2c-0.3,1.2-0.7,2.4-1,3.5c-0.5,1.5-1.6,2.5-2.3,2.5
		c-0.6,0-0.5-0.9-0.2-1.9c0.3-1.4,0.8-2.9,1-3.9c0-0.5-1.6-0.6-1.8-0.2c-0.3,1-0.6,2.1-0.9,3.3c-0.9,2-2.1,2.7-2.9,2.7
		c-0.6,0-0.9-0.4-0.8-1.1c0-0.9,0.5-2,1.4-3.2c0.9-1.1,0.3-1.8-0.6-1.6c-0.6-0.1-1.3-0.5-1.7-1.1c0.3-2.2-2.2-1.3-1.2,0.3
		c-0.2,1.5-0.7,3-1.3,4.2c-0.7,1.4-1.6,1.8-2.4,1.7c1.5-2.4,1.4-5.7-1.7-5.6c-1.9,0-3.7,1.4-4.3,3.6c-0.6,1.4-1.6,2.7-2.3,2.7
		c-0.4,0-0.3-0.7-0.2-1.3c0.2-0.8,0.6-1.8,0.7-2.6c0.3-1.4,0-2.4-1.3-2.4c-1.1,0-2.3,1.1-2.9,1.8c0.2-0.5,0.2-0.8,0.4-1.3
		c-0.2-0.5-1.5-0.6-1.8-0.2c-0.3,1.2-0.6,2.3-0.9,3.4c-0.9,1.9-1.9,3.1-3.2,3.6c0.5-1.9,0.8-3.2,1.3-4.8c0-0.3-1.4-0.7-1.6-0.3
		c-0.2,0.7-0.5,1.5-0.8,2c-0.8,1.7-1.9,2.5-2.4,2c-0.4-0.4-0.3-1.6,0.2-2.7c0.6-1.2,1.6-2.1,2.6-2.2c0.8-0.1,1.7,0.2,2.3,0.7
		c0.3,0.2,1-0.6,0.8-0.9c-1-0.9-2.5-1.1-3.7-1c-1.2,0.2-2.6,0.9-3.5,2.3c-0.3,0.4-0.5,0.9-0.7,1.3c-0.6,1.4-1.7,2.6-2.5,2.6
		c-0.4,0-0.3-0.4-0.2-1.1c0.3-1.2,0.6-2.3,1.3-4.7c-0.1-0.4-1.5-0.6-1.8-0.2c-0.6,2.1-1,3.5-1.4,5.1c-0.3,1.3,0,2.2,1.4,2.2
		c1.2,0,2.1-0.9,2.8-1.9c0.1,1.1,0.7,1.9,1.9,1.9c1.3,0,2.2-1.1,2.7-2.1c-0.3,0.9-0.6,1.8-0.8,2.4c-1.7,0.6-3.5,1.2-3.8,2.9
		c-0.1,1.1,0.7,1.8,1.8,1.7c1.9,0,2.7-1.2,3.5-4.1c1.2-0.5,2-1.2,2.8-2.2c-0.1,0.3-0.1,0.6-0.2,0.9c0,0.6,1.7,0.5,1.9,0.1
		c0.4-1.6,0.6-2.4,1-4c0.6-0.9,1.6-1.7,2.2-1.7c0.6,0,0.5,0.6,0.4,1.1c-0.2,0.7-0.6,2.6-0.7,3.2c-0.2,1.1,0.2,1.9,1.5,1.8
		c1.2,0,2.1-1,2.8-2.1c0.2,1.5,1.3,2.1,2.6,2.1c1.1,0,2.1-0.4,2.9-1.2C64.4,21.4,65.7,21.2,66.7,19.4z M113.9,16.1
		c0.7,0,0.7,0.6,0.4,1.2c-0.3,0.8-1.2,1.2-2.5,1C112.1,17.1,113.1,16.1,113.9,16.1z M91.7,19.7c0.3-1.2,1.3-1.8,2-1.7
		c0.7,0.1,0.7,0.9,0.5,1.5c-0.5,0-0.9,0.6-0.6,1.1c-0.3,0.4-0.6,0.6-1.2,0.6C91.8,21.1,91.6,20.5,91.7,19.7z M44,24.8
		c-0.6,1.7-2,1.5-1.5,0.4c0.2-0.5,1-1.1,1.8-1.4C44.2,24.1,44.1,24.5,44,24.8z M62.4,19.5c-0.5-0.1-0.9,0.5-0.6,1
		c-0.3,0.4-0.7,0.6-1.2,0.6c-1.1,0-1-1.2-0.8-2.3c0.3-1.3,1.1-2.7,2.2-2.8C63.2,16,63.1,17.9,62.4,19.5z"
    />
    <path
      className="st1"
      d="M123.9,22.3c1.3,0.1,2.1-0.4,2.9-1.1c1.4,0.3,2.7,0.1,3.9-2.4c0.1-0.2-0.5-0.5-0.6-0.4
		c-0.8,1.8-1.7,2.2-2.7,2.1c1.2-1.8,1.4-4.9-0.7-5.1c2.6-1.4,2.8-5.2-0.4-5.1c-1.1,0-2.1,0.6-2.8,1.8c-1.4,2.1-1.9,5.2-2,6.3
		C121.2,20.9,121.9,22.2,123.9,22.3z M126.4,11.7c1.3,0,0.8,1.9-0.4,2.9c-0.5,0.5-1.4,0.9-2.2,1.2C124.3,13.5,125.2,11.7,126.4,11.7
		z M123.5,17.4c0.5-0.4,1.4-0.9,2.2-0.9c1,0,1.2,1.5,0.4,3.1c-0.5-0.1-1,0.5-0.6,1c-0.3,0.4-0.7,0.6-1.2,0.6
		C122.9,21.1,123,19.3,123.5,17.4z"
    />
    <path
      className="st1"
      d="M169.4,22.4c1.2,0,2.2-0.5,3.2-2.2c-0.3,1,0.1,2.1,1.1,2.1c1.1,0,1.9-0.8,2.6-1.8c-0.1,1,0.3,1.8,1.5,1.8
		s2.2-0.5,3.2-2.2c-0.3,1,0.1,2.1,1.1,2.1c1.8,0,3.1-2.4,3.7-3.5c0.1-0.2-0.5-0.6-0.6-0.3c-0.6,1.4-1.7,2.7-2.3,2.7
		c-0.9,0,0.5-4.1,0.9-5.7c-0.1-0.5-1.6-0.6-1.8-0.2c-0.3,1.2-0.7,2.4-1,3.5c-0.5,1.5-1.6,2.5-2.3,2.5c-0.6,0-0.5-0.9-0.2-1.9
		c0.3-1.4,0.8-2.9,1-3.9c0-0.5-1.6-0.6-1.8-0.2c-0.3,1-0.6,2.1-0.9,3.3c-0.6,1.4-1.6,2.7-2.3,2.7c-0.9,0,0.5-4.1,0.9-5.7
		c-0.1-0.5-1.6-0.6-1.8-0.2c-0.3,1.2-0.7,2.4-1,3.5c-0.5,1.5-1.6,2.5-2.3,2.5c-0.6,0-0.5-0.9-0.2-1.9c0.3-1.4,0.8-2.9,1-3.9
		c0-0.5-1.6-0.6-1.8-0.2c-0.3,1-0.6,2.1-0.9,3.3c-0.7,1.6-1.5,2.2-2.6,2c1.1-1.6,1.8-5.5-0.8-5.6c-1-0.1-2,0.5-2.7,1.2
		c0.1-0.2,0.2-0.5,0.2-0.7c-0.1-0.6-1.7-0.6-1.8-0.3c-0.3,1.2-0.6,2.3-0.9,3.4c-0.7,1.6-1.7,2.2-2.6,2c1.3-2,1.4-5.3-1-5.3
		c-0.8,0-1.9,0.5-2.7,1.4c0.3-1.4,0.8-2.4,1.8-3.1c1.1-0.7,2.3-0.7,3-1.7c0.4-0.6-0.3-1.7-0.5-1.4c-1.3,1.2-2.2,1.3-3.5,2.2
		c-2,1.4-2.7,3.5-3,6l0,0c-0.7,1.7-1.7,2.1-2.5,2c1.5-2.4,1.4-5.7-1.7-5.6c-1.9,0-3.7,1.4-4.2,3.6c-0.9,1.9-1.9,3.1-3.2,3.6
		c0.5-1.9,0.8-3.2,1.3-4.8c0-0.3-1.4-0.7-1.6-0.3c-0.2,0.7-0.5,1.5-0.8,2c-0.8,1.7-1.9,2.5-2.4,2c-0.4-0.4-0.3-1.6,0.2-2.7
		c0.6-1.2,1.6-2.1,2.6-2.2c0.8-0.1,1.7,0.2,2.3,0.7c0.3,0.2,1-0.6,0.8-0.9c-1-0.9-2.5-1.1-3.7-1c-1.2,0.2-2.6,0.9-3.5,2.3
		c-1.6,2.2-1.5,5.3,0.9,5.2c1.3,0,2.2-1.1,2.7-2.1c-0.3,0.9-0.6,1.8-0.8,2.4c-1.7,0.6-3.5,1.2-3.8,2.9c-0.1,1.1,0.7,1.8,1.8,1.7
		c1.9,0,2.7-1.2,3.5-4.1c1.4-0.6,2.4-1.5,3.3-2.9c0.2,1.5,1.3,2.1,2.6,2.1c1.1,0,2.1-0.4,2.9-1.2c1,0.2,2.2,0.1,3.2-1.3
		c0.1,1.5,0.9,2.4,2.4,2.4c1.2,0.1,2.2-0.4,3-1.2c0.8,0.2,1.8,0.2,2.7-0.7c-0.5,2.2-1.1,4.3-1.6,6.4c0,0.4,1.6,0.5,1.8,0.1
		c0.4-1.8,0.9-3.6,1.3-5.4c1,1,3,1,4.4-0.5c0.9,0.2,1.9,0.1,2.8-0.8C167.7,21.4,168.1,22.3,169.4,22.4z M137.1,24.8
		c-0.6,1.7-2,1.5-1.5,0.4c0.2-0.5,1-1.1,1.8-1.4C137.3,24.1,137.2,24.5,137.1,24.8z M147.1,19.5c-0.5-0.1-0.9,0.5-0.6,1
		c-0.3,0.4-0.7,0.6-1.2,0.6c-1.1,0-1-1.2-0.8-2.3c0.3-1.3,1.1-2.7,2.2-2.8C148,16,147.8,17.9,147.1,19.5z M155.6,19.5
		c-0.4-0.1-0.9,0.5-0.6,1c-0.3,0.3-0.7,0.6-1.2,0.6c-1.1,0-1-1.5-0.8-2.8c0.5-1.3,1.5-1.9,2.2-1.9C156.3,16.4,156.3,17.7,155.6,19.5
		z M164.1,19.6c-0.4-0.2-1,0.6-0.6,1c-0.7,0.7-1.9,0.7-2.5-0.1c0.3-1.1,0.6-2.1,0.9-3.2c0.6-0.6,1.4-1.1,2-1.1
		C165,16.3,164.8,18.1,164.1,19.6z"
    />
    <path
      className="st1"
      d="M183.1,12.8c0.2-0.3-0.3-0.9-0.5-0.7c-0.6,0.6-1.9,1-2.4,0.1c-0.2-0.3-1,0.2-0.9,0.7
		C179.6,14.4,182,14.2,183.1,12.8z"
    />
    <path
      className="st1"
      d="M212.1,18.4c-0.9,2-2.3,2.8-3.7,2.7c-1.1,0-1.8-0.8-1.7-2c0.2-1.7,1.3-3.1,2.2-3c0.7,0.1,0.3,1-0.1,1.5
		c0,0.4,1.3,0.5,1.7,0.1c0.9-1,0.8-2.8-1.5-2.8c-2.2,0-3.9,1.7-4.3,3.7c-0.6,1.4-1.7,2.5-2.4,2.5c-0.4,0-0.5-0.4-0.3-1.3
		c0.2-0.8,0.5-1.9,0.7-2.5c-0.1-0.4-1.3-0.7-1.7-0.3c-0.6,2.2-2.3,4.8-3.2,4c-0.4-0.4-0.3-1.6,0.2-2.7s1.5-2,2.5-2.2
		c0.8-0.1,1.8,0.1,2.5,0.7c0.3,0.1,1-0.7,0.8-0.9c-1-1-2.6-1.2-3.8-1c-1.7,0.3-3.4,1.8-4.1,3.7c-0.9,1.9-2.1,2.5-2.9,2.5
		c-0.6,0-0.9-0.4-0.8-1.1c0-0.9,0.5-2,1.4-3.2c0.9-1.1,0.3-1.8-0.6-1.6c-0.6-0.1-1.3-0.5-1.7-1.1c0.3-2.2-2.2-1.3-1.2,0.3
		c-0.3,1.6-0.7,3.2-1.4,4.5c-0.1,0.2,0.5,0.5,0.6,0.3c0.8-1.4,1.2-3.3,1.4-4.2c0.3,0.4,0.9,0.8,1.4,1c-1,1.4-1.8,2.9-1.8,4.2
		c-0.1,1.2,0.8,2,2.1,2s2.3-0.6,3.3-1.9c0.1,1.3,0.9,1.9,1.9,1.9c1.4,0,2.2-1.1,2.8-2.2c-0.2,1.3,0.3,2.1,1.1,2.1
		c1.3,0,2.3-1,3.2-2.3c0.3,1.3,1.3,2.3,2.8,2.4c1.8,0.1,3.8-0.6,5.2-3.6C212.8,18.5,212.2,18.2,212.1,18.4z"
    />
    <path
      className="st1"
      d="M214.4,20.4c-0.5,0-1,0.4-1,0.9c0,0.4,0.2,0.7,0.6,0.9c-0.2,0.7-0.7,1.3-1.1,1.7c-0.3,0.3,0.3,0.8,0.7,0.6
		c1-0.7,1.7-1.9,1.7-3C215.3,20.8,214.9,20.4,214.4,20.4z"
    />
    <path
      className="st1"
      d="M60.3,36.1c-0.6,1.4-1.7,2.7-2.3,2.7c-0.9,0,0.5-4.1,0.9-5.7c-0.1-0.5-1.6-0.6-1.8-0.2c-0.3,1.2-0.7,2.4-1,3.5
		c-0.5,1.5-1.6,2.5-2.3,2.5c-0.6,0-0.5-0.9-0.2-1.9c0.3-1.4,0.8-2.9,1-3.9c0-0.5-1.6-0.6-1.8-0.2c-0.3,1-0.6,2.2-0.9,3.3
		c-1,2.2-1.9,2.7-2.4,2.7c-0.9,0.1-0.5-2.4,0.1-5.8c0.1-0.5-1.8-0.6-2-0.1c-0.8,1.9-2.1,4.6-2.6,5.1c-0.3-0.6-0.1-3.4,0.3-5
		c0.1-0.5-1.6-0.5-1.9-0.1c-1.3,2.4-2.1,3.8-3,6.6c-0.2,0.6,1.6,0.6,1.7,0.1c0.3-1.1,0.9-3,1.7-4.4c-0.3,2.2-0.1,3.5,0.2,4.1
		s1,0.5,1.5,0.1c0.6-0.6,1.4-1.8,2.4-4.2c-0.5,2.9-0.5,4.8,1,4.8c0.9,0,1.8-0.5,2.6-1.7c0,1,0.4,1.7,1.5,1.7c1.2,0,2.2-0.5,3.2-2.2
		c-0.3,1,0.1,2.1,1.1,2.1c1.8,0,3.1-2.4,3.7-3.5C61,36.2,60.4,35.8,60.3,36.1z"
    />
    <path
      className="st1"
      d="M132.7,36.1c-0.7,1.7-1.7,2.2-2.6,2.1c1.5-2.4,1.4-5.7-1.7-5.6c-1.9,0-3.7,1.4-4.2,3.6c-1,2.2-1.9,2.7-2.4,2.7
		c-0.9,0.1-0.5-2.4,0.1-5.8c0.1-0.5-1.8-0.6-2-0.1c-0.8,1.9-2.1,4.6-2.6,5.1c-0.3-0.6-0.1-3.4,0.3-5c0.1-0.5-1.6-0.5-1.9-0.1
		c-0.7,1.2-1.2,2.1-1.7,3.1l0,0c-0.8,2-2.3,2.7-3.5,2.7c-1,0-1.6-0.6-1.7-1.6c0.7,0.1,1.6,0,2.2-0.1c0.3-0.1,0.3-1.1,0-1.1
		c-0.5,0.1-1.3,0.2-2.1,0.1c0.4-1.3,1.4-2.4,2.2-2.3c0.6,0.1,0.7,0.5,0.5,0.9s1,0.7,1.3,0.2c0.6-1,0.2-2.3-1.7-2.3
		c-2.2,0-3.8,1.7-4.2,3.6c-0.6,1.5-1.8,2.6-2.4,2.6c-0.4,0-0.5-0.4-0.3-1.3c0.2-0.8,0.5-1.9,0.7-2.5c-0.1-0.4-1.3-0.7-1.7-0.3
		c-0.6,2.2-2.3,4.8-3.2,4c-0.4-0.4-0.3-1.6,0.2-2.7c0.5-1.1,1.5-2,2.5-2.2c0.8-0.1,1.8,0.1,2.5,0.7c0.3,0.1,1-0.7,0.8-0.9
		c-1-1-2.6-1.2-3.8-1c-1.7,0.3-3.5,1.9-4.1,3.8c-0.7,1.4-1.6,1.9-2.5,1.7c1.3-2,1.4-5.3-1-5.3c-0.8,0-1.9,0.5-2.7,1.4
		c0.3-1.4,0.8-2.4,1.8-3.1c1.1-0.7,2.3-0.7,3-1.7c0.4-0.6-0.3-1.7-0.5-1.4c-1.3,1.2-2.2,1.3-3.5,2.2c-2,1.4-2.7,3.5-3,6
		c0,0,0,0,0,0.1c-0.9,1.9-1.9,3.1-3.2,3.6c0.5-1.9,0.8-3.2,1.3-4.8c0-0.3-1.4-0.7-1.6-0.3c-0.2,0.7-0.5,1.5-0.8,2
		c-0.8,1.7-1.9,2.5-2.4,2c-0.4-0.4-0.3-1.6,0.2-2.7c0.6-1.2,1.6-2.1,2.6-2.2c0.8-0.1,1.7,0.2,2.3,0.7c0.3,0.2,1-0.6,0.8-0.9
		c-1-0.9-2.5-1.1-3.7-1c-1.2,0.2-2.6,0.9-3.5,2.3c-0.3,0.4-0.5,0.9-0.7,1.3c-0.7,1.6-1.7,2.1-2.5,2c1.5-2.4,1.4-5.7-1.7-5.6
		c-1.9,0-3.7,1.4-4.3,3.6C72,37.7,71,39,70.3,39c-0.4,0-0.3-0.7-0.2-1.3c0.2-0.8,0.6-1.8,0.7-2.6c0.3-1.4,0-2.4-1.3-2.4
		c-1.1,0-2.3,1.1-2.9,1.8c0.2-0.5,0.2-0.8,0.4-1.3c-0.2-0.5-1.5-0.6-1.8-0.2c-0.7,2.5-1.3,4.5-1.8,6.8c0,0.6,1.7,0.5,1.9,0.1
		c0.4-1.6,0.6-2.4,1-4c0.6-0.9,1.6-1.7,2.2-1.7s0.5,0.6,0.4,1.1c-0.2,0.7-0.6,2.6-0.7,3.2c-0.2,1.1,0.2,1.9,1.5,1.8
		c1.2,0,2.1-1,2.8-2.1c0.2,1.5,1.3,2.1,2.6,2.1c1.1,0,2.1-0.4,2.9-1.2c0.9,0.2,1.9,0.1,2.8-0.9c0,1.2,0.6,2.1,2,2.1
		c1.3,0,2.2-1.1,2.7-2.1c-0.3,0.9-0.6,1.8-0.8,2.4c-1.7,0.6-3.5,1.2-3.8,2.9c-0.1,1.1,0.7,1.8,1.8,1.7c1.9,0,2.7-1.2,3.5-4.1
		c1.5-0.7,2.5-1.6,3.4-3.2c0.2,1.4,0.9,2.2,2.4,2.3c1.2,0.1,2.2-0.4,3-1.2c0.9,0,2,0,2.9-1c0,1.4,0.9,2,2,2c1.4,0,2.2-1.1,2.8-2.2
		c-0.2,1.3,0.3,2.1,1.1,2.1c1.2,0,2.3-1,3.1-2.2c0.2,1.3,1.2,2.3,2.7,2.3c1,0,2.1-0.2,3.2-1.1c-0.1,0.2-0.1,0.4-0.2,0.6
		c-0.2,0.6,1.6,0.6,1.7,0.1c0.3-1.1,0.9-3,1.7-4.4c-0.3,2.2-0.1,3.5,0.2,4.1c0.3,0.6,1,0.5,1.5,0.1c0.6-0.6,1.4-1.8,2.4-4.2
		c-0.5,2.9-0.5,4.8,1,4.8c1,0,1.9-0.6,2.9-2.1c0.2,1.5,1.3,2.1,2.6,2.1c1.1,0,2.1-0.4,2.9-1.2c1.3,0.3,2.7,0,3.8-2.4
		C133.4,36.2,132.8,35.9,132.7,36.1z M77.2,37.2c-0.5-0.1-0.9,0.5-0.6,1c-0.3,0.4-0.7,0.6-1.2,0.6c-1.1,0-1-1.2-0.8-2.3
		c0.3-1.3,1.1-2.7,2.2-2.8C78,33.7,77.8,35.6,77.2,37.2z M84.2,42.5c-0.6,1.7-2,1.5-1.5,0.4c0.2-0.5,1-1.1,1.8-1.4
		C84.4,41.8,84.3,42.1,84.2,42.5z M94.2,37.2c-0.4-0.1-0.9,0.5-0.6,1c-0.3,0.3-0.7,0.6-1.2,0.6c-1.1,0-1-1.5-0.8-2.8
		c0.5-1.3,1.5-1.9,2.2-1.9C95,34,95,35.4,94.2,37.2z M128.6,37.2c-0.5-0.1-0.9,0.5-0.6,1c-0.3,0.4-0.7,0.6-1.2,0.6
		c-1.1,0-1-1.2-0.8-2.3c0.3-1.3,1.1-2.7,2.2-2.8C129.5,33.7,129.3,35.6,128.6,37.2z"
    />
    <path
      className="st1"
      d="M162.1,36.1c-0.7,1.7-1.7,2.2-2.6,2.1c1.5-2.4,1.4-5.7-1.7-5.6c-1.9,0-3.6,1.4-4.2,3.5
		c-0.7,1.6-1.5,2.2-2.7,2.1c1.1-1.6,1.8-5.5-0.8-5.6c-1-0.1-2,0.5-2.7,1.2c0.1-0.2,0.2-0.5,0.2-0.7c-0.1-0.6-1.7-0.6-1.8-0.3
		c-0.3,1.2-0.6,2.3-0.9,3.4c-0.6,1.4-1.6,2.7-2.3,2.7c-0.4,0-0.3-0.7-0.2-1.3c0.2-0.8,0.6-1.8,0.7-2.6c0.3-1.4,0-2.4-1.3-2.4
		c-1.1,0-2.3,1.1-2.9,1.8c0.2-0.5,0.2-0.8,0.4-1.3c-0.2-0.5-1.5-0.6-1.8-0.2c-0.7,2.5-1.3,4.5-1.8,6.8c0,0.6,1.7,0.5,1.9,0.1
		c0.4-1.6,0.6-2.4,1-4c0.6-0.9,1.6-1.7,2.2-1.7c0.6,0,0.5,0.6,0.4,1.1c-0.2,0.7-0.6,2.6-0.7,3.2c-0.2,1.1,0.2,1.9,1.5,1.8
		c0.9,0,1.7-0.7,2.4-1.5c-0.5,2-1,4-1.5,6.1c0,0.4,1.6,0.5,1.8,0.1c0.4-1.8,0.9-3.6,1.3-5.4c1,1,3,1,4.4-0.5c1,0.3,2.1,0.1,3-1
		c0.1,1.6,1.2,2.3,2.6,2.3c1.1,0,2.1-0.4,2.9-1.2c1.3,0.3,2.7,0,3.8-2.4C162.9,36.2,162.2,35.9,162.1,36.1z M149.5,37.2
		c-0.4-0.2-1,0.6-0.6,1c-0.7,0.7-1.9,0.7-2.5-0.1c0.3-1.1,0.6-2.1,0.9-3.2c0.6-0.6,1.4-1.1,2-1.1C150.4,33.9,150.2,35.8,149.5,37.2z
		 M158.1,37.2c-0.5-0.1-0.9,0.5-0.6,1c-0.3,0.4-0.7,0.6-1.2,0.6c-1.1,0-1-1.2-0.8-2.3c0.3-1.3,1.1-2.7,2.2-2.8
		C158.9,33.7,158.8,35.6,158.1,37.2z"
    />
    <path
      className="st1"
      d="M190.7,36.1c-0.9,2-2.3,2.8-3.7,2.7c-1.1,0-1.8-0.8-1.7-2c0.2-1.7,1.3-3.1,2.2-3c0.7,0.1,0.3,1-0.1,1.5
		c0,0.4,1.3,0.5,1.7,0.1c0.9-1,0.8-2.8-1.5-2.8c-2.2,0-3.9,1.7-4.3,3.7c-0.6,1.4-1.7,2.5-2.4,2.5c-0.4,0-0.5-0.4-0.3-1.3
		c0.2-0.8,0.5-1.9,0.7-2.5c-0.1-0.4-1.3-0.7-1.7-0.3c-0.6,2.2-2.3,4.8-3.2,4c-0.4-0.4-0.3-1.6,0.2-2.7c0.5-1.1,1.5-2,2.5-2.2
		c0.8-0.1,1.8,0.1,2.5,0.7c0.3,0.1,1-0.7,0.8-0.9c-1-1-2.6-1.2-3.8-1c-1.7,0.3-3.5,1.9-4.1,3.8c-0.8,1.5-1.6,1.9-2.5,1.8
		c1.2-1.8,1.4-4.9-0.7-5.1c2.6-1.4,2.8-5.2-0.4-5.1c-1.1,0-2.1,0.6-2.8,1.8c-1.4,2-1.9,5.2-2,6.3c-0.4,2.5,0.4,3.9,2.3,3.9
		c1.3,0.1,2.1-0.4,2.9-1.1c1,0.2,2,0.1,2.9-0.8c0,1.4,0.9,2.1,2,2c1.4,0,2.2-1.1,2.8-2.2c-0.2,1.3,0.3,2.1,1.1,2.1
		c1.3,0,2.3-1,3.2-2.3c0.3,1.3,1.3,2.3,2.8,2.4c1.8,0.1,3.8-0.6,5.2-3.6C191.4,36.2,190.8,35.9,190.7,36.1z M170.7,37.3
		c-0.5-0.1-1,0.5-0.6,1c-0.3,0.4-0.7,0.6-1.2,0.6c-1.2-0.1-1.1-1.8-0.7-3.8c0.5-0.4,1.4-0.9,2.2-0.9
		C171.4,34.2,171.5,35.7,170.7,37.3z M170.7,32.3c-0.5,0.5-1.4,0.9-2.2,1.2c0.6-2.3,1.4-4.2,2.6-4.1
		C172.4,29.4,171.9,31.3,170.7,32.3z"
    />
    <path
      className="st1"
      d="M195,28.3c-0.5,1.8-1.6,6-2.2,8.2c-0.1,0.4,1.3,0.8,1.5,0.1c0.8-2.4,1.7-5.6,2.5-8.1
		C197,28,195.2,27.6,195,28.3z"
    />
    <path
      className="st1"
      d="M193.1,38.1c-0.5,0-1,0.4-1,0.9s0.3,0.9,0.9,0.9c0.5,0,0.9-0.4,1-0.9C194,38.5,193.6,38.1,193.1,38.1z"
    />
  </svg>
);

export default slogan;
