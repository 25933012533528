// import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import Drygalo from "./components/Drygalo";
import Contacts from "./components/Contacts";
import Foods from "./components/Foods";
import SingleFood from "./components/SingleFood";
import MenuEnd from "./components/MenuEnd";
import Search from "./components/Search";
import SearchProd from "./components/SearchProd";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Drygalo />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="foods" element={<Foods />} />
            <Route path="foods/:foodSingleSlug" element={<SingleFood />} />
            <Route path="menu" element={<MenuEnd />} />
            <Route path="search" element={<Search />} />
            <Route path="/:searchProdS" element={<SearchProd />} />
            <Route path="*" element={<Drygalo />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
