import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/opacity.css'
// import "../App.css";
// import "../index.css";
import foods from '../data/foods'
// import logo from "../data/logo";
import Slogan from './Slogan'

const botAnimation = {
  hidden: {
    y: -30,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.03 },
  }),
}

const MenuEnd = () => {
  return (
    <section>
      <section>
        <motion.section
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.2, once: true }}
          className="containerMenuEnd2"
        >
          <motion.div variants={botAnimation} custom={1}>
            <Link to="../.">
              <div className="blockMenuEnd lineDown">ДРИГАЛО</div>
            </Link>
          </motion.div>
          <motion.div variants={botAnimation} custom={2}>
            <Link to="../foods">
              <div className="blockMenuEnd lineDown">ПРОДУКЦІЯ</div>
            </Link>
          </motion.div>

          <div className="containerMenuFood">
            {foods.map((food, index) => (
              <motion.div
                variants={botAnimation}
                custom={index + 10}
                key={index}
              >
                <Link to={`../foods/${food.slug}`} key={food.id}>
                  <div className="blockMenuFood lineDownW">
                    <LazyLoadImage
                      className="imgMenuFood"
                      placeholderSrc={food.img2}
                      // loading="lazy"
                      effect="opacity"
                      src={food.img2}
                      alt={food.title}
                      draggable="false"
                    />
                    <div className="rowMenuFood">{food.title}</div>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
          <motion.div variants={botAnimation} custom={3}>
            <Link to="../contacts">
              <div className="blockMenuEnd lineDown"> КОНТАКТ</div>
            </Link>
          </motion.div>
        </motion.section>
      </section>
      <footer>
        <Slogan />
      </footer>
    </section>
  )
}

export default MenuEnd
