const logo = (
  <svg
    width="70%"
    className="animationRotate"
    x="0px"
    y="0px"
    viewBox="0 0 340.1 336.3"
  >
    <style type="text/css"></style>

    <path
      className="st0"
      d="M334.5,256.4c1-1.9,1.8-3.8,2.5-5.8c2.3-7.1,2.5-14.9,0.5-22.1c-2.1-7.5-5.6-13.7-10.2-17.9
c1.5-2,2.4-4.5,2.4-7.2c0-6.8-5.5-12.2-12.2-12.2s-12.2,5.5-12.2,12.2c0,0.7,0.1,1.4,0.2,2c-3.5,1.2-6.9,3.2-10.2,6.1
c-3.5,3.2-6.3,7.1-8.6,11.3V119.3C286.6,57.3,234.4,6.8,170,6.6h-0.2C105.4,6.7,53.2,57.3,53.2,119.3v83.3
c-15.5-4.5-29.2-2.5-39.6,5.9c-6,4.8-10.7,13-11.9,21.1c-1.4,8.8-0.2,20.9,7,27.8c-5.3,7.2-5.4,16.9,0.2,23.4
c6.7,7.9,19.9,18.6,28.1,23.5c5.6,3.4,11.4,6.5,17.4,9.1c0.9,5.6,5.2,10.5,11.8,13.4c7.5,3.3,14.7,2.3,18.8-2.5
c25.6,7,55.6,10.6,87.1,10.6c53.1,0,104.4-14.3,141-39.1c23.2-15.9,25.9-24.5,25.9-29.6C338.9,262.4,337.4,258.9,334.5,256.4z
M150.9,118.2c-0.3,2.5-1.9,8.1-5.7,11.3c-2.6,2.2-6.5,3.7-10.4,5c-0.6-0.3-1.2-0.3-1.8-0.2h-0.3c-5.9,0.7-21.6,5.2-24.4,6.3
c-2.2,0.9-3.1,1.3-3.4,2.1c-0.1,0.2-0.1,0.3-0.1,0.5c-3.4,1.7-6.8,3.6-9.6,6.1c-4.3,3.6-7.9,7.8-10.7,12.7c-2,3.4-3.7,7-4.9,10.7
l0-50.3c0-38.5,26-71.3,62-82.7c-1.8,1.3-3.6,2.8-5.3,4.5c-12.5,12.1-16.9,38,0.5,53.7c3.3,4,10.5,7.6,14,10.7
C151.4,111.1,151.4,114.3,150.9,118.2z M260.2,172c-1.2-3.5-2.8-6.9-4.6-10.2c-2.8-4.8-6.5-9.1-10.7-12.7c-3-2.5-6.4-4.4-9.8-6.1
c0-0.1,0-0.2-0.1-0.2c-0.3-0.8-1.2-1.2-3.4-2.1c-2.8-1.2-18.6-5.6-24.4-6.3h-0.3c-0.6-0.2-1.3-0.1-1.9,0.2c-3.9-1.3-7.7-2.8-10.2-5
c-3.8-3.2-5.4-8.8-5.7-11.3c-0.1-0.9-0.2-1.8-0.3-2.6c0.2-0.2,0.4-0.3,0.6-0.5c1.8-1.9,3.4-4.7,5.3-7.2c1.9-2.4,3.5-5,4.6-7.7
c1-2.6,3-6.2,4.8-10.1c8.4-18.1,8.5-35.9-8.4-50.8c-0.2-0.2-0.4-0.3-0.6-0.5c37.5,10.4,65.1,43.9,65.1,83.5V172z"
    />
    <g>
      <path
        className="st1"
        d="M180.8,142.7c-2.8,0.1-4.9,2.4-4.8,5.1v0.1c0.1,2.7,2.4,4.9,5.2,4.8c2.7-0.1,4.9-2.5,4.8-5.2
C185.8,144.8,183.6,142.6,180.8,142.7z"
      />
      <path
        className="st1"
        d="M184.9,142.3c0.1,2.3,2,4.2,4.4,4.1c2.3-0.1,4.2-2,4.1-4.4c-0.1-2.3-2-4.2-4.4-4.1
C186.7,137.9,184.8,139.9,184.9,142.3z"
      />
      <path
        className="st1"
        d="M189.9,135c0.1,1.9,1.6,3.4,3.5,3.3c1.9-0.1,3.4-1.6,3.3-3.5c-0.1-1.9-1.6-3.4-3.5-3.3
C191.3,131.5,189.9,133.1,189.9,135z"
      />
      <path
        className="st1"
        d="M195.3,130.9c1.4,0,2.5-1.2,2.5-2.6s-1.2-2.5-2.6-2.5s-2.5,1.2-2.5,2.6C192.7,129.8,193.9,130.9,195.3,130.9z"
      />
      <path
        className="st1"
        d="M170,155.5c3,0.1,5.5-2.3,5.5-5.3c0.1-2.9-2.3-5.4-5.3-5.5c-3-0.1-5.5,2.3-5.5,5.3S167,155.5,170,155.5z"
      />
      <path
        className="st1"
        d="M158.8,152.7c2.8,0.1,5.1-2.1,5.2-4.8v-0.1c0.1-2.8-2.1-5-4.8-5.1c-2.8-0.1-5.1,2.1-5.2,4.8
C154,150.3,156.2,152.6,158.8,152.7z"
      />
      <path
        className="st1"
        d="M150.7,146.6c2.4,0.1,4.3-1.8,4.4-4.2c0-2.4-1.8-4.4-4.2-4.4c-2.4-0.1-4.3,1.8-4.4,4.2
C146.4,144.6,148.3,146.5,150.7,146.6z"
      />
      <path
        className="st1"
        d="M146.7,138.5c1.9,0,3.4-1.4,3.5-3.3c0.1-1.9-1.4-3.5-3.3-3.5c-1.9,0-3.4,1.4-3.5,3.3
C143.4,136.9,144.8,138.4,146.7,138.5z"
      />
      <path
        className="st1"
        d="M144.6,131c1.4,0,2.6-1.1,2.6-2.5c0-1.4-1.1-2.5-2.5-2.6c-1.4,0-2.6,1.1-2.6,2.5
C142.2,129.8,143.2,131,144.6,131z"
      />
      <path
        className="st1"
        d="M198.1,114.2c3,0.1,5.5-2.3,5.5-5.3c0-2.9-2.3-5.4-5.3-5.5c-0.2,0-0.4,0-0.6,0l-4.7,6.8
C193.6,112.4,195.6,114.2,198.1,114.2z"
      />
      <path
        className="st1"
        d="M143.4,101.5c1.1,0.7,1.8,0.2,1.6-1.1c-0.8-4.5-1-9-0.6-13.5c0-0.6-0.1-1.2-0.5-1.7
c-7.9-9.5-10.1-29.1,3.2-42.9c-1-0.5-2-1.1-2.9-1.8C124.2,51,119.1,86.2,143.4,101.5z"
      />
      <path
        className="st1"
        d="M165.4,39.7c-2,1.5-4.3,2.7-6.8,3.4l-0.8,0.2c-3,0.8-6.2,0.7-9.1-0.2c-10.4,9.3-11.5,25.4-4.8,37.9
c0.2,0.4,0.6,0.5,1,0.3c0.2-0.1,0.3-0.3,0.4-0.5c4.2-16,13.6-14.3,19.7-19.5c8.7-7.3,9.4-11.5,14.1-13.8c1.2-0.6,3.1-0.8,2.4-0.3
c-1.8,1.3-3.5,2.7-5.1,4.3c-0.5,0.5-0.9,1-1.3,1.6c-0.7,1.1-0.5,2.5,0.6,3.3c13.1,9.1,30.2,9.2,25.1,36c0,0.2,0.1,0.3,0.2,0.3
s0.3,0,0.3-0.2c13.8-27.3,3.2-44.5-9.7-53.7c-0.3-0.2-0.7-0.4-1-0.7c-0.7,0.3-1.4,0.5-2.1,0.7c-4.4,1.1-8.8,1.2-13.2,0.2l-0.4-0.1
c-0.8,0.9-2,1.6-3.2,1.9h-0.1c-2.1,0.7-4.4,0.3-6.1-1.2L165.4,39.7z"
      />
      <path
        className="st1"
        d="M188.5,36.6c0.9-0.3,1.8-0.6,2.6-1c6.4-3.1,9.4-7.2,14.4-8.6l0.2-0.1c-6.5-3.8-15.9-4.7-22.8-2.7
c-3.6,1.1-6.9,3-8.3,5.6c0.8,0.6,1.4,1.5,1.8,2.5c2.2-1.6,4.6-2.8,7.1-3.6c3-0.9,6.2-1.3,9.3-1.2c0,0-4.6,0.9-6.2,1.4
c-2.9,0.9-7.2,2.5-10,5c0.2,1,0.1,2-0.3,3C180.4,37.7,184.6,37.7,188.5,36.6z"
      />
      <path
        className="st1"
        d="M169,29.7c-2.5,0.7-4.1,2.3-3.2,5.7c0.9,3.4,3.2,3.8,5.6,3.2h0.1c2.4-0.8,4-2.5,3-5.8
C173.4,29.5,171.2,29,169,29.7z"
      />
      <path
        className="st1"
        d="M148.5,40.6c3,1.2,6.3,1.4,9.3,0.6l0.7-0.2c2.3-0.7,4.4-1.7,6.3-3.1c-0.3-0.7-0.6-1.5-0.8-2.3
c-0.1-0.2-0.2-0.4-0.2-0.6c-1.4,0.5-2.7,1-3.9,1.3l-0.8,0.2c-8,2-13.4,0.3-18.2-2.6C142.3,36.5,145.6,39.4,148.5,40.6z"
      />
      <path
        className="st1"
        d="M160.2,35c1.2-0.3,2.3-0.7,3.4-1.2c0.1-0.9,0.5-1.8,1-2.5c-1.8-4.4-9.9-9.5-20.6-6.7c-4.4,1.1-7,1.9-9.8,1.9
c3.2,4.2,7.5,7.1,12.6,8.6C151.2,36.4,155.9,36.3,160.2,35z"
      />
      <path
        className="st1"
        d="M174.7,112.4c3.9,0.5,7.1-0.7,9.3-4.3c1-1.6,2.1-3.3,3-4.8c-3.6-1.4-7.6-1.9-11.5-1.5
c-3.9,0.4-7.6,1.4-11.8,2.1C167.3,107.1,169.6,111.8,174.7,112.4z M184.4,105c-4,5.1-10.9,4.6-16.5,0.1
C173.6,104.9,180.6,104.7,184.4,105z"
      />
      <path
        className="st1"
        d="M150,109.1c0.1-3-2.3-5.4-5.3-5.5c-3-0.1-5.5,2.3-5.5,5.3s2.3,5.5,5.3,5.5C147.5,114.5,150,112.1,150,109.1z"
      />
      <path
        className="st1"
        d="M231.9,174.7c0-1.6-0.4-2.2-1.4-1.9l0.1,0.8l-0.9,0.1C229.7,174.8,230.3,175,231.9,174.7z"
      />
      <path
        className="st1"
        d="M231.3,179.1l0.1,0.9c1.1,0,1.3-0.5,0.9-2.2c-1.6-0.1-2.2,0.4-1.8,1.4L231.3,179.1z"
      />
      <path
        className="st1"
        d="M235.5,177.5c0,1.6,0.4,2.2,1.4,1.8l-0.1-0.8l0.9-0.1C237.7,177.3,237.2,177.1,235.5,177.5z"
      />
      <path
        className="st1"
        d="M236.2,172.9l-0.1-0.9c-1.1,0-1.3,0.5-0.9,2.1c1.6,0.2,2.1-0.3,1.8-1.3L236.2,172.9z"
      />
      <path
        className="st1"
        d="M244.8,176.2c0,1.6,0.4,2.2,1.4,1.8l-0.1-0.8l0.9-0.1C247,176,246.5,175.8,244.8,176.2z"
      />
      <path
        className="st1"
        d="M244.3,172c0,0.3,0.1,0.7,0.1,1c1.2,0,1.9-0.2,1.9-0.8c0-0.2,0-0.4-0.1-0.6l-0.8,0.1v-0.8
C244.6,170.9,244.3,171.2,244.3,172z"
      />
      <path
        className="st1"
        d="M241.2,173.4c0-1.6-0.4-2.2-1.4-1.8l0.1,0.8l-0.8,0.1C239.2,173.6,239.7,173.8,241.2,173.4z"
      />
      <path
        className="st1"
        d="M239.8,177.4c0,0.2,0,0.4,0.1,0.6l0.8-0.1l0.1,0.9c0.8,0,1.1-0.3,1.1-1.1c0-0.4-0.1-0.7-0.1-1.1
C240.5,176.6,239.9,176.8,239.8,177.4z"
      />
      <path
        className="st1"
        d="M250.7,172.2c0-1.6-0.4-2.2-1.4-1.8l0.1,0.8l-0.9,0.1C248.5,172.3,249,172.6,250.7,172.2z"
      />
      <path
        className="st1"
        d="M249.2,176.8l0.8-0.1l0.1,0.9c1.1,0,1.3-0.5,0.9-2.1C249.4,175.3,248.9,175.8,249.2,176.8z"
      />
      <path
        className="st1"
        d="M254.3,175c0,1.6,0.4,2.2,1.4,1.9l-0.1-0.8l0.9-0.1C256.3,174.8,255.8,174.6,254.3,175z"
      />
      <path
        className="st1"
        d="M254.8,170.5l-0.1-0.9c-1.1,0-1.3,0.5-0.9,2.1c1.6,0.1,2.2-0.3,1.8-1.3L254.8,170.5z"
      />
      <path
        className="st1"
        d="M110.1,173.8l-0.9-0.1l0.1-0.8c-1-0.3-1.4,0.3-1.4,1.9C109.5,175.2,110,175,110.1,173.8z"
      />
      <path
        className="st1"
        d="M108.4,180.1l0.1-0.9l0.8,0.1c0.4-0.9-0.2-1.4-1.8-1.4C107.1,179.6,107.3,180.1,108.4,180.1z"
      />
      <path
        className="st1"
        d="M102.1,178.4l0.9,0.1l-0.1,0.8c1,0.4,1.4-0.2,1.4-1.8C102.6,177.3,102.1,177.5,102.1,178.4z"
      />
      <path
        className="st1"
        d="M104.6,174.3c0.4-1.6,0.2-2.1-0.9-2.1l-0.1,0.9l-0.8-0.1C102.5,174,103,174.5,104.6,174.3z"
      />
      <path
        className="st1"
        d="M92.8,177.3l0.8,0.1l-0.1,0.8c1,0.4,1.4-0.2,1.4-1.8C93.3,176,92.8,176.2,92.8,177.3z"
      />
      <path
        className="st1"
        d="M95.6,172.1c0-0.8-0.3-1.1-1.1-1.1l-0.1,0.9l-0.8-0.1c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.6,0.7,0.8,1.9,0.8
C95.4,172.9,95.5,172.5,95.6,172.1z"
      />
      <path
        className="st1"
        d="M98.6,173.6c1.6,0.4,2.1,0.2,2.1-0.9l-0.8-0.1l0.1-0.8C99,171.4,98.6,172,98.6,173.6z"
      />
      <path
        className="st1"
        d="M97.9,177.8c0,0.8,0.3,1.1,1.1,1.1l0.1-0.9l0.8,0.1c0.1-0.2,0.1-0.4,0.1-0.6c0-0.6-0.7-0.8-1.9-0.8
C98,177.1,98,177.5,97.9,177.8z"
      />
      <path
        className="st1"
        d="M89.1,172.4c1.7,0.4,2.2,0.1,2.2-0.9l-0.9-0.1l0.1-0.8C89.5,170.2,89.1,170.8,89.1,172.4z"
      />
      <path
        className="st1"
        d="M89.7,177.7l0.1-0.9l0.8,0.1c0.3-1-0.2-1.5-1.8-1.3C88.4,177.3,88.6,177.7,89.7,177.7z"
      />
      <path
        className="st1"
        d="M85.6,175.2c-1.6-0.4-2.2-0.2-2.2,1l0.9,0.1l-0.1,0.8C85.2,177.4,85.6,176.8,85.6,175.2z"
      />
      <path
        className="st1"
        d="M85.1,169.8l-0.1,0.9l-0.8-0.1c-0.4,1,0.2,1.4,1.8,1.3C86.4,170.3,86.2,169.8,85.1,169.8z"
      />
      <path
        className="st1"
        d="M285.2,286.5c-30,12.2-70.2,19-113.2,19c-30.6,0-62-3.4-88.6-10.5l4.8-9.9c6.1,5,15.6,5.2,22.3,0.6
c5.6,1.7,11.5,0.6,16.2-2.9c-0.7,5.1,1.1,11.3,9.4,11.5c6.8,0.2,12.2-2.8,17.3-10c-1.6,5.9,1,9.7,5.6,10.6
c6.8,1.2,13.1-2.6,19.4-14.8c1.5-2.9,5.3-9.9,7.7-13.1c4.4-5.6,8.4-5,8.4-1.7c-0.1,1.3-0.6,2.5-1.4,3.4
c-3.4,3.9-10.9,8.9-10.3,17.3c0.4,5.9,5.3,8.2,12.7,7.7c6.9-0.5,13.7-3.7,19.4-11.2c1.8,7.6,8.1,8.5,12.9,7.6
c7.9-1.4,11.2-8,13.1-13.2c0.4,6.1,3,9.7,7.6,8.9c5.2-0.8,9.5-4,13-8.4c0,1.5,0,2.9,0,4.4c0.1,3.8,11.9-0.6,11.8-3.1
c-0.2-6.1,0-14.9,1.7-22.5h0.2c2.4,14.9,6.3,22.6,16.1,19.2c6-2.1,9.5-7.3,11.9-14.7c5.9,4.7,12.4,2.9,18.4-1.8
c3.4-2.7,6.1-6.3,7.7-10.3c1.6-4.3,1.7-8.9,0.3-13.3c-3.9-12.2-14.6-19.3-26.2-10.2c-8.3,6.6-11.9,17.3-5.9,28.5
c0.7,1.3,1.4,2.5,2.3,3.6c-1.7,6.2-4.2,9-6.9,9.8c-4.8,1.5-7-9.4-9.2-25.2c-0.4-3.2-14.3,0.6-15.5,3.8c-2.7,6.9-4.7,14-5.9,21.3
c-2,5.4-6.2,10.2-8.6,10.3c-3.2,0.1-2.4-12.5-2-14.8c-1-2.1-8.3-3.1-10.7-0.6c-1.3,6.5-6.7,22.4-11.3,19.6c-1.5-1-2.1-4.5-1.1-9.7
c1.2-6,5.8-11.8,10.9-13.3c3.7-1,9.3-1.3,13.3,0.8c1.9,0.5,4.7-5.2,3.1-6.6c-6.9-4.1-15.7-3.2-21.8-1.2
c-8.1,2.6-18.2,12.1-19.6,23.6c-4.1,8.6-10.5,11-14.5,11.4c-4.5,0.4-5.6-4.2-1-8c3.8-3.1,9.9-7.3,9.4-14.1c-0.4-5-4.3-8.6-11.7-8.2
c-12.9,0.7-16.8,13.1-21.9,22.3c-3,5.3-7.1,9-9.4,9.1c-3.7,0.1,3.9-18.5,6.3-27.3c-1.9-3.1-11.7-3.6-13.3-1.8
c-1.7,5.2-3.9,11.4-5.9,16.6c-2,5.4-6.6,11.3-9.6,11.3c-2.8,0-1-4.7,0.7-10.3c1.6-5.6,3.8-12.4,5.2-16.8
c-0.5-2.9-11.7-3.8-13.3-1.7c-1.6,5.1-4.1,9.9-5.8,15.3c-0.1,0.4-1.2,2.3-1.3,2.5c-2.6,4.5-8,7.6-13.6,6.8
c8.1-7.8,12.5-25.8,1.3-28.3c-6.2-1.4-11.3,1.3-15.8,3.9l1.8-4.1c-1.2-3.6-11.7-4.5-12.5-3c-5.6,13.9-11.8,27.5-18.9,40.7
c-5.6-1.9-11-4.1-16.3-6.5c6.8-1.7,15-10.2,19-23.3c7-23.6-9.2-38.1-22.9-41.9c-11-3.1-22.8-3.2-32.3,3.4c-4.1,3-6.9,7.3-7.9,12.3
c-0.9,5.1,0.4,18,11.8,20.6c-2.2,3.4-4,5.6-5.7,7.1c-2.7,2.4-4.2,6.4-1.4,9.1c5.8,6,18.3,14.8,25.8,18.8
c7.9,4.2,16.1,7.6,24.5,10.5l-1.9,3.7c-0.9,3.7,11.2,8.3,13.3,5.1l2-4.6c26.3,7.1,58.3,11.1,93.5,11.1c55.5,0,104.2-13.4,137-33
c13.4-7.9,21.3-15.1,21.3-17.8C330.4,260.9,316.9,273.6,285.2,286.5z M307.2,232c3.1-2.6,7.1,2.4,10,8.5c1.5,3.3,2.5,7,1.9,8.8
c-0.2,0.8-0.7,1.6-1.4,2.1c-3.9,3.1-7-2.7-9.2-7.3C305.8,238.6,304.9,234,307.2,232z M97.7,263.4c3.5-2.1,6.8-2.8,8.3-2.2
c2.5,0.9,1.9,6.1-1.4,11.9c-1.6,2.8-3.3,6.9-6.4,7.3c-2.6,0.4-5.3-0.6-7-2.6C93.7,273,95.4,268,97.7,263.4z M40.9,276.3
c-2.6-0.1-8.5-3.9-12.6-7.7c2.6-1.3,5.6-4.8,7.4-8.1c4.5-7.6,8.5-16.1,14.7-22.1c-0.3-2.8-7.9-6.7-12.3-4.9
c-6.3,4.2-9.1,11.4-13.9,19.1c-3.2-1.3-6.6-3.7-7-9.2c-0.2-3.5,0.9-6.9,3.2-9.4c6.8-8,18.5-7,25.6-4.8c15.3,5,13.7,16.5,10.5,27
C52.9,268,47.2,276.6,40.9,276.3z"
      />
      <path
        className="st1"
        d="M281.4,226.6c0.8,0.1,1.6,0.3,2.4,0.5V116.3c0-60.6-51.2-110-114-109.9C107,6.5,56,55.8,56,116.3v87.2
c3.5,1.3,6.9,3.1,10,5.4c1-2.2,2.1-4.3,3.6-6.3c1.4-2,3-3.9,4.7-5.6c1.7-1.8,2.8-4,3.3-6.3V120c0-38.9,25.9-72.1,61.9-84.4
c-0.2-0.4-0.4-0.7-0.6-1.1l-0.7-1.4c-2.3-1.6-4.3-3.5-6-5.7l-0.3-0.4l-1.1-0.5c-0.4-0.9-0.3-1.9,0.3-2.7c0.9-1,2.3-1.5,3.7-1.4
c2.9-0.3,5.7-0.9,8.4-1.7l0.2-0.1c10.9-2.9,18.6,1.6,21.8,5.9c0.7-0.4,1.5-0.8,2.3-1h0.1c1.6-0.5,3.4-0.5,5,0
c2.2-2.5,5.2-4.4,8.4-5.2c7.7-2.3,17.9-1.1,24.6,2.9c0.7,0.4,1.3,1,1.9,1.5c0.7,0.7,1,1.7,1.2,2.7c-0.2,0.8-0.7,1-1.5,1.2l-2.4,0.2
c-2.2,0.7-4.2,2.1-6.3,3.7c-0.7,0.6-1.5,1.1-2.2,1.6c38.4,10.9,66.5,45.3,66.5,85.8v63.8c0.2,1.7,0.3,3.5,0.3,5.3
c0.1,2.9,1.3,5.6,3.3,7.5c1.7,1.8,3.3,3.7,4.8,5.7l0.1,0.2c1.5,2,2.8,4.2,3.8,6.5c2.4,6.2,1.7,13.3,0.8,18
C278,226.5,280,226.4,281.4,226.6z"
      />
      <path
        className="st1"
        d="M53.2,116.3c0-62.2,52.2-112.6,116.6-112.8h0.2c64.3,0.2,116.6,50.7,116.7,112.8v106.4c1-1.9,2.1-3.7,3.4-5.4
V116.9C290,53.2,236.3,1.3,170.2,1.2H170c-66,0.1-119.7,52-119.7,115.7v85c1,0.2,1.9,0.5,2.9,0.8V116.3z"
      />
      <path
        className="st1"
        d="M233.5,143.1c-0.8-0.5-1.6-0.9-2.5-1.2c-2.7-1.1-18.3-5.5-23.9-6.2h-0.4c-0.3,0-0.6,0-1,0c0,0-0.1,0.1-0.2,0.7
c-0.1,0.8-0.1,2-0.2,3.3c-0.1,1.9-0.1,4.2-0.4,6.8c-1.5,12.9-4.9,22.1-17.2,28.5c-1.1,0.6-2.2,1.1-3.3,1.6
c-4.5,2.2-8.3,4.1-11.3,7.5c-3,3.5-3.9,7-4.2,11.2c0.4,0.4,0.8,0.7,1.2,1.2c0.3,0.3,0.6,0.6,0.9,0.9c1.1-0.1,2.2-0.2,3.3-0.5
c2.1-0.5,4.4-0.2,6.3,0.8c1.3,0.8,2.5,1.8,3.4,2.9c1.8-0.6,3.8-0.8,5.9-0.7c0.7-0.5,1.3-1,1.9-1.6c4.4-3.6,8.2-3.9,13.1-1.1
c0.5,0.3,1.1,0.6,1.7,0.7c0.8-0.2,1.5-0.4,2.2-0.8c5.6-2.7,11.7-1.7,15.4,3.4c0.2,0.4,0.5,0.7,0.8,1c1.1,0.1,2.2,0,3.3-0.2
c0.5-0.1,1-0.1,1.4-0.1c-0.1-2.7-0.4-5.3-0.7-7.9c-0.7-4.9-1.1-9.8-1.2-14.8c-0.1-5.2,0.1-22.9,5.7-34.9
C233.5,143.4,233.5,143.2,233.5,143.1z M211.1,140.1c3,2.1,6.5-1.2,5.8,4.9c-0.7-2.6-2.1-1.3-3.9-1.8c-0.3,1.9-2.1,2.5-0.1,4.3
C207.1,145.4,211.6,143.7,211.1,140.1z M216.4,148.8c-1.1,0.7-3-2.3-3.8-3.3c0.6-0.1,1.3,0,1.9,0.3c0-0.6,0.2-1.3,0.6-1.8
C215.6,145.2,217.5,148.1,216.4,148.8z M208.7,148.5c0.2-0.8,1.1-1.4,2.6-0.5c1.4,0.7,2.9,1.3,4.4,1.6c0.8,0.2,0.7,0.7,0.7,0.7
s-0.1,0.6-0.9,0.4c-1.5-0.4-3.1-0.6-4.7-0.6C209,150.1,208.5,149.3,208.7,148.5z M210.9,152.9c1.2-0.5,4.1-2.3,4.8-1.2
c0.7,1.1-2.3,2.9-3.3,3.7c-0.1-0.7,0-1.3,0.3-1.9C212.1,153.5,211.4,153.3,210.9,152.9z M207.1,157c2-3-1.2-6.5,4.9-5.8
c-2.7,0.7-1.4,2.1-1.9,3.9c1.8,0.3,2.5,2.1,4.4,0.1C212.3,161,210.7,156.5,207.1,157z M217.5,152.5c-0.4,1.6-0.6,3.1-0.6,4.7
c0.2,1.8-0.7,2.3-1.5,2.1c-0.8-0.2-1.4-1.1-0.5-2.6c0.7-1.4,1.3-2.9,1.6-4.4c0.2-0.8,0.7-0.7,0.7-0.7S217.7,151.7,217.5,152.5z
M217.7,149.5c0,0-0.5-0.1-0.3-0.9c0.4-1.5,0.6-3.1,0.6-4.7c-0.2-1.8,0.8-2.3,1.6-2.1c0.8,0.2,1.4,1.1,0.4,2.6
c-0.7,1.4-1.3,2.9-1.6,4.4C218.2,149.6,217.7,149.5,217.7,149.5z M224.1,148.3c-1.2,0.5-4.1,2.4-4.8,1.3c-0.7-1.1,2.3-3,3.3-3.8
c0.1,0.6,0,1.3-0.3,1.9C222.9,147.7,223.6,147.9,224.1,148.3z M218.6,152.4c1.1-0.7,2.9,2.3,3.7,3.3c-0.7,0.1-1.3,0-1.9-0.3
c0,0.6-0.2,1.3-0.6,1.8C219.3,156,217.5,153.1,218.6,152.4z M223.9,161c-3-2-6.5,1.2-5.8-4.9c0.7,2.7,2.1,1.4,3.9,1.9
c0.4-1.9,2.2-2.5,0.1-4.4C227.8,155.8,223.4,157.4,223.9,161z M226.3,152.7c-0.2,0.8-1.1,1.3-2.6,0.4c-1.4-0.7-2.9-1.3-4.4-1.6
c-0.8-0.2-0.7-0.7-0.7-0.7s0.1-0.5,0.9-0.3c1.5,0.4,3.1,0.6,4.7,0.6C226,151,226.5,151.9,226.3,152.7z M223.1,149.8
c2.6-0.7,1.3-2,1.8-3.9c-1.9-0.3-2.5-2.1-4.3-0.1c2.2-5.8,3.8-1.3,7.3-1.8C225.9,147.1,229.1,150.5,223.1,149.8z"
      />
      <path
        className="st1"
        d="M106.5,143.3c0,0,0,0.1,0.1,0.4c5.5,12,5.7,29.8,5.7,34.9c0,5-0.4,9.9-1.2,14.8c-0.6,3.6-0.8,7.2-0.8,10.8
c0,1.1,0,2.2,0.1,3.2c4.1-3.7,9.5-4.5,14.2-1.8c2.3-4,6.4-5.9,11.4-6.3c0.2,0,1.6-1.6,1.7-1.8c4-4,7.8-4.8,12.9-2.5
c0.6,0.3,1.2,0.5,1.8,0.5c0.7-0.2,1.4-0.6,2.1-1c4.2-2.6,9.1-2.9,13-0.5c0.2-2.4,0.7-4.6,1.7-6.7c-0.6-1.1-1.3-2.2-2.2-3.3
c-3-3.5-6.9-5.4-11.3-7.5c-1.1-0.5-2.2-1.1-3.3-1.6c-12.3-6.4-15.7-15.6-17.2-28.5c-0.3-2.6-0.4-4.9-0.4-6.8c0-1.3-0.1-2.4-0.2-3.3
c-0.1-0.6-0.2-0.7-0.2-0.7h-1.2c-5.8,0.6-21.2,5-23.9,6.2C108.1,142.3,107.3,142.7,106.5,143.3z M114,152.7
c-0.2-0.8,0.3-1.8,2.1-1.6c1.6,0,3.2-0.2,4.7-0.6c0.8-0.2,0.9,0.3,0.9,0.3s0.1,0.5-0.7,0.7c-1.5,0.3-3,0.9-4.4,1.6
C115.1,154.1,114.2,153.5,114,152.7z M117.6,145.7c1,0.8,4,2.6,3.3,3.7c-0.7,1.1-3.6-0.7-4.8-1.2c0.5-0.4,1.2-0.6,1.8-0.6
C117.6,147,117.5,146.4,117.6,145.7z M120.4,157.2c-0.4-0.5-0.6-1.2-0.6-1.8c-0.6,0.3-1.3,0.4-1.9,0.3c0.8-1,2.7-4,3.8-3.3
C122.8,153.1,120.9,156,120.4,157.2z M116.2,161.1c0.5-3.6-4-5.2,1.8-7.3c-2,1.8-0.2,2.4,0.1,4.3c1.8-0.6,3.2,0.8,3.9-1.8
C122.7,162.3,119.2,159,116.2,161.1z M133.2,157c-3.6-0.5-5.3,4-7.3-1.8c1.8,2,2.4,0.2,4.3-0.1c-0.6-1.8,0.7-3.2-1.8-3.9
C134.4,150.5,131.1,154,133.2,157z M127.9,155.4c-1-0.9-4-2.7-3.3-3.8c0.7-1.1,3.6,0.8,4.8,1.3c-0.5,0.4-1.2,0.6-1.8,0.6
C127.9,154.1,128,154.8,127.9,155.4z M131.4,148.4c0.2,0.8-0.3,1.8-2.1,1.6c-1.6,0-3.2,0.2-4.7,0.6c-0.8,0.2-0.9-0.3-0.9-0.3
s-0.1-0.5,0.7-0.7c1.5-0.3,3-0.9,4.4-1.6C130.3,147.1,131.2,147.6,131.4,148.4z M125.1,144c0.4,0.5,0.6,1.2,0.6,1.8
c0.6-0.3,1.2-0.4,1.9-0.3c-0.8,1-2.6,4-3.7,3.3C122.8,148.1,124.6,145.2,125.1,144z M129.1,140.2c-0.5,3.6,4,5.2-1.8,7.3
c2-1.9,0.2-2.6-0.1-4.4c-1.8,0.6-3.2-0.8-3.9,1.9C122.7,139,126.2,142.2,129.1,140.2z M123.7,152.3c0.3,1.5,0.9,3,1.6,4.4
c0.9,1.5,0.4,2.4-0.4,2.6c-0.8,0.2-1.7-0.3-1.6-2.1c0-1.6-0.2-3.2-0.6-4.7c-0.2-0.8,0.3-0.9,0.3-0.9S123.5,151.5,123.7,152.3z
M120.6,141.9c0.7-0.2,1.7,0.2,1.6,2c0,1.6,0.2,3.1,0.6,4.7c0.2,0.8-0.3,0.9-0.3,0.9s-0.5,0.1-0.7-0.6c-0.3-1.5-0.9-3-1.6-4.4
C119.2,143,119.9,142.1,120.6,141.9z M119.6,145.9c-1.8-2-2.5-0.2-4.4,0.1c0.6,1.8-0.8,3.2,1.9,3.9c-6.1,0.7-2.9-2.8-4.9-5.8
C115.8,144.6,117.4,140.1,119.6,145.9z"
      />
      <path
        className="st1"
        d="M189.4,212.2c-2.1-3.1-6.2-0.9-8.2-4.1s-2.1-7.6-9-5.7c-2.1,0.3-4.3-0.4-5.9-1.8c-2.2-2.3-5-4.1-9.9-0.9
c-2.1,1.2-4.4,1.6-6.1,0.8c-5.6-2.7-6.8-0.5-9.2,1.7c-1.4,1.5-3.3,2.4-5.4,2.4c-0.6,0-1.1,0.1-1.6,0.2c0.5,0,1.1,0.1,1.6,0.2
c2.1,0.6,3.9,1.8,5.4,3.4c6.5-2.6,12.5-3.8,18.8-3.5c9.6,0.4,17,4.3,27.1,13c2.5,2.1,3.4,1.4,3.4-0.1
C190.5,216,190.2,213.4,189.4,212.2z"
      />
      <path
        className="st1"
        d="M116.8,216.9c7.7-1.3,14.5-0.3,22.9,3.4c2.4-2.1,4.7-3,7.4-2.9c-2.8-2.4-6.3,0.5-8.9-2.2
c-2.6-2.7-3.8-7-10-3.6c-2,0.8-4.3,0.6-6.1-0.5c-2.6-1.7-5.8-2.9-9.9,1.4c-1.8,1.6-3.9,2.6-5.8,2.1c-6-1.4-6.8,1.1-8.6,3.7
c-1,1.8-2.7,3.1-4.7,3.6c-6.4,1.6-4.5,7.3-5.7,12.7C97.9,224.4,106.5,218.8,116.8,216.9z"
      />
      <path
        className="st1"
        d="M190.3,234.5c-2.2-0.5-4.7-0.2-6-2.4c-2-3.3-2.3-7.8-9.3-5.8c-2.2,0.4-4.4-0.3-6.1-1.8
c-2.2-2.3-5.2-4.2-10.3-0.8c-2.1,1.2-4.5,1.7-6.3,0.9c-5.8-2.8-7.1-0.4-9.5,1.8c-1.4,1.6-3.4,2.5-5.5,2.6c-5.5,0.2-6.1,4.1-7.4,8.4
l-0.1,0.3c-0.2,0.6,0.1,1.2,0.7,1.4c0.3,0.1,0.5,0,0.8-0.2l0.5-0.4c11.4-6.7,21.5-10.3,31.5-9.6c9.3,0.6,17,4,26,11.4
c0-0.1,0-0.1,0-0.2c-0.1-0.7-0.1-1.4,0-2.1C189.5,236.7,189.8,235.6,190.3,234.5z"
      />
      <path
        className="st1"
        d="M255.5,239.2c0.6,0.4,1.4,0.2,1.7-0.4c0.2-0.3,0.2-0.6,0.2-0.9c-0.2-2-1-4-2.2-5.6c-2.5-2.8-6.2,0-8.6-2.9
c-2.4-2.9-3.1-7.2-9.6-4.4c-2,0.6-4.3,0.2-6-1c-2.4-1.9-5.5-3.3-9.9,0.5c-1.9,1.5-4,2.2-5.9,1.6c-5.8-1.9-6.8,0.5-8.8,2.9
c-1.1,1.7-2.9,2.8-4.9,3.1c-6.5,1.2-5.2,6.9-6.7,12.1c11.2-9.2,20.1-13.9,30.5-14.9C235,228.3,244,231.7,255.5,239.2z"
      />
      <path
        className="st1"
        d="M194.2,208.2c0.4,0.4,0.7,0.8,1,1.2c6.5-1.8,12.3-2.2,18.3-1.3c7.5,1.1,13.4,4.4,20.2,10.8
c0.2,0.1,0.3,0.2,0.5,0.4c0.3,0.3,0.6,0.5,1,0.8c1.1-0.2,2.2-0.6,3.2-1c0.9-0.4,2-0.6,3-0.6c0-0.1,0-0.1-0.1-0.2
c-1.7-3.2-6-1.5-7.5-4.8c-1.5-3.3-1.3-7.6-8.1-6.5c-2.1,0.1-4.1-0.8-5.5-2.4c-1.9-2.4-4.4-4.5-9.5-1.9c-2.1,0.9-4.4,1.1-6,0.1
c-5.1-3.2-6.6-1.2-9.1,0.7c-1.5,1.3-3.5,2-5.5,1.8c-0.6-0.1-1.1,0-1.6,0C190.7,205.7,192.9,206.7,194.2,208.2z"
      />
    </g>
    <g>
      <path
        className="st2"
        d="M194.1,127.4c0,0.4,0.4,0.8,0.9,0.8c0.4,0,0.8-0.4,0.8-0.9c0-0.4-0.4-0.8-0.9-0.8S194,126.9,194.1,127.4z"
      />
      <path
        className="st2"
        d="M192.7,132.5c-0.6,0.1-1.1,0.6-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1
C193.8,133,193.3,132.5,192.7,132.5z"
      />
      <path
        className="st2"
        d="M188.2,141.6c0.1,0,0.1,0,0.2,0c0.7,0,1.2-0.6,1.1-1.3c0-0.7-0.6-1.2-1.3-1.1c-0.1,0-0.2,0-0.2,0
c-0.7,0-1.2,0.6-1.1,1.3C186.9,141.2,187.5,141.7,188.2,141.6z"
      />
      <path
        className="st2"
        d="M180,146.9c0.8-0.1,1.4-0.7,1.4-1.5c-0.1-0.8-0.7-1.4-1.5-1.4c-0.7,0.1-1.4,0.7-1.4,1.5
C178.6,146.3,179.2,146.9,180,146.9z"
      />
      <path
        className="st2"
        d="M145.1,126.6c-0.5,0-0.9,0.4-0.9,0.8c0,0.5,0.4,0.9,0.8,0.9c0.5,0,0.9-0.4,0.9-0.8
C146,127.1,145.6,126.7,145.1,126.6z"
      />
      <path
        className="st2"
        d="M148.4,133.7c0-0.5-0.5-1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1
C147.9,134.8,148.4,134.3,148.4,133.7z"
      />
      <path
        className="st2"
        d="M151.9,141.7c0.7,0,1.2-0.5,1.2-1.1c-0.1-0.8-0.6-1.3-1.2-1.3c-0.7,0-1.2,0.5-1.2,1.2S151.2,141.7,151.9,141.7
z"
      />
      <path
        className="st2"
        d="M160.1,146.9c0.8,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4S159.3,146.9,160.1,146.9z"
      />
      <path
        className="st2"
        d="M170,149.2c0.9,0,1.6-0.7,1.6-1.5c0.1-0.8-0.6-1.5-1.5-1.6c-0.9,0-1.6,0.7-1.6,1.5
C168.5,148.5,169.2,149.2,170,149.2z"
      />
      <path
        className="st2"
        d="M212.8,147.5c-2-1.8-0.2-2.4,0.1-4.3c1.8,0.5,3.2-0.8,3.9,1.8c0.7-6.1-2.8-2.8-5.8-4.9
C211.6,143.7,207.1,145.4,212.8,147.5z"
      />
      <path
        className="st2"
        d="M215.1,144c-0.4,0.5-0.6,1.2-0.6,1.8c-0.6-0.3-1.3-0.4-1.9-0.3c0.8,1,2.7,4,3.8,3.3
C217.5,148.1,215.6,145.2,215.1,144z"
      />
      <path
        className="st2"
        d="M217.7,149.5c0,0,0.5,0.1,0.7-0.7c0.3-1.5,0.9-3,1.6-4.4c1-1.5,0.4-2.4-0.4-2.6c-0.8-0.2-1.8,0.3-1.6,2.1
c0,1.6-0.2,3.2-0.6,4.7C217.2,149.4,217.7,149.5,217.7,149.5z"
      />
      <path
        className="st2"
        d="M208.7,148.5c-0.2,0.8,0.3,1.7,2.1,1.6c1.6,0,3.2,0.2,4.7,0.6c0.8,0.2,0.9-0.4,0.9-0.4s0.1-0.5-0.7-0.7
c-1.5-0.3-3-0.9-4.4-1.6C209.8,147.1,208.9,147.7,208.7,148.5z"
      />
      <path
        className="st2"
        d="M220.6,145.9c1.8-2,2.4-0.2,4.3,0.1c-0.5,1.9,0.8,3.2-1.8,3.9c6,0.6,2.8-2.8,4.8-5.8
C224.3,144.6,222.7,140.1,220.6,145.9z"
      />
      <path
        className="st2"
        d="M219.3,149.6c0.7,1.1,3.6-0.8,4.8-1.3c-0.5-0.4-1.2-0.6-1.8-0.6c0.3-0.6,0.4-1.3,0.3-1.9
C221.6,146.6,218.6,148.5,219.3,149.6z"
      />
      <path
        className="st2"
        d="M219.3,151.5c1.5,0.3,3,0.9,4.4,1.6c1.5,0.9,2.4,0.4,2.6-0.4c0.2-0.8-0.3-1.7-2.1-1.6c-1.6,0-3.2-0.2-4.7-0.6
c-0.8-0.2-0.9,0.3-0.9,0.3S218.5,151.3,219.3,151.5z"
      />
      <path
        className="st2"
        d="M207.1,157c3.6-0.5,5.2,4,7.3-1.8c-1.9,2-2.6,0.2-4.4-0.1c0.6-1.8-0.7-3.2,2-3.9
C205.9,150.5,209.1,154,207.1,157z"
      />
      <path
        className="st2"
        d="M212.3,155.4c1-0.8,4-2.6,3.3-3.7c-0.7-1.1-3.6,0.7-4.8,1.2c0.5,0.4,1.2,0.6,1.8,0.6
C212.3,154.1,212.2,154.7,212.3,155.4z"
      />
      <path
        className="st2"
        d="M216.5,152.3c-0.3,1.5-0.9,3-1.6,4.4c-0.9,1.5-0.3,2.4,0.5,2.6c0.8,0.2,1.7-0.3,1.5-2.1c0-1.6,0.2-3.1,0.6-4.7
c0.2-0.8-0.3-0.9-0.3-0.9S216.7,151.5,216.5,152.3z"
      />
      <path
        className="st2"
        d="M223.9,161c-0.5-3.6,3.9-5.2-1.8-7.4c2.1,1.9,0.3,2.5-0.1,4.4c-1.8-0.5-3.2,0.8-3.9-1.9
C217.4,162.2,220.9,159,223.9,161z"
      />
      <path
        className="st2"
        d="M219.8,157.2c0.4-0.5,0.6-1.2,0.6-1.8c0.6,0.3,1.2,0.4,1.9,0.3c-0.8-1-2.6-4-3.7-3.3
C217.5,153.1,219.3,156,219.8,157.2z"
      />
      <path
        className="st2"
        d="M122,156.2c-0.7,2.6-2.1,1.2-3.9,1.8c-0.3-1.9-2.1-2.5-0.1-4.3c-5.8,2.2-1.3,3.8-1.8,7.3
C119.2,159,122.7,162.3,122,156.2z"
      />
      <path
        className="st2"
        d="M117.9,155.7c0.6,0.1,1.3,0,1.9-0.3c0,0.6,0.2,1.3,0.6,1.8c0.5-1.2,2.4-4.1,1.3-4.8
C120.6,151.7,118.7,154.7,117.9,155.7z"
      />
      <path
        className="st2"
        d="M123,151.6c0,0-0.5,0.1-0.3,0.9c0.4,1.5,0.6,3.1,0.6,4.7c-0.1,1.8,0.8,2.3,1.6,2.1c0.8-0.2,1.3-1.1,0.4-2.6
c-0.7-1.4-1.3-2.9-1.6-4.4C123.5,151.5,123,151.6,123,151.6z"
      />
      <path
        className="st2"
        d="M120.7,150.5c-1.5,0.4-3.1,0.6-4.7,0.6c-1.8-0.2-2.3,0.8-2.1,1.6c0.2,0.8,1.1,1.4,2.6,0.4
c1.4-0.7,2.9-1.3,4.4-1.6c0.8-0.2,0.7-0.7,0.7-0.7S121.5,150.3,120.7,150.5z"
      />
      <path
        className="st2"
        d="M133.2,157c-2.1-3,1.2-6.5-4.9-5.8c2.5,0.7,1.2,2.1,1.8,3.9c-1.9,0.3-2.5,2.1-4.3,0.1
C128,161,129.6,156.5,133.2,157z"
      />
      <path
        className="st2"
        d="M124.6,151.6c-0.7,1.1,2.3,2.9,3.3,3.8c0.1-0.6,0-1.3-0.3-1.9c0.6,0,1.3-0.2,1.8-0.6
C128.2,152.4,125.3,150.5,124.6,151.6z"
      />
      <path
        className="st2"
        d="M128.9,148c-1.4,0.7-2.9,1.3-4.4,1.6c-0.8,0.2-0.7,0.7-0.7,0.7s0.1,0.5,0.9,0.3c1.5-0.4,3.1-0.6,4.7-0.6
c1.8,0.2,2.3-0.8,2.1-1.6C131.2,147.6,130.3,147.1,128.9,148z"
      />
      <path
        className="st2"
        d="M112.3,144.1c2,3-1.2,6.5,4.9,5.8c-2.7-0.7-1.3-2.1-1.9-3.9c1.9-0.3,2.6-2.1,4.4-0.1
C117.4,140.1,115.8,144.6,112.3,144.1z"
      />
      <path
        className="st2"
        d="M117.9,147.6c-0.6,0-1.3,0.2-1.8,0.6c1.2,0.5,4.1,2.3,4.8,1.2c0.7-1.1-2.3-2.9-3.3-3.7
C117.5,146.4,117.6,147,117.9,147.6z"
      />
      <path
        className="st2"
        d="M122.2,143.9c0.1-1.8-0.9-2.2-1.6-2c-0.7,0.2-1.4,1.1-0.4,2.6c0.7,1.4,1.3,2.9,1.6,4.4
c0.2,0.7,0.7,0.6,0.7,0.6s0.5-0.1,0.3-0.9C122.4,147,122.2,145.5,122.2,143.9z"
      />
      <path
        className="st2"
        d="M123.4,145.1c0.7-2.7,2.1-1.3,3.9-1.9c0.3,1.8,2.1,2.5,0.1,4.4c5.8-2.2,1.3-3.8,1.8-7.3
C126.2,142.2,122.7,139,123.4,145.1z"
      />
      <path
        className="st2"
        d="M127.6,145.5c-0.7-0.1-1.3,0-1.9,0.3c0-0.6-0.2-1.3-0.6-1.8c-0.5,1.2-2.3,4.1-1.2,4.8
C125,149.5,126.8,146.5,127.6,145.5z"
      />
      <path
        className="st2"
        d="M196.6,106.5c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.5c0-1-0.7-1.7-1.6-1.7S196.6,105.6,196.6,106.5z"
      />
      <path
        className="st2"
        d="M189.9,85.5c1.5,0,3-0.7,3.9-1.9c0-2.2-2.3-3.4-4.1-3.6C191.4,81.4,191.2,83.9,189.9,85.5z"
      />
      <path
        className="st2"
        d="M164.2,85.7c1.5,0.4,3.8-0.6,4.6-0.8c-0.2-3.3-1.9-4.5-4.5-4.9c0.3,0.2,0.5,0.4,0.7,0.7
C166.2,82.3,165.8,84.5,164.2,85.7z"
      />
      <path
        className="st2"
        d="M144.6,105c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.5
C146.2,105.8,145.5,105,144.6,105z"
      />
      <path
        className="st2"
        d="M205.9,23.3c-6.8-4-16.9-5.2-24.6-2.9c-3.3,0.8-6.3,2.7-8.4,5.2c-1.6-0.5-3.4-0.5-5,0h-0.1
c-0.8,0.2-1.6,0.6-2.3,1c-3.3-4.3-10.9-8.7-21.8-5.9l-0.2,0.1c-2.8,0.8-5.6,1.4-8.4,1.7c-1.4-0.1-2.8,0.4-3.7,1.4
c-0.6,0.8-0.7,1.8-0.3,2.7l0.2,0.1c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.4,0.3-0.7,0.7-0.7l2.1,0.1c2.6-0.1,5.1-0.7,9.1-1.8l0.2-0.1
c11.1-2.9,19.2,2,21.7,6.7c0.9-0.8,1.9-1.4,3.1-1.7h0.3c1.7-0.7,3.6-0.5,5.2,0.4c1.7-2.6,4.7-4.5,8.6-5.7c7.5-2.2,17.6-1,24.2,2.9
l1.9,1.1c0.1,0.1,0.2,0.2,0.3,0.4c0,0.2,0,0.4-0.1,0.5c0.3-0.2,0.4-0.4,0.6-0.8c-0.2-1-0.5-2-1.2-2.7
C207.2,24.3,206.6,23.7,205.9,23.3z"
      />
      <path
        className="st2"
        d="M56,203.6v-87.2c0-60.6,51-109.9,113.7-110c62.7-0.1,114,49.3,114,109.9V227c0.2,0.1,0.5,0.2,0.7,0.3
c0.6-1.7,1.3-2.8,2.2-4.4V116.3c-0.1-62-52.3-112.6-116.7-112.8h-0.2C105.4,3.7,53.2,54.2,53.2,116.3v86.3c0.3,0.1,0.6,0.2,0.9,0.3
C54.7,203.1,55.3,203.3,56,203.6z"
      />
      <path
        className="st2"
        d="M136.1,139.8c0.1,1.8,0.1,4.1,0.4,6.7c0.2,1.7,0.4,3.3,0.7,4.9c12.4,20.5,25,16.8,32.9,25.2
c7.1-8.3,24.6-7.2,32.3-24.1c0.4-1.9,0.7-3.9,0.9-6c0.3-2.5,0.4-4.8,0.4-6.7c0-1.2,0.1-2.3,0.2-3.1c-2.6-0.9-5.2-1.9-7.5-3.2
c0.2,0.4,0.3,0.8,0.3,1.3c0.1,1.9-1.4,3.4-3.3,3.5c-1.9,0.1-3.4-1.4-3.5-3.3c0-1.9,1.4-3.5,3.3-3.5c0.1,0,0.2,0,0.4,0
c-0.1-0.1-0.2-0.1-0.3-0.2c-3.7-3.4-5.9-8-6.4-13.9c-0.4,0.3-0.7,0.6-1.1,0.8l-0.1,0.1c-0.3,0.2-0.6,0.4-0.9,0.6
c-0.1,0-0.2,0.1-0.2,0.1c-0.3,0.2-0.6,0.3-0.8,0.5c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0.1-0.5,0.3-0.8,0.4c-0.1,0-0.2,0.1-0.3,0.1
c-0.3,0.1-0.5,0.2-0.8,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.5,0.2-0.8,0.3c-0.1,0-0.2,0.1-0.4,0.1c-0.3,0.1-0.5,0.1-0.8,0.2
c-0.1,0-0.2,0.1-0.4,0.1c-0.3,0.1-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.3,0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.3,0
c-0.3,0-0.6,0-0.9,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6,0-0.9,0c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7,0-1.1-0.1c-0.8-0.1-1.6-0.2-2.4-0.3
c-0.4-0.1-0.7-0.2-1.1-0.2c-0.1,0-0.2,0-0.2-0.1c-0.3-0.1-0.6-0.2-0.9-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.5-0.2-0.8-0.3
c-0.1,0-0.3-0.1-0.4-0.1c-0.3-0.1-0.5-0.2-0.8-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.5-0.2-0.7-0.3c-0.1-0.1-0.3-0.1-0.4-0.2
c-0.2-0.1-0.5-0.2-0.7-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.5-0.2-0.7-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-0.1-0.5-0.3-0.7-0.4
c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-0.1-0.5-0.3-0.7-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-0.2-0.5-0.3-0.7-0.5c-0.1-0.1-0.2-0.2-0.4-0.2
c-0.3-0.2-0.5-0.4-0.8-0.6c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2-0.6-0.5-0.9-0.7l-0.1-0.1c-0.7-0.6-1.4-1.1-2.1-1.8
c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3-0.2-0.5-0.5-0.8-0.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.4-0.3-0.5-0.5c0.6,9-1.4,15.6-6.4,20.2
c-0.2,0.2-0.4,0.4-0.7,0.6c0.2,0,0.4-0.1,0.7-0.1c1.9,0,3.4,1.6,3.3,3.5c-0.1,1.9-1.6,3.3-3.5,3.3c-1.9-0.1-3.3-1.6-3.3-3.5
c0-0.6,0.2-1.1,0.5-1.6c-2.3,1.4-5,2.4-7.7,3.3C136,137.5,136,138.6,136.1,139.8z M189,137.9c2.4-0.1,4.3,1.8,4.4,4.1
c0.1,2.4-1.8,4.3-4.1,4.4c-2.4,0.1-4.3-1.8-4.4-4.1C184.8,139.9,186.7,137.9,189,137.9z M180.8,142.7c2.8-0.1,5.1,2.1,5.2,4.8
c0.1,2.7-2.1,5.1-4.8,5.2c-2.8,0-5.1-2.2-5.2-4.9C175.9,145.1,178,142.8,180.8,142.7z M170.2,144.8c3,0.1,5.4,2.6,5.3,5.5
c0,3-2.5,5.4-5.5,5.3c-3,0-5.3-2.5-5.3-5.5S167.2,144.7,170.2,144.8z M159.2,142.8c2.7,0.1,4.9,2.3,4.8,5.1v0.1
c-0.1,2.7-2.4,4.9-5.2,4.8c-2.7-0.1-4.9-2.4-4.8-5.2C154.2,144.9,156.5,142.7,159.2,142.8z M150.9,138c2.4,0,4.2,2,4.2,4.4
c-0.1,2.4-2,4.3-4.4,4.2c-2.4-0.1-4.3-2-4.2-4.4C146.6,139.8,148.5,137.9,150.9,138z"
      />
      <path
        className="st2"
        d="M169.6,74.7c-2.7-0.3-5.4-0.9-7.9-1.6c-0.8-0.3-1.6-0.4-2.4-0.4c-2.8,1.6-6.2,3-10.2,4.1c0,0-3.1,4.1-2.6,16.7
c0,2.9,0.1,7.1,3.4,11.1c11.9,14.6,27.8,20.7,38.5,9.5c1.8-1.9,4.1-5.7,6.4-8.8c4.1-5.6,5.5-12.8,5.6-19.7c0.1-2.1,0-4.2-0.3-6.2
c-0.3-1.7-1.3-4.7-3.1-5.3c0,0.1,0,0.1,0,0.2c-0.6,0.4-1.2,0.4-1.8,0.1c-1.8-0.7-3.8-0.8-5.6-0.2c-2.1,0.6-4.2,1-6.4,1.2
c-0.6,0-1.1-0.2-1.6-0.5c-0.2-0.5,1.6-1.8,2-1.9c2-0.6,3.6-1.2,5.6-1.4c-5.3-2.6-11-6.9-12.3-10c-0.7-1.8-2.1-3.2-3.9-3.9
c0,0-2,6.8-10.1,12.7c3.4,0.1,6.7,1.1,8.9,3.1c0.3,0.3,0.8,0.7,0.5,0.9C171.2,74.8,170.5,74.9,169.6,74.7z M154,79.4l2.4,0.6
l-0.9-1.6l2.4,0.8c0,0-0.8-1.2-1-1.6c0.5,0.2,1.8,0.7,2.3,0.9c-0.2-0.6-0.4-0.8-0.6-1.5c0.7,0.5,1.3,0.6,1.9,1
c-0.2-0.7-0.3-1.2-0.3-1.5c0.7,0.4,0.9,0.9,1.7,1.1l-0.1-1.1l1.5,1.1l0.1-0.8c0,0,0.9,0.9,1.2,1.1c0.5,0.4,1.1,0.6,1.7,0.8
c2.5,1.2,4.3,3.6,4.7,6.2C164.8,89.1,155.5,87,154,79.4z M189,77.2c0.1,0.2,0,1.2,0,1.2l1.5-1.2l-0.1,1.1l1.6-1l-0.3,1.3l2-1
l-0.4,1.4l1.8-0.8l-0.4,1.4l1.8-0.6l-0.6,1.6l1.6-0.6c-3.2,10.7-11.8,6.1-15.5,5.5C183,79.3,187.3,80.1,189,77.2z M180.8,95.3
c0.9,0,1.7,0.3,1.7,0.8c-0.2,0.7-1,1-1.6,0.8h-0.1c-0.9-0.3-2.3,0.4-2.6,0C177.9,96.5,178.9,95.3,180.8,95.3z M187,103.4
c-0.9,1.5-2,3.2-3,4.8c-2.2,3.6-5.5,4.8-9.3,4.3c-5.2-0.6-7.4-5.4-11-8.4c4.2-0.7,7.9-1.7,11.8-2.1
C179.4,101.5,183.5,102,187,103.4z M173.2,95.3c2,0,3,1.3,2.6,1.6c-0.4,0.3-2-0.3-2.6,0c-0.1,0.1-0.1,0.1-0.2,0.1
c-0.7,0.2-1.3-0.2-1.5-0.8C171.5,95.8,172.2,95.4,173.2,95.3z"
      />
      <path
        className="st2"
        d="M184.4,105c-3.8-0.3-10.7-0.1-16.5,0.1C173.4,109.5,180.4,110,184.4,105z"
      />
      <path
        className="st2"
        d="M252.6,171.8c-0.3,1,0.4,2,1.8,1.4v-0.8c1.1-0.4,2.1-0.5,2.9,0.5c-1.1-3.5-2.5-6.8-4.3-10
c-2.6-4.4-5.9-8.3-9.7-11.7c-1.6-1.5-4.9-3.7-8.9-5.7c-3.6,8.7-4.9,21.2-5,31c0.6-0.8,1.5-1,2.5-0.9c0.1,0.2,0.1,0.4,0.2,0.6
c1,0.3,1.9-0.4,1.4-1.8h-0.8c-0.4-1.2-0.5-2.2,0.6-3c1.3,0.6,1.5,1.6,1.4,2.8l-0.6,0.2c-0.3,1,0.4,2,1.8,1.4v-0.7
c0.9-0.5,2-0.4,2.8,0.3c0.5-0.9,1.6-1.3,2.6-1.1c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.6,0.1,1.2-0.4,1.3-1
c0.1-0.3,0-0.6-0.1-0.9h-1c-0.2-0.4-0.3-0.8-0.3-1.2c0-0.7,0.3-1.4,0.9-1.8c0.9,0.3,1.5,1.3,1.4,2.3c0,0.1,0,0.2,0,0.4l-0.6,0.2
c-0.1,0.1-0.1,0.3-0.1,0.4c-0.1,0.9,0.6,1.6,1.8,1.1v-0.8c0.2-0.1,0.5-0.1,0.8-0.2h0.3h0.1h0.2h0.2h0.2l0.2,0.1l0.1,0.1l0.2,0.1
l0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.5-0.9,1.6-1.3,2.6-1.1c0.1,0.2,0.1,0.4,0.2,0.6c1,0.3,2-0.4,1.4-1.8h-0.7
c-0.4-1.2-0.5-2.2,0.6-3c1.3,0.6,1.5,1.6,1.4,2.8L252.6,171.8z M254.7,169.6l0.1,0.9l0.8-0.1c0.4,1-0.2,1.4-1.8,1.3
C253.5,170.1,253.7,169.6,254.7,169.6z M229.7,173.7l0.9-0.1l-0.1-0.8c1-0.3,1.4,0.3,1.4,1.9C230.3,175,229.7,174.8,229.7,173.7z
M235.2,174.1c-0.4-1.6-0.2-2.1,0.9-2.1l0.1,0.9l0.8-0.1C237.3,173.8,236.8,174.3,235.2,174.1z M239.2,172.5l0.8-0.1l-0.1-0.8
c1-0.4,1.4,0.2,1.4,1.8C239.7,173.8,239.2,173.6,239.2,172.5z M244.4,173c0-0.3-0.1-0.7-0.1-1c0-0.8,0.3-1.1,1.1-1.1v0.8l0.8-0.1
c0.1,0.2,0.1,0.4,0.1,0.6C246.3,172.8,245.6,173,244.4,173z M248.5,171.3l0.9-0.1l-0.1-0.8c1-0.4,1.4,0.2,1.4,1.8
C249,172.6,248.5,172.3,248.5,171.3z"
      />
      <path
        className="st2"
        d="M271.7,227.9c0.2-0.7,0.4-1.4,0.6-2.1c1.3-5.1,1.8-10.8-0.7-16.8c-0.9-1.8-1.9-3.6-3.2-5.2
c-1.4-1.8-2.9-3.6-4.5-5.2c-1.5-1.6-2.7-3.5-3.6-5.5c-0.7-2.1-1-4.3-0.9-6.5c0-0.7-0.1-1.4-0.1-1.9c-0.2-2.5-0.5-5-1-7.4
c-0.3-1.5-0.7-2.9-1.1-4.4c-0.6,1.1-1.7,1.3-2.7,1.2c-0.1-0.2-0.1-0.4-0.2-0.6c-1.1-0.3-2,0.4-1.5,1.8h0.8c0.4,1.2,0.5,2.2-0.6,3
c-1.3-0.6-1.5-1.6-1.4-2.8l0.6-0.2c0.3-1-0.4-2-1.8-1.4v0.8c-0.9,0.5-2,0.4-2.7-0.3c-0.5,0.9-1.6,1.3-2.6,1.1
c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.2-0.1-0.3-0.1c-0.6-0.1-1.2,0.4-1.3,1c-0.1,0.3,0,0.6,0.1,0.9l0.8,0.1
c0.2,0.4,0.3,0.8,0.3,1.2c0,0.7-0.3,1.3-0.9,1.7c-0.9-0.3-1.5-1.3-1.4-2.3c0-0.1,0-0.3,0-0.4l0.6-0.2c0.1-0.1,0.1-0.3,0.1-0.4
c0-0.9-0.7-1.6-1.9-1.1v0.8c-0.2,0.1-0.5,0.1-0.8,0.2c-0.2,0-0.3,0-0.4,0h-0.2h-0.2h-0.2l-0.2-0.1l-0.1-0.1l-0.2-0.1l-0.1-0.1
c-0.1-0.1-0.1-0.1-0.2-0.2c-0.5,0.9-1.6,1.3-2.6,1.1c-0.1-0.2-0.1-0.4-0.2-0.6c-1-0.3-1.9,0.4-1.4,1.8h0.8c0.4,1.2,0.5,2.2-0.6,3
c-1.3-0.6-1.5-1.6-1.4-2.8l0.6-0.2c0.3-1-0.4-2-1.8-1.4v0.8c-1,0.3-1.9,0.5-2.7-0.3c0,0.5,0,0.9,0,1.3c0,4.9,0.4,9.7,1.1,14.6
c0.4,2.6,0.7,5.3,0.8,7.9c1.2,0.2,2.3,0.7,3.3,1.3c2.7,1.9,4.4,4.8,4.8,7.9c2.7,0.4,5.9,2,7.4,4.1c1.7,2.2,2.6,4.9,2.7,7.5
c0,0.2,0,0.4,0,0.6c0.6,0.8,1.1,1.7,1.5,2.7c2.7-0.2,6.2,0.5,8.2,2.1c1.6,1.3,2.9,2.9,3.7,4.8C265.5,230.2,268.6,228.8,271.7,227.9
z M231.4,180l-0.1-0.9l-0.8,0.1c-0.4-1,0.2-1.5,1.8-1.4C232.7,179.5,232.5,180,231.4,180z M236.9,179.2c-1,0.4-1.4-0.2-1.4-1.8
c1.7-0.4,2.2-0.2,2.2,0.9l-0.9,0.1L236.9,179.2z M240.7,178.7l-0.1-0.9l-0.8,0.1c-0.1-0.2-0.1-0.4-0.1-0.6c0.1-0.6,0.8-0.8,2-0.8
c0,0.4,0.1,0.7,0.1,1.1C241.8,178.4,241.5,178.7,240.7,178.7z M246.1,177.2l0.1,0.8c-1,0.4-1.4-0.2-1.4-1.8
c1.7-0.4,2.2-0.2,2.2,0.9L246.1,177.2z M250.1,177.5l-0.1-0.9l-0.8,0.1c-0.3-1,0.2-1.5,1.8-1.3
C251.4,177.1,251.2,177.5,250.1,177.5z M255.6,176.9c-1,0.3-1.4-0.3-1.4-1.9c1.6-0.4,2.1-0.2,2.2,1l-0.9,0.1L255.6,176.9z"
      />
      <path
        className="st2"
        d="M106.5,174.6c-0.6,1.4,0.4,2.1,1.4,1.8l0.2-0.6c1-0.1,1.9,0.1,2.5,0.9c-0.1-9.8-1.3-22.3-4.9-30.9
c-4.1,2.1-7.1,4.1-8.8,5.6c-3.9,3.3-7.1,7.2-9.7,11.7c-1.7,3-3.1,6.2-4.2,9.5c0.7-0.5,1.5-0.4,2.5-0.1v0.8c1.4,0.6,2.1-0.4,1.8-1.4
l-0.6-0.2c-0.1-1.2,0.1-2.2,1.4-2.8c1.1,0.8,1,1.8,0.6,3h-0.8c-0.6,1.4,0.4,2.1,1.4,1.8c0.1-0.2,0.1-0.4,0.2-0.6
c1-0.2,2.1,0.2,2.6,1.1c0.1-0.1,0.1-0.1,0.2-0.2l0.1-0.1l0.2-0.1l0.1-0.1l0.2-0.1H93h0.2h0.2h0.1h0.4c0.3,0.1,0.6,0.1,0.8,0.2v0.8
c1.2,0.5,1.9-0.2,1.9-1.1c0-0.1,0-0.3-0.1-0.4l-0.6-0.2c0-0.2,0-0.3,0-0.4c-0.1-1,0.5-2,1.4-2.3c0.6,0.4,0.9,1.1,0.9,1.8
c0,0.4-0.1,0.8-0.3,1.2h-0.8c-0.1,0.3-0.2,0.6-0.1,0.9c0.1,0.6,0.7,1.1,1.3,1c0.1,0,0.2,0,0.3-0.1c0.1-0.2,0.1-0.4,0.2-0.6
c1-0.2,2.1,0.2,2.6,1.1c0.7-0.7,1.8-0.8,2.7-0.3l0.1,0.8c1.4,0.6,2.1-0.4,1.8-1.4l-0.6-0.2c-0.1-1.2,0.1-2.2,1.4-2.8
c1.1,0.8,1,1.8,0.6,3H106.5z M109.3,172.9l-0.1,0.8l0.9,0.1c-0.1,1.2-0.6,1.4-2.2,1C107.9,173.2,108.3,172.6,109.3,172.9z
M86,171.9c-1.6,0.1-2.2-0.3-1.8-1.3l0.8,0.1l0.1-0.9C86.2,169.8,86.4,170.3,86,171.9z M89.1,172.4c0-1.6,0.4-2.2,1.4-1.8l-0.1,0.8
l0.9,0.1C91.3,172.5,90.8,172.8,89.1,172.4z M95.4,173.2c-1.2,0-1.9-0.2-1.9-0.8c0-0.2,0-0.4,0.1-0.6l0.8,0.1l0.1-0.9
c0.8,0,1.1,0.3,1.1,1.1C95.5,172.5,95.4,172.9,95.4,173.2z M98.6,173.6c0-1.6,0.4-2.2,1.4-1.8l-0.1,0.8l0.8,0.1
C100.7,173.8,100.2,174,98.6,173.6z M104.6,174.3c-1.6,0.2-2.1-0.3-1.8-1.3l0.8,0.1l0.1-0.9C104.8,172.2,105,172.7,104.6,174.3z"
      />
      <path
        className="st2"
        d="M92.9,216.8c0.5-0.7,0.9-1.4,1.3-2.1c3-4.8,6.6-6.4,12-5.4c0.6,0.2,1.3,0.2,1.9,0.1c0.2-0.1,0.4-0.3,0.7-0.5
c-0.1-1.4-0.2-3-0.2-4.7c0-3.7,0.3-7.4,0.9-11c0.7-4.9,1.1-9.7,1.1-14.6c0-0.4,0-0.8,0-1.1c-0.7,0.7-1.6,0.6-2.7,0.3l-0.1-0.8
c-1.4-0.6-2.1,0.4-1.8,1.4l0.6,0.2c0.1,1.2-0.1,2.2-1.4,2.8c-1.1-0.8-1-1.8-0.6-3h0.7c0.6-1.4-0.4-2.1-1.4-1.8
c-0.1,0.2-0.1,0.4-0.2,0.6c-1,0.2-2.1-0.2-2.6-1.1c-0.1,0.1-0.1,0.1-0.2,0.2l-0.1,0.1l-0.2,0.1l-0.1,0.1l-0.2,0.1h-0.2H100h-0.4
h-0.4c-0.3-0.1-0.6-0.1-0.8-0.2v-0.8c-1.2-0.5-1.9,0.2-1.8,1.1c0,0.1,0,0.3,0.1,0.4l0.6,0.2c0,0.1,0,0.3,0,0.4c0.1,1-0.5,2-1.4,2.3
c-0.6-0.4-0.9-1-0.9-1.7c0-0.4,0.1-0.8,0.3-1.2l0.8-0.1c0.1-0.3,0.2-0.6,0.1-0.9c-0.1-0.6-0.7-1.1-1.3-1c-0.1,0-0.2,0-0.3,0.1
c-0.1,0.2-0.1,0.4-0.2,0.6c-1,0.2-2.1-0.2-2.6-1.1c-0.8,0.7-1.9,0.8-2.8,0.3v-0.8c-1.4-0.6-2.1,0.4-1.8,1.4l0.6,0.2
c0.1,1.2-0.1,2.2-1.4,2.8c-1.1-0.8-1-1.8-0.6-3h0.8c0.6-1.4-0.4-2.1-1.4-1.8c-0.1,0.2-0.1,0.4-0.2,0.6c-0.9,0.1-1.8-0.1-2.4-1
c-0.4,1.4-0.8,2.8-1.1,4.2c-0.5,2.5-0.8,5-1,7.4c-0.1,0.6-0.1,1.2-0.1,1.9c0.1,2.2-0.2,4.4-0.9,6.5c-0.8,2-2,3.9-3.6,5.5
c-2.1,2.3-3.8,4.3-4.5,5.2c-0.2,0.3-0.5,0.6-0.7,0.9c-1,1.5-1.7,3.3-2.4,4.9c-0.1,0.2-0.2,0.5-0.3,0.7c6.2,5,11.5,12,14.6,20.3
c0-0.1,0-0.1,0-0.2c-0.1-0.7-0.2-1.4-0.1-2.1C83.1,222.2,87.2,218.6,92.9,216.8z M107.5,177.9c1.6,0,2.2,0.5,1.8,1.4l-0.8-0.1
l-0.1,0.9C107.3,180.1,107.1,179.6,107.5,177.9z M104.3,177.5c0,1.6-0.4,2.2-1.4,1.8l0.1-0.8l-0.9-0.1
C102.1,177.5,102.6,177.3,104.3,177.5z M98.1,176.8c1.2,0,1.9,0.2,1.9,0.8c0,0.2,0,0.4-0.1,0.6l-0.8-0.1L99,179
c-0.8,0-1.1-0.3-1.1-1.1C98,177.5,98,177.1,98.1,176.8z M94.9,176.4c0,1.6-0.4,2.2-1.4,1.8l0.1-0.8l-0.8-0.1
C92.8,176.2,93.3,176,94.9,176.4z M88.8,175.7c1.6-0.2,2.1,0.3,1.8,1.3l-0.8-0.1l-0.1,0.9C88.6,177.7,88.4,177.3,88.8,175.7z
M85.6,175.2c0,1.6-0.4,2.2-1.4,1.9l0.1-0.8l-0.9-0.1C83.4,175,84,174.8,85.6,175.2z"
      />
      <path
        className="st2"
        d="M106.8,263.3c-1.6-0.6-4.9,0.1-8.2,2.2c-2.3,4.6-4,9.6-6.3,14.5c5.2,5.8,10.8,1.4,13.1-4.8
C108.7,269.4,109.3,264.2,106.8,263.3z"
      />
      <path
        className="st2"
        d="M315,253.4c5.3-4.1-5.5-24-10.3-19.5C300.2,236.4,308.8,258.4,315,253.4z"
      />
      <path
        className="st2"
        d="M47.3,231c-7.1-2-18.6-3.4-25.2,4.7c-6.1,6.7-2,15.6,3.7,19.1c6.4-11.2,14.5-29.3,26-14.6
c-8.9,7.9-12.1,23.5-21.8,30.3c3.9,3.8,9.8,7.6,12.3,7.7C56,277.6,69.7,235.7,47.3,231z"
      />
      <path
        className="st2"
        d="M317.7,213.2c5.3,0,9.6-4.3,9.6-9.8c-0.1-5.4-4.5-9.7-9.8-9.6c-5.4,0.1-9.7,4.4-9.6,9.8
C308,209,312.3,213.2,317.7,213.2z M312.8,197.9c1.4-0.3,3-0.4,4.5-0.4c1.1-0.1,2.1,0.1,3.1,0.4c0.9,0.3,1.5,0.9,1.9,1.7
c0.2,0.4,0.3,0.9,0.3,1.3c0,1.3-0.9,2.2-2,2.6l-0.4,0.1c0,0-0.1,0,0,0.1c0.1,0.1,0.3,0.1,0.4,0.2c1.1,0.6,1.6,1.6,1.5,2.8
c0.2,1,0.5,2,0.9,2.9l-2.8,0c-0.5-0.9-0.8-1.9-1-2.9c-0.2-1.3-0.9-1.8-2.4-1.8h-1.3v4.7h-2.7V197.9z"
      />
      <path
        className="st2"
        d="M319.9,201.4c0-1.1-0.8-1.9-2.5-1.9c-0.6-0.1-1.1,0-1.5,0.1v3.4h1.3C318.6,203,319.9,202.5,319.9,201.4z"
      />
      <path
        className="st2"
        d="M332.3,259.5c-1-0.6-2.2-1-3.4-1c11.1-12.1,7.5-35.7-5.4-44.4c-1.8,1-3.8,1.6-6,1.6c-3.6,0-6.9-1.6-9.2-4.1
c-10.3,2.9-19.1,14.3-20.1,24.8c-4.7-8.3-22.1-3.1-26.1,5.3c-0.7,2-1.4,4-2,5.9c-17.6-12.7-37.3-2.7-46.8,12.7
c-3.8-15.9-28.6-13.6-35.4-0.4c-3-10-18.7-12.7-24.8-5.4c-2.7-4.2-15.9-8.6-22.6-1.2c0,0-1.7,4.5-2.2,5.8
c-1.2-10.8-13.3-15.3-22.6-11.8c-5.9-5.7-17.8-7.1-21.3-1.1c0,0-0.5,1.4-2.8,7.3c6.5-44-66.4-66.9-77.1-22
c-1.5,8.7,0.4,20.6,9.4,25.1c-5.9,5.5-8.8,14.4-3.3,20.3c12.8,13.7,30,24.4,46.8,31.3c-2,9.9,20.7,19.2,24.6,8.4
c68.8,19.6,172.7,13.2,229.7-26C324.3,281.6,343.7,270,332.3,259.5z M271.4,280.7c0,2.5-11.6,6.9-11.7,3.1c0-1.5,0-2.9,0-4.4
c-5.4,7.8-19.7,14.5-20.2-0.5c-1.8,5.2-5.2,11.9-12.9,13.2c-4.7,0.9-10.9,0-12.7-7.7c-18.4,23.1-47,7.8-21.4-13.8
c3.8-4.8-1.1-9.5-6.9-1.7c-2.5,3.2-6.2,10.3-7.6,13.1c-6.2,12.3-12.4,16-19.1,14.8c-4.5-0.9-7-4.8-5.5-10.7
c-6.8,12.8-28.5,14.4-26.2-1.5c-4.5,3.5-10.4,4.6-15.9,2.9c-6.5,4.6-16,4.5-21.9-0.6l-4.7,10c58.7,16.3,146.2,13.8,198.5-8.5
c31.1-12.9,44.5-25.7,44.4-20.8c-48.3,54.7-175.2,60.4-247.8,39.7l-1.9,4.6c-2.1,3.3-14-1.4-13.1-5.1l1.8-3.6
c-17.7-5.7-36.6-16-49.7-29.2c-5-7.5,5.3-10.8,8.5-18.6c-29.1-10.2-7.1-45.6,26.5-34.3c30,6.9,32.3,52.4,3.9,65.4
c5.3,2.5,10.6,4.7,16.1,6.6c6.9-13.2,13.1-26.9,18.6-40.8c0.8-1.5,11.1-0.6,12.3,3l-1.7,4.1c20.8-13.8,30,7.6,14.3,24.5
c14,1.8,16.4-15.1,20.3-24.6c1.6-2.1,12.7-1.2,13.1,1.7c-1.4,4.4-3.6,11.3-5.1,16.8c-6.5,17.3,5.1,9.7,8.8-1
c1.9-5.2,4.1-11.5,5.8-16.6c1.6-1.8,11.2-1.3,13.1,1.8c-2.4,8.9-9.8,27.5-6.2,27.4c12.8-4.5,13.4-31.7,30.9-31.6
c7.3-0.4,11.1,3.3,11.5,8.3c0.5,6.8-5.6,11.1-9.2,14.1c-4.6,3.9-3.4,8.5,1,8.1c3.9-0.4,10.2-2.8,14.3-11.5
c3.6-20,23.4-31.1,40.8-22.5c1.5,1.4-1.2,7.1-3.1,6.6c-10.3-4.8-21.7,1.1-23.7,12.6c-1,5.3-0.5,8.8,1,9.8
c4.6,2.9,9.9-13.1,11.1-19.6c2.4-2.4,9.5-1.5,10.5,0.7c-1.8,13.3,1.6,22.9,10.4,4.5c1.2-7.4,3.1-14.5,5.8-21.4
c1.3-3.2,14.9-7,15.3-3.8c2.1,22.5,8.2,35.9,15.8,15.4c-18.8-23.4,18.6-54.7,29.3-21.8c5.5,13.9-12.1,35.7-25.9,25.5
c-6.2,20.1-25.9,22.5-27.7-4.6H273C271.7,265.6,271.1,273.1,271.4,280.7z"
      />
      <path
        className="st2"
        d="M186.9,217.8c-10.1-8.7-17.5-12.6-27.1-13c-6.3-0.2-12.3,0.9-18.8,3.5c0.7,0.8,1.3,1.6,1.8,2.6
c2.7-0.5,6.2-0.1,8.4,1.4c2.1,1.5,3.7,3.5,4.8,5.9c0.1-0.1,0.2-0.1,0.2-0.2c5.5-3.5,12-3.2,16.6,1.7c0.3,0.3,0.6,0.6,0.9,0.9
c1.2-0.1,2.3-0.3,3.4-0.6c2.2-0.6,4.6-0.3,6.6,0.7c3,1.6,5.2,4.4,6,7.7c0.2,0,0.5,0,0.7,0.1c3.6-1.9,5.7-4.6,4.8-8.5
c-0.3-1,0.7-6.2-3.1-10.2c-1.4-1.5-4.4-2.4-6.4-2.4c-0.9,0-1.7-0.5-2-1.2c-0.7-4.1-4.2-8.1-8.9-7c-4.7,1.1-5,0.7-6.8-1.2
c-3.3-3.6-8.1-4-12.4-1.3c-2.7,1.6-3.5,1.9-6.4,0.7c-3.8-1.7-6.6-1.5-10,2c-1,1-1.8,2.4-3.4,2.5c-3.6,0.2-7.1,1.4-9.2,4.8
c0.9-0.3,1.8-0.6,2.7-1.1c1.5-0.7,3.1-1,4.7-0.9c0.5-0.1,1-0.2,1.6-0.2c2.1,0,4-0.9,5.4-2.4c2.4-2.2,3.6-4.4,9.2-1.7
c1.7,0.8,4,0.4,6.1-0.8c4.9-3.2,7.7-1.4,9.9,0.9c1.6,1.4,3.8,2.1,5.9,1.8c6.9-1.9,7,2.5,9,5.7s6.1,1,8.2,4.1c0.8,1.2,1.1,3.8,1,5.6
C190.3,219.2,189.4,219.9,186.9,217.8z"
      />
      <path
        className="st2"
        d="M241.4,218.2c0,0,0,0.1,0.1,0.2c1.1-0.1,2.3,0.1,3.4,0.5c0.8,0.3,1.5,0.7,2.2,1.2c-0.3-1.2-0.9-2.4-1.8-3.7
c-1.2-1.6-4.1-2.9-6.1-3.1c-0.9-0.1-1.6-0.6-1.9-1.5c-0.3-4.1-3.4-8.5-8.1-7.9c-4.7,0.6-5.1,0.2-6.6-1.9c-2.9-4-7.6-4.8-12.2-2.6
c-2.8,1.4-3.7,1.6-6.4,0c-3.6-2-6.4-2.2-10.2,0.9c-1.1,0.9-2.1,2.2-3.7,2.2c-1.5,0-3,0.1-4.4,0.4c0.3,0.6,0.5,1.3,0.7,2
c0.8,0,1.9,0.2,3,0.5c-0.3-0.1-0.6-0.2-0.8-0.2c0.5,0,1-0.1,1.6,0c2,0.2,4-0.5,5.5-1.8c2.5-1.9,4-3.9,9.1-0.7c1.6,1,3.9,0.8,6-0.1
c5.1-2.6,7.6-0.5,9.5,1.9c1.4,1.6,3.4,2.5,5.5,2.4c6.8-1.1,6.6,3.2,8.1,6.5S239.7,215,241.4,218.2z"
      />
      <path
        className="st2"
        d="M200.5,227c0.6-0.6,1.1-1.3,1.5-2c3.4-4.6,7.1-5.8,12.5-4.3c0.6,0.2,1.2,0.3,1.9,0.3c0.7-0.3,1.4-0.8,1.9-1.3
c4.7-3.9,10.6-4.5,15.4-0.9c-6.8-6.3-12.7-9.6-20.2-10.8c-6-0.9-11.8-0.4-18.3,1.3c1.3,1.8,2.2,3.9,2.5,6.1
c0.1,0.9,0.2,1.9,0.2,2.9v1.3c0.5,1.9,0.3,3.9-0.4,5.7c-0.7,1.6-1.8,2.9-3.2,4c0.1,0,0.1,0,0.2,0.1
C196.2,228.3,198.2,227.5,200.5,227z"
      />
      <path
        className="st2"
        d="M225.4,229.3c-10.3,1-19.2,5.7-30.3,14.7c0.2,0,0.5,0,0.7,0c8.6-0.6,14.5,2.9,18.1,7.5
c4.7-5.8,10.4-10.3,16.3-12.4c4.4-1.6,14.8-4.3,24.9-0.3C243.8,231.6,234.9,228.3,225.4,229.3z"
      />
      <path
        className="st2"
        d="M256.3,239.4c0.3,0.1,0.7,0.3,1,0.4c0-0.2,0.1-0.3,0.1-0.4c0.8-2.3,2.1-4.2,3.8-5.8c-0.6-1.4-1.6-2.8-3.1-4
c-1.6-1.3-4.7-1.8-6.6-1.5c-0.9,0.2-1.7-0.2-2.2-1c-1.3-4-5.3-7.4-9.8-5.7s-4.9,1.4-6.9-0.2c-3.8-3.1-8.6-2.8-12.5,0.5
c-2.4,2-3.2,2.4-6.2,1.6c-4-1.1-6.7-0.6-9.6,3.3c-0.8,1.1-1.5,2.7-3.1,3c-4.3,0.9-8.3,3.1-9.2,8.7c-0.2,1.3,0.4,2.9-0.4,4
c-0.5,0.8-1,1.7-1.3,2.6c1.5-0.4,3-0.7,4.6-0.8c1.4-5.2,0.2-10.8,6.7-12c2-0.3,3.8-1.4,4.9-3.1c2-2.4,3-4.8,8.8-2.9
c1.9,0.6,4-0.1,5.9-1.6c4.4-3.8,7.5-2.4,9.9-0.5c1.7,1.2,4,1.6,6,1c6.5-2.8,7.2,1.5,9.6,4.4c2.4,2.9,6.1,0.1,8.6,2.9
c1.2,1.6,2,3.6,2.2,5.6c0,0.3,0,0.6-0.2,0.9C257.1,239.2,256.7,239.4,256.3,239.4z"
      />
      <path
        className="st2"
        d="M129.9,237.4c1.3-4.3,1.9-8.2,7.4-8.4c2.1-0.1,4.1-1,5.5-2.6c2.4-2.2,3.7-4.6,9.5-1.8c1.8,0.8,4.2,0.3,6.3-0.9
c5.1-3.4,8.1-1.5,10.3,0.8c1.7,1.5,3.9,2.2,6.1,1.8c7-2,7.3,2.5,9.3,5.8c1.3,2.2,3.8,1.9,6,2.4c0.5-1.1,1.1-2.1,1.9-3
c-0.9-0.2-1.9-0.4-2.7-0.4c-0.9,0.1-1.8-0.5-2.1-1.3c-0.8-4.3-4.5-8.4-9.4-7.2c-4.9,1.2-5.3,0.8-7.1-1.1c-3.5-3.8-8.5-4.1-13-1.2
c-2.8,1.8-3.6,2.1-6.6,0.8c-4-1.7-6.9-1.5-10.4,2.2c-1,1.1-1.9,2.5-3.6,2.7c-4.6,0.4-9,2.1-10.7,7.8c-0.4,1.3,0,3.1-1,4.1
c-1.4,1.6-2.4,3.6-2.3,5.5c2.2,1.2,4.1,2.9,5.7,5c6.2-6,17.2-3.5,18.5-3.1c2.2,0.5,4.3,1.4,6.1,2.7c3-2,6.9-2.8,11.7-2.4
c3.6,0.3,9.7,1.6,13.6,6.2c2.4-2.5,5.3-4.6,8.5-5.9c0.2-1.7,0.9-3.4,1.9-4.9c0-0.2,0-0.5,0-0.7c-9-7.4-16.7-10.8-26-11.4
c-10-0.7-20.1,2.9-31.5,9.6l-0.5,0.3c-0.3,0.2-0.5,0.4-0.8,0.3c-0.6-0.2-0.9-0.8-0.7-1.4L129.9,237.4z"
      />
      <path
        className="st2"
        d="M95.8,237.2c2.9,0.3,7.8,1.3,11.7,4.5c3.7-0.9,7.6-0.9,11.3,0c0.5,0.1,1.1,0.3,1.6,0.5c0.2-2,1.2-4.1,2.7-5.8
c0.1-0.3,0.1-0.7,0.1-1c0-0.7,0.1-1.4,0.3-2.1c1.9-6.4,6.8-9.3,13.1-9.8c0.3,0,1.6-1.7,1.8-1.9c0.4-0.4,0.8-0.8,1.2-1.2
c-8.4-3.8-15.3-4.7-22.8-3.3c-10.3,1.9-18.9,7.5-29.4,17.7c1.2-5.4-0.7-11.1,5.7-12.7c2-0.5,3.7-1.8,4.7-3.6
c1.8-2.6,2.6-5.1,8.6-3.7c1.9,0.5,4-0.5,5.8-2.1c4.1-4.3,7.3-3.1,9.9-1.4c1.8,1.1,4.1,1.3,6.1,0.5c6.2-3.4,7.4,0.9,10,3.6
c2.6,2.7,6.1-0.3,8.9,2.2c1.5,0.1,3.2,0.5,5,1.2c0.4,0.2,0.9,0.4,1.3,0.4c-0.7-1.6-1.9-3.2-3.8-4.6c-1.7-1.1-4.8-1.4-6.8-0.9
c-0.8,0.2-1.7-0.1-2.3-0.8c-1.6-3.8-6-6.9-10.3-4.8c-4.3,2.1-4.8,1.9-6.9,0.4c-4-2.8-8.8-2.1-12.4,1.5c-2.2,2.2-3,2.7-6.1,2.1
c-4.1-0.8-6.8,0-9.3,4.2c-0.7,1.2-1.2,2.8-2.8,3.3c-4.2,1.3-8,3.8-8.4,9.5c-0.1,1.3,0.6,2.9-0.1,4.1c0,0.2-0.2,0.5-0.3,0.8
c0.5,1.6,0.9,3.3,1.3,5.1C87.9,237.4,91.5,236.7,95.8,237.2z"
      />
    </g>
  </svg>
);

export default logo;
