import React from 'react'
import { NavLink } from 'react-router-dom'
import imgMenu from '../data/img/menu.svg'
import imgPrapor from '../data/img/prapor.svg'
import imgSearch from '../data/img/search.svg'
import Darktheme from './Theme'

// const Menu = () => {
//   const userMedia = window.matchMedia("(prefers-color-scheme: dark)");
//   if (userMedia.matches) {
//     document.documentElement.setAttribute("data-theme", "dark");
//     localStorage.setItem("theme", "dark");
//   } else {
//     document.documentElement.setAttribute("data-theme", "light");
//     localStorage.setItem("theme", "light");
//   }

//   const animatePrapor = () => {
//     const flag = document.createElement("div");
//     flag.classList.add("flagAnimation");
//     document.body.appendChild(flag);

//     setTimeout(() => {
//       document.body.removeChild(flag);
//     }, 3000);
//   };

//   return (
//     <nav>
//       <NavLink to="." end alt="Дригало" className="menuButton">
//         ДРИГАЛО
//       </NavLink>

//       <Darktheme />

//       <NavLink to="foods" alt="Продукція Дригало" className="menuButton">
//         ПРОДУКЦІЯ
//       </NavLink>

//       <NavLink to="contacts" alt="Контакт Дригало" className="menuButton">
//         КОНТАКТ
//       </NavLink>

//       <div onClick={animatePrapor}>
//         <img
//           src={imgPrapor}
//           className="prapor"
//           alt="СЛАВА УКРАЇНІ!"
//           draggable="false"
//         />
//       </div>

//       <NavLink to="menu" alt="Меню Дригало">
//         <img
//           src={imgMenu}
//           className="menuImg"
//           alt="Меню Дригало"
//           draggable="false"
//         />
//       </NavLink>

//       <NavLink to="search" alt="Пошук продукції">
//         <img
//           src={imgSearch}
//           className="searchImg"
//           alt="Пошук продукції"
//           draggable="false"
//         />
//       </NavLink>
//     </nav>
//   );
// };
//
// export default Menu;

export default function Menu() {
  const userMedia = window.matchMedia('(prefers-color-scheme: dark)')
  if (userMedia.matches) {
    document.documentElement.setAttribute('data-theme', 'dark')
    localStorage.setItem('theme', 'dark')
  } else {
    document.documentElement.setAttribute('data-theme', 'light')
    localStorage.setItem('theme', 'light')
  }

  const animatePrapor = () => {
    const flag = document.createElement('div')
    flag.classList.add('flagAnimation')
    document.body.appendChild(flag)

    setTimeout(() => {
      document.body.removeChild(flag)
    }, 3000)
  }

  return (
    <nav>
      <NavLink to="." end alt="Дригало" className="menuButton">
        ДРИГАЛО
      </NavLink>

      <NavLink to="foods" alt="Продукція Дригало" className="menuButton">
        ПРОДУКЦІЯ
      </NavLink>

      <NavLink to="contacts" alt="Контакт Дригало" className="menuButton">
        КОНТАКТ
      </NavLink>

      <Darktheme />

      <div onClick={animatePrapor}>
        <img
          src={imgPrapor}
          className="prapor"
          alt="СЛАВА УКРАЇНІ!"
          draggable="false"
        />
      </div>

      <NavLink to="menu" alt="Меню Дригало">
        <img
          src={imgMenu}
          className="menuImg"
          alt="Меню Дригало"
          draggable="false"
        />
      </NavLink>

      <NavLink to="search" alt="Пошук продукції">
        <img
          src={imgSearch}
          className="searchImg"
          alt="Пошук продукції"
          draggable="false"
        />
      </NavLink>
    </nav>
  )
}
